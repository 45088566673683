/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'
import Inicio from './components/Inicio.vue'
import NotFound from './components/NotFound.vue'
import Formulario from './components/Formulario.vue'
import FormularioBecas from './components/FormularioBecas.vue'
import FormularioPreevaluacion from './components/FormularioPreevaluacion.vue'

/* COMPONENTES CUSTOM
import MiniCatalogo from './components/custom/MiniCatalogo.vue'
import MiniArticulos from './components/custom/MiniArticulos.vue'
import MiniEmpleados from './components/custom/MiniEmpleados.vue'*/

// Componentes OIDC
import OidcCallback from './views/OidcCallback'
import OidcCallbackError from './views/OidcCallbackError'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from './store'

Vue.use(Router)

const router = new Router({
   mode: 'history',
   base: process.env.BASE_URL,
   routes: [
      {
         path: '/',
         name: 'inicio',
         component: Inicio,
         meta: {
            title: 'Sistema - Inicio',
            isPublic: true
         }
      },
      {
         path: '/talonario',
         name: 'talonario',
         component: () =>
            import(
               /* webpackChunkName: "Talonario" */ './components/Talonario.vue'
            ),
         meta: {
            title: 'Sistema - Talonarios'
         }
      },
      {
         path: '/talonarioalu',
         name: 'talonarioalumno',
         component: () =>
            import(
               /* webpackChunkName: "TalonarioAlumno" */ './components/TalonarioAlumno.vue'
            ),
         meta: {
            title: 'Sistema - Pagos Alumno'
         }
      },
      {
         path: '/reporteTalonario',
         name: 'reporteTalonario',
         component: () =>
            import(
               /* webpackChunkName: "ReporteTalonario" */ './components/ReporteTalonario.vue'
            ),
         meta: {
            title: 'Sistema - Talonario',
            isPublic: true
         }
      },
      {
         path: '/pagos',
         name: 'pagos',
         component: () =>
            import(/* webpackChunkName: "Pagos" */ './components/Pagos.vue'),
         meta: {
            title: 'Sistema - Pagos'
         }
      },
      {
         path: '/pagosLibreria',
         name: 'pagosLibreria',
         component: () =>
            import(
               /* webpackChunkName: "PagosLibreria" */ './components/PagosLibreria.vue'
            ),
         meta: {
            title: 'Sistema - Pagos de librería'
         }
      },
      {
         path: '/pagosIdiomas',
         name: 'pagosIdiomas',
         component: () =>
            import(
               /* webpackChunkName: "PagosIdioma" */ './components/PagosIdiomas.vue'
            ),
         meta: {
            title: 'Sistema - Pagos de idiomas'
         }
      },
      {
         path: '/estadistica',
         name: 'estadistica',
         component: () =>
            import(
               /* webpackChunkName: "Estadistica" */ './components/Estadistica.vue'
            ),
         meta: {
            title: 'Sistema - Estadística'
         }
      },
      {
         path: '/solicitudes',
         name: 'solicitudes',
         component: () =>
            import(
               /* webpackChunkName: "Solicitudes" */ './components/Solicitudes.vue'
            ),
         meta: {
            title: 'Sistema - Solicitudes de planes de pagos'
         }
      },
      {
         path: '/solicitudesBecas',
         name: 'solicitudesBecas',
         component: () =>
            import(
               /* webpackChunkName: "Solicitudes" */ './components/SolicitudesBecas.vue'
            ),
         meta: {
            title: 'Sistema - Solicitudes de becas'
         }
      },
      {
         path: '/formulario',
         name: 'formulario',
         component: Formulario,
         meta: {
            title: 'UNICAES - Formulario de plan de pagos',
            isPublic: true
         }
      },
      {
         path: '/formularioBecas',
         name: 'formularioBecas',
         component: FormularioBecas,
         meta: {
            title: 'UNICAES - Formulario de becas',
            isPublic: true
         }
      },
      {
         path: '/formularioConsultaDeBeca',
         name: 'formularioPreevaluacion',
         component: FormularioPreevaluacion,
         meta: {
            title: 'UNICAES - Formulario consulta de beca',
            isPublic: true
         }
      },
      {
         path: '/preinscripcionBecas',
         name: 'preinscripcionBecas',
         component: () =>
            import(
               /* webpackChunkName: "FormularioBecasAdmin" */ './components/PreinscripcionBeca.vue'
            ),
         meta: {
            title: 'Sistema - Preinscripción becas'
         }
      },
      {
         path: '/colecturiadigital',
         name: 'colecturiaDigital',
         component: () =>
            import(
               /* webpackChunkName: "ColecturiaDigital" */ './components/ColecturiaDigital.vue'
            ),
         meta: {
            title: 'UNICAES - Colecturía Digital',
            isPublic: true
         }
      },
      {
         path: '/colecturiaexterna',
         name: 'colecturiaExterna',
         component: () =>
            import(
               /* webpackChunkName: "ColecturiaExterna" */ './components/ColecturiaExterna.vue'
            ),
         meta: {
            title: 'UNICAES - Colecturía Externa',
            isPublic: true
         }
      },
      {
         path: '/vistaAlumno',
         name: 'vistaAlumno',
         component: () =>
            import(
               /* webpackChunkName: "VistaAlumno" */ './components/VistaAlumno.vue'
            ),
         meta: {
            title: 'UNICAES - Vistas de Alumno'
         }
      },
      {
         path: '*',
         component: NotFound,
         meta: {
            title: 'Sistema - No encontrado',
            isPublic: true
         }
      },
      {
         path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
         name: 'oidcCallback',
         component: OidcCallback
      },
      {
         path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
         name: 'oidcCallbackError',
         component: OidcCallbackError,
         meta: {
            isPublic: true
         }
      },
      {
         path: '/admusuarios',
         name: 'admusuarios',
         component: () =>
            import(
               /* webpackChunkName: "AdmUsuarios" */ './components/AdmUsuarios'
            ),
         meta: {
            title: 'App - Adm. de usuarios'
         }
      },
      {
         path: '/becas',
         name: 'becas',
         component: () =>
            import(/* webpackChunkName: "Becas" */ './components/Becas.vue'),
         meta: {
            title: 'Sistema - Becas'
         }
      },
      {
         path: '/tipoBecas',
         name: 'tipoBecas',
         component: () =>
            import(
               /* webpackChunkName: "TipoBeca" */ './components/TipoBeca.vue'
            ),
         meta: {
            title: 'Sistema - Tipo Beca'
         }
      },
      {
         path: '/ciclos',
         name: 'ciclos',
         component: () =>
            import(/* webpackChunkName: "Ciclos" */ './components/Ciclos.vue'),
         meta: {
            title: 'Sistema - Ciclos'
         }
      },
      {
         path: '/arancelAlumno',
         name: 'arancelAlumno',
         component: () =>
            import(
               /* webpackChunkName: "ArancelAlumno" */ './components/ArancelAlumno.vue'
            ),
         meta: {
            title: 'Sistema - Arancel Alumno'
         }
      },
      {
         path: '/arancel',
         name: 'arancel',
         component: () =>
            import(
               /* webpackChunkName: "Arancel" */ './components/Arancel.vue'
            ),
         meta: {
            title: 'Sistema - Aranceles'
         }
      },
      {
         path: '/arancelPeriodo',
         name: 'arancelPeriodo',
         component: () =>
            import(
               /* webpackChunkName: "ArancelPeriodos" */ './components/ArancelPeriodos.vue'
            ),
         meta: {
            title: 'Sistema - Arancel Periodos'
         }
      },
      {
         path: '/registroIdiomas',
         name: 'registroIdiomas',
         component: () =>
            import(
               /* webpackChunkName: "Registro" */ './components/RegistroIdiomas.vue'
            ),
         meta: {
            title: 'Sistema - Registro Idiomas'
         }
      },
      {
         path: '/estadisticasBeca',
         name: 'estadisticasBeca',
         component: () =>
            import(
               /* webpackChunkName: "Estadistica" */ './components/EstadisticaSolicitudBeca.vue'
            ),
         meta: {
            title: 'Sistema - Estadisticas'
         }
      },
      {
         path: '/becariosExternos',
         name: 'becariosExternos',
         component: () =>
            import(
               /* webpackChunkName: "BecariosExternos" */ './components/BecariosExternos.vue'
            ),
         meta: {
            title: 'Sistema - Becarios Externos'
         }
      },
      {
         path: '/patrocinador',
         name: 'patrocinador',
         component: () =>
            import(
               /* webpackChunkName: "Patrocinador" */ './components/PatrocinadorBeca.vue'
            ),
         meta: {
            title: 'Sistema - Patrocinador'
         }
      },
      {
         path: '/clientes',
         name: 'clientes',
         component: () =>
            import(
               /* webpackChunkName: "Clientes" */ './components/Clientes.vue'
            ),
         meta: {
            title: 'Sistema - Clientes'
         }
      },
      {
         path: '/becasDashboard',
         name: 'becasDashboard',
         component: () =>
            import(
               /* webpackChunkName: "BecasDashboard" */ './components/BecasDashboard.vue'
            ),
         meta: {
            title: 'Sistema - Becas Dashboard'
         }
      },
      {
         path: '/programasIdiomas',
         name: 'programasIdiomas',
         component: () =>
            import(
               /* webpackChunkName: "ProgramasIdiomas" */ './components/ProgramasIdiomas.vue'
            ),
         meta: {
            title: 'Sistema - Programas de idiomas'
         }
      },
      {
         path: '/procedimientos',
         name: 'procedimientos',
         component: () =>
            import(
               /* webpackChunkName: "Procedimientos" */ './components/Procedimientos.vue'
            ),
         meta: {
            title: 'Sistema - Procedimientos'
         }
      }
   ]
})

// Validando rutas que requieren autenticacion
router.beforeEach(vuexOidcCreateRouterMiddleware(store))

// Codigo para actualizar dinamicamente el titulo de la pestaña del navegador
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
   // This goes through the matched routes from last to first, finding the closest route with a title.
   // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
   const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title)

   // Find the nearest route element with meta tags.
   const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags)
   const previousNearestWithMeta = from.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags)

   // If a route with a title was found, set the document (page) title to that value.
   if (nearestWithTitle) document.title = nearestWithTitle.meta.title

   // Remove any stale meta tags from the document using the key attribute we set below.
   Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
      (el) => el.parentNode.removeChild(el)
   )

   // Skip rendering meta tags if there are none.
   if (!nearestWithMeta) return next()

   // Turn the meta tag definitions into actual elements in the head.
   nearestWithMeta.meta.metaTags
      .map((tagDef) => {
         const tag = document.createElement('meta')
         Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key])
         })

         // We use this to track which meta tags we create, so we don't interfere with other ones.
         tag.setAttribute('data-vue-router-controlled', '')
         return tag
      })

      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag))
   next()
})

export default router
