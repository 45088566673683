import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from './config/oidc'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const localModule = {
   state: {
      permisos: [],
      user: localStorage.getItem('credentials') || null
   },
   getters: {
      publicUserLoggedIn(state) {
         return !!state.user
      },
      publicUser(state) {
         return state.user
      },
      isDevelopment() {
         return process.env.NODE_ENV == 'development' ? true : false
      },
      permController(state) {
         return (controladorId, action) => {
            return state.permisos.find((x) => {
               return x.controladorId == controladorId
            })[action]
         }
      },
      showModulo(state) {
         return (moduloId) => {
            return state.permisos.some((x) => x.moduloId == moduloId && x.ver)
         }
      }
   },
   mutations: {
      SET_PERMISOS(state, perm) {
         state.permisos = perm
         //console.log('Permisos obtenidos')
      },
      SET_PUBLIC_USER_DATA(state, userData) {
         state.user = userData
      },
      DESTROY_PUBLIC_LOGIN(state) {
         state.user = null
      }
   },
   actions: {
      guardarPermisos({ commit }, perm) {
         commit('SET_PERMISOS', perm)
      },
      login({ commit }, credentials) {
         commit('SET_PUBLIC_USER_DATA', credentials)
      },
      logout(context) {
         localStorage.removeItem('user')
         context.commit('DESTROY_PUBLIC_LOGIN')
      }
   }
}

const snackModule = {
   state: {
      snackbar: { active: false, color: '', message: '', timer: 4000 }
   },
   getters: {
      snackbar(state) {
         return state.snackbar
      }
   },
   mutations: {
      UPDATE_SNACKBAR(state, snackbar) {
         state.snackbar = snackbar
      }
   },
   actions: {
      showSnack({ commit }, { message, color, timer = 4000 }) {
         commit('UPDATE_SNACKBAR', {
            active: true,
            color: color,
            message: message,
            timer: timer
         })
      }
   }
}

export default new Vuex.Store({
   modules: {
      localModule: localModule,
      snackModule: snackModule,
      oidcStore: vuexOidcCreateStoreModule(
         oidcSettings,
         // Optional OIDC store settings
         {
            dispatchEventsOnWindow: false
         },
         // Optional OIDC event listeners
         {
            /*userLoaded: (user) => console.log('OIDC user is loaded:', user),
            userUnloaded: () => console.log('OIDC user is unloaded'),
            accessTokenExpiring: () => console.log('Access token will expire'),
            accessTokenExpired: () => console.log('Access token did expire'),
            silentRenewError: () => console.log('OIDC user is unloaded'),
            userSignedOut: () => console.log('OIDC user is signed out'),
            oidcError: (payload) => console.log('OIDC error', payload)*/
         }
      )
   },
   plugins: [
      createPersistedState({
         paths: ['localModule'],
         key: 'localModule',
         storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key)
         }
      })
   ]
})
