<template>
   <v-card class="mx-auto" :max-width="width">
      <v-toolbar color="primary">
         <v-toolbar-title class="white--text text-center"
            >COMENTARIOS</v-toolbar-title
         >
         <v-spacer></v-spacer>
      </v-toolbar>

      <v-list two-line>
         <v-list>
            <template v-if="comentarios.length > 0">
               <template v-for="(item, index) in comentarios">
                  <v-list-item :key="item.id">
                     <v-container class="fluid">
                        <v-list-item-content>
                           <v-list-item-title
                              class="font-weight-bold text-wrap"
                              v-text="item.comentario"
                           ></v-list-item-title>

                           <v-list-item-subtitle
                              v-text="item.fecha"
                           ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                           <v-list-item-action-text></v-list-item-action-text>
                        </v-list-item-action>
                     </v-container>
                  </v-list-item>

                  <v-divider
                     v-if="index < comentarios.length - 1"
                     :key="item.id + 'b'"
                  ></v-divider>
               </template>
            </template>
            <template v-else>
               <h4 class="text-center">Sin comentarios</h4>
            </template>
         </v-list>
      </v-list>
   </v-card>
</template>

<script>
export default {
   props: {
      width: {
         type: Number,
         default: 500
      },
      comentarios: {
         type: Array,
         default: function () {
            return []
         }
      }
   },
   data: () => ({})
}
</script>

<style lang="scss" scoped></style>
