export const oidcSettings = {
   // Por defecto vuex-oidc guarda el userStore en localStorage
   //userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),

   // PRODUCCION
   authority: process.env.VUE_APP_IDENTITY,
   client_id: 'clienteweb',
   response_type: 'code',
   scope: 'openid profile TransaccionesWebApi IdentityServerApi',
   redirect_uri: process.env.VUE_APP_CLIENTE + 'oidc-callback',
   post_logout_redirect_uri: process.env.VUE_APP_CLIENTE,
   silentRedirectUri: process.env.VUE_APP_CLIENTE + 'oidc-silent-renew.html',
   automaticSilentRenew: true, // If true oidc-client will try to renew your token when it is about to expire
   automaticSilentSignin: true // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true
}
