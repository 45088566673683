import { mapGetters, mapActions } from 'vuex'

export const authComputed = {
   ...mapGetters([
      'oidcUser',
      'permController',
      'showModulo',
      'isDevelopment',
      'publicUserLoggedIn',
      'publicUser'
   ])
}

export const snackGlobal = {
   ...mapGetters(['snackbar'])
}

export const authMethods = {
   ...mapActions(['authenticateOidc', 'signOutOidc'])
}
