<template>
   <v-dialog v-model="show" width="750px" persistent>
      <v-card>
         <v-card-text class="text--primary">
            <v-container grid-list-md>
               <v-layout align-start>
                  <v-flex xs12>
                     <v-card elevation="0" height="550">
                        <v-container grid-list-md fluid>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium">
                                    Configuraciones generales
                                 </span>
                              </v-flex>
                              <v-flex xs12>
                                 <span
                                    class="font-weight-medium"
                                    style="font-size: 20px"
                                 >
                                    Estados de la aplicación
                                 </span>
                              </v-flex>

                              <v-flex xs12>
                                 <v-data-table
                                    :headers="headersConfig"
                                    :items="detallesConfig"
                                    :items-per-page="-1"
                                    :footer-props="{
                                       itemsPerPageOptions: [-1]
                                    }"
                                    no-results-text="Búsqueda sin resultados"
                                    class="elevation-0"
                                    hide-default-footer
                                    height="500"
                                    fixed-header
                                 >
                                    <template #item.nombre="{ item }">
                                       {{ getNombre(item.nombre) }}
                                    </template>

                                    <template #item.valor="{ item }">
                                       <div
                                          v-if="typeof item.valor === 'boolean'"
                                          class="layout justify-center"
                                       >
                                          <v-checkbox
                                             v-model="item.valor"
                                             color="primary"
                                             style="margin-left: 5px"
                                          ></v-checkbox>
                                       </div>

                                       <v-textarea
                                          v-if="typeof item.valor === 'string'"
                                          v-model="item.valor"
                                          color="primary"
                                          rows="1"
                                          label=""
                                       >
                                       </v-textarea>
                                    </template>

                                    <!-- Si no hay datos, mostrar boton para listar de nuevo -->
                                    <template slot="no-data">
                                       <div v-if="detallesConfig.length == 0">
                                          <v-container>
                                             <v-layout wrap align-center>
                                                <v-flex
                                                   xs12
                                                   class="text-center"
                                                >
                                                   <v-btn color="primary"
                                                      >Sin opciones</v-btn
                                                   >
                                                </v-flex>
                                             </v-layout>
                                          </v-container>
                                       </div>
                                    </template>
                                 </v-data-table>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card>
                     <v-container grid-list-md fluid>
                        <v-layout wrap> </v-layout>
                     </v-container>
                  </v-flex>
               </v-layout>
            </v-container>
         </v-card-text>
         <v-card-actions style="padding: 0px 40px 20px 40px">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click.stop="close">Salir</v-btn>
            <v-btn color="primary" dark @click.stop="guardarConfig"
               >Guardar</v-btn
            >
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import formatters from '../mixins/formattersLib'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'Configuracion',
   directives: {
      mask
   },
   mixins: [formatters],
   props: {
      value: Boolean
   },
   data: () => ({
      // Variables
      detallesConfig: [],
      headersConfig: [
         {
            text: 'Nombre',
            value: 'nombre',
            class: 'titulo',
            width: 250,
            sortable: false
         },
         {
            text: 'Activo / Valor',
            value: 'valor',
            align: 'center',
            class: 'titulo',
            sortable: false
         }
      ]
   }),
   computed: {
      ...authComputed,
      show: {
         get() {
            return this.value
         },
         set(value) {
            this.$emit('input', value)
         }
      }
   },
   watch: {
      show(val) {
         if (val == true) {
            axios
               .get('api/Configuracion')
               .then((response) => {
                  var res = response.data
                  res.forEach((el) => {
                     switch (el.valor) {
                        case 'true':
                           el.valor = true
                           break
                        case 'false':
                           el.valor = false
                           break
                        default:
                           if (el.valor === null) {
                              el.valor = ''
                           }
                           break
                     }
                  })
                  this.detallesConfig = res
               })
               .catch((error) => {
                  console.log(error.response)
               })
         }
      }
   },
   methods: {
      guardarConfig() {
         this.detallesConfig.forEach((config) => {
            var stringValor = config.valor.toString()
            axios
               .put(`api/Configuracion/${config.nombre}?value=${stringValor}`)
               .then(function () {})
               .catch(function () {})
         })
         this.close()
      },
      close() {
         this.show = false
      },
      getNombre(nombre) {
         switch (true) {
            case nombre == 'pagosEnLinea':
               return 'Funciones de pago en linea en colecturía digital.'

            case nombre == 'formPlanPagos':
               return 'Recepción de formularios para nuevos planes de pago.'

            case nombre == 'formBecasPreevaluacion':
               return 'Formulario de preevaluación para solicitudes de becas.'

            case nombre == 'carrerasPlanPagos':
               return 'Códigos de carreras para recepción de planes de pagos'

            case nombre == 'mensaje':
               return 'Mensaje para Colecturía Digital y de Usuarios Externos.'

            case nombre == 'mensajePlanPagos':
               return 'Mensaje para formulario de Plan de Pagos.'

            case nombre == 'mensajeBecas':
               return 'Mensaje para formulario de Becas.'

            case nombre == 'fechaInicioAbs':
               return 'Fecha de inicio de absolución de mora.'

            case nombre == 'fechaFinAbs':
               return 'Fecha de finalización de absolución de mora.'

            case nombre == 'cicloActual':
               return 'Ciclo actual para validación de formularios de planes de pago y becas.'

            case nombre == 'cicloExonerado':
               return 'Exonerar ciclo mora de ciclo.'

            case nombre == 'pagosIdiomas':
               return 'Funciones de pagos de módulos de idiomas.'

            case nombre == 'vencimientoInscripcionIdiomas':
               return 'Fecha de finalizacion de inscripcion de modulos de idiomas sabatino.'

            case nombre == 'vencimientoInscripcionIdiomasInt':
               return 'Fecha de finalizacion de inscripcion de modulos de idiomas intensivo.'

            case nombre.includes('EBOOK') === true:
               return nombre

            case nombre == 'periodoDiferidos':
               return 'Filtro de periodo al listar exámenes de diferidos'

            case nombre == 'fechaLimiteBecas':
               return 'Fecha limite para completar solicitud de becas. (Para notificación por correo)'

            case nombre == 'fechaActivacionPagosIdiomas':
               return 'Fecha de activacion pagos idiomas'

            default:
               return 'NO CONFIGURADO'
         }
      }
   }
}
</script>
