<template>
   <v-app id="app">
      <!-- GLOBAL SNACKBAR -->
      <v-snackbar
         v-model="snackbar.active"
         :color="snackbar.color"
         :right="true"
         :bottom="true"
         :timeout="snackbar.timer"
      >
         {{ snackbar.message }}
         <template #action="{ attrs }">
            <v-btn
               color="white"
               text
               v-bind="attrs"
               @click="snackbar.active = false"
            >
               Close
            </v-btn>
         </template>
      </v-snackbar>

      <!-- SIDE BAR -->
      <v-navigation-drawer
         v-if="oidcUser"
         v-model="drawer"
         :clipped="$vuetify.breakpoint.lgAndUp"
         width="300"
         fixed
         app
      >
         <v-list dense>
            <!-- INICIO -->
            <v-list-item :to="{ name: 'inicio' }" exact color="primary">
               <v-list-item-action>
                  <v-icon style="width: 25px">fa-home</v-icon>
               </v-list-item-action>
               <v-list-item-title class="sidebarTitle">
                  Inicio
               </v-list-item-title>
            </v-list-item>

            <!-- ADM USUARIOS -->
            <v-list-item
               v-if="checkIsAdmin(oidcUser)"
               :to="{ name: 'admusuarios' }"
               exact
               color="primary"
            >
               <v-list-item-action>
                  <v-icon style="width: 25px">fa-users</v-icon>
               </v-list-item-action>
               <v-list-item-title class="sidebarTitle"
                  >Adm. de usuarios</v-list-item-title
               >
            </v-list-item>

            <!-- CONFIGURACION -->
            <v-list-item
               v-if="checkIsAdmin(oidcUser)"
               color="primary"
               @click="dialogConfiguracion = true"
            >
               <v-list-item-action>
                  <v-icon style="width: 25px">fa-cog</v-icon>
               </v-list-item-action>
               <v-list-item-title class="sidebarTitle">
                  Configuración aplicación
               </v-list-item-title>
            </v-list-item>

            <v-list-item
               v-if="checkIsAdmin(oidcUser)"
               :to="{ name: 'ciclos' }"
               exact
               color="primary"
            >
               <v-list-item-action>
                  <v-icon style="width: 25px">fas fa-calendar-alt</v-icon>
               </v-list-item-action>
               <v-list-item-title class="sidebarTitle"
                  >Ciclos</v-list-item-title
               >
            </v-list-item>

            <!-- DEMOS -->
            <v-list-group v-if="false">
               <v-list-item slot="activator">
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        DEMOS
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <v-list-item :to="{ name: 'talonarioalumno' }">
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-pager</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Pago en línea - ProyCon
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <v-list-item :to="{ name: 'reporteTalonario' }">
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-pager</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Talonario completo - PHARO
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </v-list-group>

            <!-- TALONARIOS PROYCON -->
            <v-list-group v-if="false">
               <v-list-item slot="activator">
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Talonarios (ProyCon)
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <v-list-item :to="{ name: 'pagos' }">
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-credit-card</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Pagos
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <v-list-item :to="{ name: 'talonario' }">
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-book-open</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Talonarios
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </v-list-group>

            <!-- CONFIGURACION BECAS -->
            <v-list-group v-if="showModulo(3)">
               <v-list-item slot="activator">
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Becas
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <v-list-item
                  v-if="permController(4, 'ver')"
                  :to="{ name: 'becas' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px"> fas fa-graduation-cap</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Becas aplicadas</v-list-item-title
                  >
               </v-list-item>
               <v-list-item
                  v-if="permController(11, 'ver')"
                  :to="{ name: 'becariosExternos' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px"> fas fa-graduation-cap</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Becarios externos</v-list-item-title
                  >
               </v-list-item>
               <v-list-item
                  v-if="permController(14, 'ver')"
                  :to="{ name: 'becasDashboard' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px"> fas fa-chart-pie</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Dashboard</v-list-item-title
                  >
               </v-list-item>

               <v-list-item
                  v-if="checkIsAdmin(oidcUser) || checkAsesorCero(oidcUser)"
                  :to="{ name: 'estadisticasBeca' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">far fa-chart-bar</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Estadisticas de asesores de becas</v-list-item-title
                  >
               </v-list-item>

               <!-- FORMULARIO DE BECAS -->
               <v-list-item :to="{ name: 'formularioBecas' }" color="primary">
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-file-upload</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Formulario de becas
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(12, 'ver')"
                  :to="{ name: 'patrocinador' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-wallet</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Patrocinadores</v-list-item-title
                  >
               </v-list-item>
               <v-list-item
                  v-if="permController(10, 'ver')"
                  :to="{ name: 'preinscripcionBecas' }"
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-envelope</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Preinscripción de solicitud
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(9, 'ver')"
                  :to="{ name: 'solicitudesBecas' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px"> fas fa-file-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Solicitudes de becas</v-list-item-title
                  >
               </v-list-item>
               <v-list-item
                  v-if="permController(5, 'ver')"
                  :to="{ name: 'tipoBecas' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px"> fas fa-university</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Tipos de becas</v-list-item-title
                  >
               </v-list-item>
            </v-list-group>

            <!-- COLECTURIA DIGITAL -->
            <v-list-group v-if="showModulo(2)">
               <v-list-item slot="activator">
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Colecturía Digital
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(15, 'ver')"
                  :to="{ name: 'arancel' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">far fa-list-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Aranceles
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(6, 'ver')"
                  :to="{ name: 'arancelAlumno' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">far fa-calendar-minus</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Arancel por alumno
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(16, 'ver')"
                  :to="{ name: 'arancelPeriodo' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">far fa-calendar-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Arancel por periodos
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(13, 'ver')"
                  :to="{ name: 'clientes' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-book-open</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Clientes externos
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(2, 'ver')"
                  :to="{ name: 'pagos' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-money-check-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Pagos de contabilidad
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(7, 'ver')"
                  :to="{ name: 'pagosLibreria' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-book</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Pagos de librería
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(8, 'ver')"
                  :to="{ name: 'pagosIdiomas' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-comments-dollar</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Pagos de idiomas
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(18, 'ver')"
                  :to="{ name: 'procedimientos' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-user-cog</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Procedimientos
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(17, 'ver')"
                  :to="{ name: 'programasIdiomas' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-language</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Programas de idiomas
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item
                  v-if="permController(3, 'ver')"
                  :to="{ name: 'vistaAlumno' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-pager</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Vistas de alumno
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </v-list-group>

            <!-- PLANES DE PAGO -->
            <v-list-group v-if="showModulo(1)">
               <v-list-item slot="activator">
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Planes de Pagos
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <!-- ESTADISTICAS DE ASESORES -->
               <v-list-item
                  v-if="checkIsAdmin(oidcUser)"
                  :to="{ name: 'estadistica' }"
                  exact
                  color="primary"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">far fa-chart-bar</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="sidebarTitle"
                     >Estadísticas de asesores</v-list-item-title
                  >
               </v-list-item>

               <!-- FORMULARIO -->
               <v-list-item :to="{ name: 'formulario' }" color="primary">
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-file-upload</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Formulario de plan de pagos
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>

               <!-- SOLICITUDES -->
               <v-list-item
                  v-if="permController(1, 'ver')"
                  :to="{ name: 'solicitudes' }"
               >
                  <v-list-item-action>
                     <v-icon style="width: 25px">fas fa-file-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title class="sidebarTitle">
                        Solicitudes de plan de pago
                     </v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </v-list-group>
         </v-list>
      </v-navigation-drawer>

      <!-- TOP BAR -->
      <v-app-bar
         :clipped-left="$vuetify.breakpoint.lgAndUp"
         color="primary"
         class="appBarBorder"
         dark
         app
         fixed
      >
         <v-toolbar-title style="width: 400px">
            <v-layout>
               <div>
                  <v-app-bar-nav-icon
                     v-if="oidcUser"
                     @click.stop="drawer = !drawer"
                  ></v-app-bar-nav-icon>
               </div>
               <div
                  v-if="$vuetify.breakpoint.mdAndUp || !oidcUser"
                  class="d-flex align-center"
               >
                  <v-img
                     :src="
                        sede == 'SA'
                           ? '/assets/unicaes-500.png'
                           : '/assets/unicaes-cri-500.png'
                     "
                     contain
                     :width="sede == 'SA' ? 200 : 260"
                     @click="goToHome"
                  ></v-img>
               </div>
            </v-layout>
         </v-toolbar-title>
         <v-spacer></v-spacer>
         <!-- BOTONES SESION IDENTITY -->
         <div
            v-if="
               oidcUser &&
               !$route.path
                  .toString()
                  .toLowerCase()
                  .includes('/colecturiaexterna')
            "
         >
            <v-btn icon>
               <v-icon style="width: 25px">fa-user</v-icon>
            </v-btn>
            <strong v-if="$vuetify.breakpoint.mdAndUp">{{
               oidcUser.name
            }}</strong>
         </div>
         <v-btn
            v-if="
               !oidcUser &&
               !$route.path
                  .toString()
                  .toLowerCase()
                  .includes('/reportetalonario') &&
               !$route.path
                  .toString()
                  .toLowerCase()
                  .includes('/colecturiadigital') &&
               !$route.path
                  .toString()
                  .toLowerCase()
                  .includes('/colecturiaexterna') &&
               !$route.path.toString().toLowerCase().includes('/formulario')
            "
            color="primary"
            depressed
            class="ml-4"
            @click="login()"
            >INICIAR SESION</v-btn
         >
         <v-btn
            v-if="
               oidcUser &&
               !$route.path
                  .toString()
                  .toLowerCase()
                  .includes('/colecturiaexterna')
            "
            color="primary"
            depressed
            class="ml-4"
            @click="logout()"
            >CERRAR SESION</v-btn
         >

         <!-- BOTONES SESION PUBLICA -->
         <div
            v-if="
               publicUser &&
               $route.path
                  .toString()
                  .toLowerCase()
                  .includes('/colecturiaexterna')
            "
         >
            <v-btn icon>
               <v-icon style="width: 25px">fa-user</v-icon>
            </v-btn>
            <strong v-if="$vuetify.breakpoint.mdAndUp">{{
               publicUser.carnet
            }}</strong>
         </div>
         <v-btn
            v-if="
               publicUser &&
               $route.path
                  .toString()
                  .toLowerCase()
                  .includes('/colecturiaexterna')
            "
            color="primary"
            depressed
            class="ml-4"
            @click="logoutPublic()"
            >CERRAR SESION</v-btn
         >
      </v-app-bar>

      <!-- CONTENIDO -->
      <v-main>
         <v-container fluid fill-height>
            <v-slide-y-transition mode="out-in">
               <router-view />
            </v-slide-y-transition>
         </v-container>
      </v-main>

      <!-- FOOTER -->
      <v-footer dark padless>
         <v-card class="flex" flat tile>
            <v-card-actions class="primary justify-center">
               <div v-if="$vuetify.breakpoint.mdAndUp">
                  Para consultas técnicas escríbenos al correo: {{ correoDev }}
               </div>
               <div v-else><strong>Soporte:</strong> {{ correoDev }}</div>
            </v-card-actions>
         </v-card>
      </v-footer>

      <Configuracion v-model="dialogConfiguracion" />
   </v-app>
</template>

<!-- Scripts -->
<script>
import axios from 'axios'
import { authComputed, authMethods, snackGlobal } from './helpers.js'
import Configuracion from './components/Configuracion'

export default {
   name: 'App',
   components: {
      Configuracion
   },
   data: (vm) => ({
      drawer: vm.$vuetify.breakpoint.lgAndUp ? true : false,
      correoDev: process.env.VUE_APP_CORREO_DEV,
      sede: process.env.VUE_APP_SEDE,
      dialogConfiguracion: false
   }),
   computed: {
      // Otras funciones computadas
      ...authComputed,
      ...snackGlobal
   },
   watch: {
      oidcUser(val) {
         var me = this
         if (val != null && val != undefined) {
            //console.log(me.oidcUser)
            // Obtener permisos para el usuario actual desde BD
            axios
               .get('api/permisos/' + val.sub)
               .then(function (response) {
                  //console.log(response.data)
                  me.$store.dispatch('guardarPermisos', response.data)
               })
               .catch(function () {
                  //console.log(error)
               })
         }
      }
   },
   methods: {
      goToHome() {
         if (this.$router.currentRoute.name != 'inicio') {
            this.$router.push({ name: 'inicio' })
         }
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      },
      ...authMethods,
      login() {
         this.authenticateOidc()
      },
      logout() {
         this.$store.dispatch('guardarPermisos', [])
         this.$store.dispatch('logout')
         this.signOutOidc()
      },
      logoutPublic() {
         this.$store.dispatch('logout').then(() => {
            this.sacameDeAqui()
         })
      },
      checkIsAdmin(user) {
         if (user) {
            return user.role == 'admin' ? true : false
         } else {
            return false
         }
      },
      checkAsesorCero(user) {
         if (user) {
            return user.nivelasesor == 0 ? true : false
         } else {
            return false
         }
      }
   }
}
</script>

<style>
/* ESTILO GLOBAL ENTRE COMPONENTES */
.wrap-list-title {
   word-wrap: break-word;
   -webkit-line-clamp: unset !important;
   white-space: normal;
}
.upper input {
   text-transform: uppercase;
}

td {
   font-size: 14.5px !important;
}
/* Titulos de datatables */
.titulo {
   font-size: 16px !important;
   font-weight: 500;
}
.tituloPrimary {
   font-size: 16px !important;
   font-weight: 500;
   color: white !important;
   background-color: var(--v-primary-base) !important;
}
.sidebarTitle {
   font-size: 15px !important;
}
.noselect {
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/* Titulos de dialogs de reportes */
.dialogsOptionTitlePrimary {
   font-size: 16px;
   color: var(--v-primary-base);
}
/* Bordes redondeados en maestros detalles */
.borderDetallesPrimary {
   background-color: white !important;
   border: 3px solid var(--v-primary-base) !important;
   border-radius: 15px !important;
   margin-top: 20px;
}
/* Bordes redondeados en maestros detalles */
.borderDetallesSecondary {
   background-color: white !important;
   border: 3px solid #e65100 !important;
   border-radius: 15px !important;
   margin-top: 20px;
}
/* Label de periodo cerrados en contabilidad */
.periodoCerradoTitlePrimary {
   color: var(--v-primary-base) !important;
   font-size: 18px;
   float: left;
}
.colorPrimary {
   color: var(--v-primary-base) !important;
}
.smallForm {
   margin-bottom: -14px;
}
.appBarBorder {
   box-shadow: inset 0 -7px 0 var(--v-secondary-base),
      0px 0px 8px 2px rgba(0, 0, 0, 0.4) !important;
}
.v-list-item[aria-expanded='true'] {
   background: #f4e1e1;
}
</style>
