<template>
   <v-layout aling-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <v-container :fluid="$vuetify.breakpoint.mdAndDown">
            <v-layout wrap>
               <!-- MENSAJE PARA USUARIOS DE BECAS -->
               <v-flex v-if="mostrarMensaje" class="mb-8" xs12>
                  <v-card class="borderDetallesPrimary">
                     <v-layout
                        wrap
                        fill-height
                        align-center
                        :class="
                           $vuetify.breakpoint.smAndUp ? 'px-6' : 'px-4 py-4'
                        "
                     >
                        <v-flex xs12 sm1 text-center>
                           <v-icon size="35" color="primary">
                              fa-info-circle
                           </v-icon>
                        </v-flex>
                        <v-flex xs12 sm11 text-justify class="pt-2">
                           <span class="font-weight-bold colorPrimary">
                              AVISO IMPORTANTE:
                           </span>
                           <p
                              class="font-weight-medium"
                              style="margin-bottom: 10px"
                           >
                              {{ mensajeBecas }}
                           </p>
                        </v-flex>
                     </v-layout>
                  </v-card>
               </v-flex>

               <v-flex
                  v-if="solicitudInfo != null"
                  class="font-weight-medium text-h6"
                  xs12
                  text-center
                  mb-4
               >
                  Su número de referencia es: <br />
                  <span class="colorPrimary font-weight-bold">{{
                     solicitudInfo.numeroReferencia
                  }}</span>
               </v-flex>

               <v-flex xs12>
                  <v-stepper
                     v-model="stepNum"
                     non-linear
                     :class="$vuetify.breakpoint.xsOnly ? 'elevation-0' : ''"
                  >
                     <v-stepper-items
                        :class="$vuetify.breakpoint.smAndUp ? 'px-8' : 'py-2'"
                     >
                        <!-- PASO 1 -->
                        <v-stepper-content
                           step="1"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout wrap>
                              <v-flex xs12 class="my-12">
                                 <v-form
                                    ref="formReferencia"
                                    v-model="validReferencia"
                                    lazy-validation
                                    @submit.prevent="revisarSolicitud"
                                 >
                                    <p
                                       class="font-weight-medium text-center text-h5"
                                    >
                                       Para consultar tu solicitud de beca
                                       ingresa tú número de referencia:
                                    </p>
                                    <v-layout justify-center align-center>
                                       <div
                                          :style="
                                             $vuetify.breakpoint.smAndUp
                                                ? 'width: 300px'
                                                : ''
                                          "
                                       >
                                          <v-text-field
                                             v-model="referencia"
                                             :rules="rulesReferencia"
                                             label="Referencia"
                                             color="primary"
                                          ></v-text-field>
                                       </div>
                                    </v-layout>
                                    <v-layout justify-center align-center mt-2>
                                       <v-btn
                                          rounded
                                          color="primary"
                                          outlined
                                          :disabled="!validReferencia"
                                          :width="
                                             $vuetify.breakpoint.xsOnly
                                                ? '100%'
                                                : ''
                                          "
                                          height="60px"
                                          x-large
                                          @click="revisarSolicitud"
                                       >
                                          Revisar solicitud
                                       </v-btn>
                                    </v-layout>
                                 </v-form>
                              </v-flex>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 3 -->
                        <v-stepper-content
                           step="3"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasDatosGenerales
                              :carnet-prop="carnet"
                              :beneficiario-id="selectBeneficiario"
                              :solicitud-info-prop="solicitudInfo"
                              @cancelGoStep="stepNum = $event"
                              @nextStep="nextStepDatosGenerales($event)"
                           />
                        </v-stepper-content>

                        <!-- PASO 3.1 -->
                        <v-stepper-content
                           step="3.1"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasLaboral
                              :solicitud-beca-id="solicitudBecaId"
                              :completada="completada"
                              @cancelGoStep="stepNum = $event"
                              @nextStep="stepNum = $event"
                           />
                        </v-stepper-content>

                        <!-- PASO 3.2 -->
                        <v-stepper-content
                           step="3.2"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasNucleoFamiliar
                              :solicitud-beca-id="solicitudBecaId"
                              :completada="completada"
                              @cancelGoStep="stepNum = $event"
                              @nextStep="stepNum = $event"
                           />
                        </v-stepper-content>

                        <!-- PASO 3.3 -->
                        <v-stepper-content
                           step="3.3"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasBienesFamiliares
                              :solicitud-beca-id="solicitudBecaId"
                              :completada="completada"
                              @cancelGoStep="stepNum = $event"
                              @nextStep="stepNum = $event"
                           />
                        </v-stepper-content>

                        <!-- PASO 3.4 -->
                        <v-stepper-content
                           step="3.4"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasContIngresosEgresos
                              :solicitud-beca-id="solicitudBecaId"
                              :completada="completada"
                              @cancelGoStep="stepNum = $event"
                              @nextStep="stepNum = $event"
                           />
                        </v-stepper-content>

                        <!-- PASO 3.5 -->
                        <v-stepper-content
                           step="3.5"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasEnsayo
                              :solicitud-beca-id="solicitudBecaId"
                              :completada="completada"
                              @cancelGoStep="stepNum = $event"
                              @nextStep="stepNum = $event"
                           />
                        </v-stepper-content>

                        <!-- PASO 4 -->
                        <v-stepper-content
                           step="4"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasComprobantes
                              :solicitud-beca-id="solicitudBecaId"
                              :beneficiario-id="selectBeneficiario"
                              :completada="completada"
                              :listar="stepNum == 4"
                              @cancelGoStep="stepNum = $event"
                           />
                        </v-stepper-content>
                     </v-stepper-items>
                  </v-stepper>
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import FormBecasDatosGenerales from './FormularioBecasDatosGenerales'
import FormBecasLaboral from './FormularioBecasLaboral'
import FormBecasNucleoFamiliar from './FormularioBecasNucleoFamiliar'
import FormBecasBienesFamiliares from './FormularioBecasBienesFamiliares'
import FormBecasContIngresosEgresos from './FormularioBecasContIngresosEgresos'
import FormBecasEnsayo from './FormularioBecasEnsayo'
import FormBecasComprobantes from './FormularioBecasComprobantes'

export default {
   name: 'FormularioBecasAlumno',
   directives: {
      mask
   },
   components: {
      FormBecasDatosGenerales,
      FormBecasLaboral,
      FormBecasNucleoFamiliar,
      FormBecasBienesFamiliares,
      FormBecasContIngresosEgresos,
      FormBecasEnsayo,
      FormBecasComprobantes
   },
   mixins: [formatters],
   data: (vm) => ({
      mensajeBecas: '',
      mostrarMensaje: false,
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      stepNum: 1,
      correoAdm: process.env.VUE_APP_CORREO_ADM,
      fechaSolicitud: vm.toISOLocal(new Date()).substr(0, 10),
      acepto: false,
      formularioDeshabilitado: false,

      // Variables de formulario
      tipoBeneficioId: 0,
      cmbBeneficiarios: [],
      validBeneficiario: true,
      selectBeneficiario: null,
      carnet: '',
      validCarnet: true,
      verificandoRequisitos: false,
      pasoRequisitos: null,
      solicitudBecaId: null,
      solicitudInfo: null,
      completada: false,
      referencia: '',
      validReferencia: true,

      // Reglas de validacion
      rulesCarnet: [
         (v) => !!v || 'Ingresa tu carnet',
         (v) => (v && v.length == 11) || 'Termina de escribir tu carnet'
      ],
      rulesBeneficiario: [(v) => !!v || 'Seleccione un tipo de beneficiario'],
      rulesReferencia: [(v) => !!v || 'Ingrese una referencia']
   }),
   computed: {
      ...authComputed,
      selectBeneficiarioNombre() {
         if (this.selectBeneficiario != null) {
            return this.cmbBeneficiarios.find(
               (obj) => obj.beneficiarioId == this.selectBeneficiario
            ).nombre
         } else {
            return ''
         }
      },
      filteredBeneficiarios() {
         return this.cmbBeneficiarios.filter((i) => {
            return i.tipoBeneficioId == this.tipoBeneficioId
         })
      }
   },
   watch: {
      carnet() {
         if (this.stepNum == 2.1) {
            this.pasoRequisitos = null
         }
      }
   },
   created() {
      this.listarComboBoxes()
      this.referencia = this.$route.query.NumeroRef
      setTimeout(() => {
         if (this.referencia != undefined) {
            this.revisarSolicitud()
         }
      }, 1000)
   },
   methods: {
      revisarSolicitud() {
         if (this.$refs.formReferencia.validate()) {
            axios
               .get('api/SolicitudDeBeca?numeroRef=' + this.referencia)
               .then((response) => {
                  if (response.data.length > 0) {
                     this.solicitudInfo = response.data[0]
                     this.solicitudBecaId = response.data.solicitudBecaId
                     this.stepNum = 3
                  } else {
                     this.showSnack(
                        'Número de referencia incorrecto',
                        'snackError'
                     )
                  }
               })
         }
      },
      nextStepDatosGenerales(soliInfo) {
         this.solicitudBecaId = soliInfo.solicitudBecaId
         this.selectBeneficiario = soliInfo.beneficiarioId
         this.completada = soliInfo.completada
         this.solicitudInfo = soliInfo

         // Si ya esta cargada una solicitud, solo pasar al siguiente step
         if (
            this.selectBeneficiario == 1 ||
            this.selectBeneficiario == 2 ||
            this.selectBeneficiario == 4 ||
            this.selectBeneficiario == 8 ||
            this.selectBeneficiario == 9
         ) {
            this.stepNum = 3.1
         } else {
            this.stepNum = 4
         }
      },
      nextStepRequisitos() {
         this.stepNum = 3
      },
      validarRequisitos() {
         if (this.$refs.formCarnet.validate()) {
            this.pasoRequisitos = null
            this.verificandoRequisitos = true
            setTimeout(() => {
               axios
                  .get('api/SolicitudDeBeca/RequisitosBecario/' + this.carnet)
                  .then(() => {
                     this.pasoRequisitos = true
                  })
                  .catch(() => {
                     this.pasoRequisitos = false
                  })
                  .finally(() => {
                     this.verificandoRequisitos = false
                  })
            }, 1500)
         }
      },
      cancelarValidarRequisitos() {
         this.$refs.formCarnet.resetValidation()
         this.pasoRequisitos = null
         this.carnet = ''
         this.verificandoRequisitos = false
         this.stepNum = 2
      },
      nextStepBeneficiarios() {
         if (this.$refs.formBeneficiario.validate()) {
            if (this.selectBeneficiario == 1 || this.selectBeneficiario == 2) {
               this.stepNum = 2.1
            } else {
               this.stepNum = 3
            }
         }
      },
      cancelarBeneficiarios() {
         this.$refs.formBeneficiario.resetValidation()
         this.selectBeneficiario = null
         this.stepNum = 1
      },
      chooseBienestarUni() {
         this.tipoBeneficioId = 2
         this.stepNum = 2
      },
      chooseAdministrativa() {
         this.tipoBeneficioId = 1
         this.stepNum = 2
      },
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      listarComboBoxes() {
         axios
            .get('api/solicitudesdebecas/Beneficiario')
            .then((response) => {
               this.cmbBeneficiarios = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         // Mensaje desde DB
         axios
            .get('api/Configuracion/mensajeBecas')
            .then((response) => {
               if (
                  response.data.valor.trim() != '' &&
                  response.data.valor != null
               ) {
                  this.mensajeBecas = response.data.valor
                  this.mostrarMensaje = true
               } else {
                  this.mostrarMensaje = false
                  this.mensajeBecas = ''
               }
            })
            .catch(() => {
               //console.log(error)
            })
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      }
   }
}
</script>

<style scoped>
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
.primaryColor {
   color: var(--v-primary-base) !important;
}
</style>
