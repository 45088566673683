import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/src/styles/main.sass'
import 'typeface-roboto/index.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
   directives: {
      Ripple
   }
})

export default new Vuetify({
   icons: {
      iconfont: 'fa' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
   },
   theme: {
      dark: false,
      themes: {
         light: {
            primary: '#A00402',
            secondary: '#deab2f',
            snackSuccess: '#388E3C',
            snackWarning: '#FF6F00',
            snackError: '#B71C1C',
            snackInfo: '#0D47A1'
         },
         dark: {}
      },
      options: {
         customProperties: true
      }
   }
})
