<template>
   <v-layout align-start wrap>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}

         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <!-- DIALOG CREAR / EDITAR -->
      <v-dialog v-model="dialogNucleo" persistent max-width="600px">
         <v-form ref="formNucleo" v-model="validNucleo" lazy-validation>
            <v-card>
               <v-card-text class="text--primary" style="margin-top: -20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium">{{
                              formTitle
                           }}</span>
                        </v-flex>
                     </v-layout>
                     <v-layout wrap>
                        <v-flex xs12 sm7>
                           <v-text-field
                              v-model="nombre"
                              :rules="nombreRules"
                              class="upper"
                              color="primary"
                              label="Nombre completo"
                              @keyup="uppercase"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm5>
                           <v-autocomplete
                              v-model="selectParentesco"
                              :items="cmbParentesco"
                              :rules="parentescoRules"
                              color="primary"
                              item-text="nombre"
                              item-value="parentescoId"
                              label="Parentesco"
                              no-data-text="Sin resultados"
                              placeholder="Seleccionar..."
                           ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12>
                           <v-text-field
                              v-model="lugarDeTrabajo"
                              :rules="lugarDeTrabajoRules"
                              color="primary"
                              label="Lugar de trabajo"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12 class="font-weight-medium text-subtitle-1">
                           Tiempo laboral
                        </v-flex>
                        <v-flex xs6 sm3>
                           <v-text-field
                              v-model="tiempoLaboralAnnios"
                              :rules="tiempoLaboralRules"
                              color="primary"
                              label="Años"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm3>
                           <v-text-field
                              v-model="tiempoLaboralMeses"
                              :rules="tiempoLaboralRules"
                              color="primary"
                              label="Meses"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                           <v-text-field
                              v-model="salario"
                              :rules="salarioRules"
                              color="primary"
                              label="Salario nominal"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                           <v-text-field
                              v-model="jefeInmediato"
                              class="upper"
                              :rules="jefeInmediatoRules"
                              color="primary"
                              label="Nombre de jefe inmediato"
                              @keyup="uppercase"
                           ></v-text-field>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions
                  style="
                     margin-top: -40px;
                     margin-right: 20px;
                     padding-bottom: 20px;
                  "
               >
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click.native="close"
                     >Cancelar</v-btn
                  >
                  <v-btn
                     color="primary white--text"
                     :disabled="!validNucleo"
                     @click.native="saveNucleo"
                     >Guardar</v-btn
                  >
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <!-- DIALOG ELIMINAR  -->
      <v-dialog v-model="dialogEliminar" persistent max-width="600px">
         <v-form ref="formEliminar">
            <v-card>
               <v-card-text class="text--primary" style="margin-top: -20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium"
                              >Remover dato laboral</span
                           >
                        </v-flex>
                        <v-flex xs12>
                           <span
                              class="font-weight-regular"
                              style="font-size: 20px"
                              >¿Esta seguro que desea remover el dato
                              laboral?</span
                           >
                           <v-layout wrap style="margin-top: 10px">
                              <v-flex xs12>
                                 <v-card
                                    flat
                                    color="red lighten-5"
                                    class="text-center elevation-1"
                                    style="
                                       border-radius: 10px;
                                       padding: 15px 25px;
                                    "
                                 >
                                    <span
                                       class="red--text text--darken-2 text-h6"
                                       >{{ nombre }}</span
                                    >
                                 </v-card>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                        <v-flex xs12 text-center>
                           <v-icon color="primary" size="70" class="mt-2 mb-2"
                              >fa-trash-alt</v-icon
                           >
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions style="margin-top: -30px; padding-bottom: 20px">
                  <v-spacer></v-spacer>
                  <v-btn
                     color="primary"
                     dark
                     outlined
                     min-width="150"
                     @click.native="removerNucleo"
                     >Si</v-btn
                  >
                  <v-btn
                     color="primary"
                     dark
                     min-width="150"
                     @click.native="close"
                     >No</v-btn
                  >
                  <v-spacer></v-spacer>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <v-flex xs12>
         <span class="font-weight-medium text-h5">Datos laborales</span>
      </v-flex>
      <v-flex xs12 class="d-flex align-center py-4">
         <span class="colorPrimary font-weight-medium" style="font-size: 20px">
            Indicaciones:
         </span>
      </v-flex>
      <v-flex xs12>
         <ul>
            <li>
               <div class="font-weight-medium">
                  Haga clic en el símbolo
                  <v-icon right left color="primary ">fas fa-plus</v-icon> para
                  agregar un nuevo elemento a la tabla.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Agregue en la tabla la información de las
                  <span class="colorPrimary">
                     personas que aportan a los gastos del hogar</span
                  >, incluyéndose usted si es uno de los que aporta.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Tenga presente que en el apartado de
                  <span class="colorPrimary">Ingresos y Egresos</span>
                  deberá adjuntar los comprobantes de ingresos de las personas
                  que aportan a los gastos del hogar.
               </div>
            </li>
         </ul>
      </v-flex>

      <!-- TABLA -->
      <v-flex xs12 mt-4 mb-8>
         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>

               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn
                        :disabled="completada"
                        text
                        icon
                        color="white darken-3"
                        v-on="on"
                        @click="completada ? false : nuevoNucleo()"
                     >
                        <v-icon>fas fa-plus</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px">Agregar</span>
               </v-tooltip>
            </v-toolbar>

            <!-- DATATABLE laborales -->
            <v-data-table
               :headers="headers"
               :items="laborales"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-data-text="Aún no se ha ingresado ningún dato laboral"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.tiempoLaboral="{ item }">
                  <span v-if="item.tiempoLaboralAnnios > 0">{{
                     `${item.tiempoLaboralAnnios} año(s) y `
                  }}</span>
                  <span>{{ `${item.tiempoLaboralMeses} meses` }}</span>
               </template>
               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :disabled="completada"
                           color="amber darken-3"
                           v-on="on"
                           @click="completada ? false : editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar </span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :disabled="completada"
                           color="primary"
                           v-on="on"
                           @click="completada ? false : deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Eliminar </span>
                  </v-tooltip>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         my-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="grey lighten-3"
            @click="cancelarDatosLaborales"
         >
            Paso anterior
         </v-btn>
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            color="primary"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            @click="nextStepDatosLaborales"
         >
            Continuar
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'

export default {
   name: 'FormBecaLaboral',
   props: {
      solicitudBecaId: {
         type: Number,
         default: 1
      },
      completada: {
         type: Boolean,
         default: false
      }
   },
   data: () => ({
      // Variables
      dialogNucleo: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Nombre', value: 'nombre', class: 'titulo' },
         { text: 'Parentesco', value: 'parentesco', class: 'titulo' },
         { text: 'Lugar de trabajo', value: 'lugarDeTrabajo', class: 'titulo' },
         { text: 'Tiempo laboral', value: 'tiempoLaboral', class: 'titulo' },
         { text: 'Salario', value: 'salario', class: 'titulo' },
         {
            text: 'Nombre de jefe inmediato',
            value: 'jefeInmediato',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            width: 120,
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      laborales: [],
      swGeneraVenta: false,

      validNucleo: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades bodega
      laboralId: '',
      nombre: '',
      lugarDeTrabajo: '',
      tiempoLaboralAnnios: '',
      tiempoLaboralMeses: '',
      salario: '',
      jefeInmediato: '',
      selectParentesco: '',
      cmbParentesco: [],

      // Reglas de validacion de formularios
      nombreRules: [(v) => !!v || 'Ingrese una nombre'],
      lugarDeTrabajoRules: [(v) => !!v || 'Ingrese un lugar de trabajo'],
      parentescoRules: [(v) => !!v || 'Ingrese un parentesco'],
      tiempoLaboralRules: [
         (v) => !!v || 'Ingrese un tiempo laboral',
         (v) => v === '' || /^(\d)+$/.test(v) || 'Formato de número inválido'
      ],
      salarioRules: [
         (v) => !!v || 'Ingrese un salario nominal',
         (v) =>
            v === '' || /^(\d*\.)?\d+$/.test(v) || 'Formato de número inválido'
      ],
      jefeInmediatoRules: [(v) => !!v || 'Ingrese el nombre del jefe inmediato']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Agregar dato laboral'
            : 'Editar dato laboral'
      }
   },
   watch: {
      solicitudBecaId(val) {
         if (val != null) {
            this.listarLaboral()
         }
      },
      laborales() {
         this.saveAll()
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      uppercase() {
         this.nombre = this.nombre.toUpperCase()
         this.jefeInmediato = this.jefeInmediato.toUpperCase()
      },
      nextStepDatosLaborales() {
         if (this.laborales.length > 0) {
            this.$emit('nextStep', 3.2)
         } else {
            this.snackText =
               'Para poder continuar, es obligatorio ingresar al menos un dato laboral.'
            this.snackColor = 'snackWarning'
            this.snackbar = true
         }
      },
      cancelarDatosLaborales() {
         this.$emit('cancelGoStep', 3)
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarLaboral() {
         // Obteniendo laborales
         axios
            .get(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/DatoLaboralFamiliar'
            )
            .then((response) => {
               if (response.data.length > 0) {
                  this.laborales = response.data
                  this.getCorrelativos()
               }
            })
      },
      listarComboboxes() {
         // Obteniendo laborales
         axios
            .get('api/general/Parentescos')
            .then((response) => {
               this.cmbParentesco = response.data
            })
            .catch(() => {
               //console.log(error)
            })
      },

      // Cargando informacion del item a editar
      editItem(item) {
         this.laboralId = item.id
         this.nombre = item.nombre
         this.lugarDeTrabajo = item.lugarDeTrabajo
         this.selectParentesco = item.parentescoId
         this.tiempoLaboralAnnios = item.tiempoLaboralAnnios
         this.tiempoLaboralMeses = item.tiempoLaboralMeses
         this.salario = item.salario
         this.jefeInmediato = item.jefeInmediato
         this.editedIndex = 1
         this.dialogNucleo = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover
      deleteItem(item) {
         this.dialogEliminar = true
         this.nombre = item.nombre
         this.laboralId = item.id

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogNucleo = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar
      saveNucleo() {
         if (this.$refs.formNucleo.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               this.laborales.splice(this.getIndexOf(), 1)
               this.laborales.push({
                  nombre: this.nombre,
                  parentescoId: this.selectParentesco,
                  parentesco: this.getParentesco(this.selectParentesco),
                  tiempoLaboralAnnios: this.tiempoLaboralAnnios,
                  tiempoLaboralMeses: this.tiempoLaboralMeses,
                  salario: this.salario,
                  jefeInmediato: this.jefeInmediato,
                  lugarDeTrabajo: this.lugarDeTrabajo,
                  solicitudBecaId: this.solicitudBecaId
               })
               this.close()
               this.snackText = 'Familiar editado exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.close()
               this.getCorrelativos()
            } else {
               // Codigo para guardar
               this.laborales.push({
                  nombre: this.nombre,
                  parentescoId: this.selectParentesco,
                  parentesco: this.getParentesco(this.selectParentesco),
                  tiempoLaboralAnnios: this.tiempoLaboralAnnios,
                  tiempoLaboralMeses: this.tiempoLaboralMeses,
                  salario: this.salario,
                  jefeInmediato: this.jefeInmediato,
                  lugarDeTrabajo: this.lugarDeTrabajo,
                  solicitudBecaId: this.solicitudBecaId
               })
               this.close()
               this.snackText = 'Familiar agregado exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formNucleo.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombre = ''
         this.lugarDeTrabajo = ''
         this.selectParentesco = ''
         this.salario = ''
         this.jefeInmediato = ''
         this.tiempoLaboralAnnios = ''
         this.tiempoLaboralMeses = ''
      },
      nuevoNucleo() {
         this.dialogNucleo = true
      },
      removerNucleo() {
         // Enviando metodo delete
         this.laborales.splice(this.getIndexOf(), 1)
         this.getCorrelativos()
         this.close()
      },
      getParentesco(parentescoId) {
         let nombre = ''
         this.cmbParentesco.forEach((element) => {
            //nombre = element.parentescoId == parentescoId ? element.nombre : ''
            if (element.parentescoId == parentescoId) {
               nombre = element.nombre
            }
         })
         return nombre
      },
      getCorrelativos() {
         for (let i = 0; i < this.laborales.length; i++) {
            this.laborales[i].id = i
         }
      },
      getIndexOf() {
         let index = -1
         for (let i = 0; i < this.laborales.length; i++) {
            if (this.laborales[i].id == this.laboralId) {
               index = i
            }
         }
         return index
      },
      saveAll() {
         axios.put(
            'api/solicitudesdebecas/' +
               this.solicitudBecaId +
               '/DatoLaboralFamiliar',
            this.laborales
         )
      }
   }
}
</script>
