<template>
   <v-layout aling-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <v-container :fluid="$vuetify.breakpoint.mdAndDown">
            <v-layout wrap>
               <v-flex xs12>
                  <v-stepper
                     v-model="stepNum"
                     non-linear
                     :class="$vuetify.breakpoint.xsOnly ? 'elevation-0' : ''"
                  >
                     <v-stepper-items
                        :class="$vuetify.breakpoint.smAndUp ? 'px-8' : 'py-2'"
                     >
                        <!-- PASO 1 -->
                        <v-stepper-content
                           step="1"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout wrap>
                              <v-flex xs12 class="font-weight-medium text-h5">
                                 ¿Puedo aplicar a una beca parcial?
                              </v-flex>

                              <v-flex v-if="formularioDeshabilitado" xs12 mt-8>
                                 <p
                                    class="colorPrimary font-weight-bold text-h6"
                                 >
                                    El formulario de consulta de becas está
                                    deshabilitado.
                                    <v-icon
                                       color="primary"
                                       style="margin-top: -5px"
                                       right
                                       size="30"
                                    >
                                       fas fa-info-circle
                                    </v-icon>
                                 </p>
                              </v-flex>
                              <v-flex
                                 v-if="!formularioDeshabilitado"
                                 xs12
                                 class="d-flex align-center py-4"
                              >
                                 <span
                                    class="colorPrimary font-weight-medium"
                                    style="font-size: 20px"
                                 >
                                    Indicaciones:
                                 </span>
                              </v-flex>
                              <v-flex v-if="!formularioDeshabilitado" xs12>
                                 <ul>
                                    <li>
                                       <div class="font-weight-medium">
                                          Por favor ingrese su
                                          <span class="colorPrimary"
                                             >número de carnet</span
                                          >
                                          y
                                          <span class="colorPrimary"
                                             >correo institucional</span
                                          >
                                          para comenzar.
                                       </div>
                                    </li>
                                    <li>
                                       <div class="font-weight-medium">
                                          Se enviará un
                                          <span class="colorPrimary"
                                             >código de 6 digitos</span
                                          >
                                          a su correo, el cual deberá ingresar
                                          en la aplicación para poder continuar.
                                       </div>
                                    </li>
                                 </ul>
                              </v-flex>
                              <v-flex xs12 class="my-12 d-flex justify-center">
                                 <v-card
                                    width="450"
                                    outlined
                                    class="py-4 px-4"
                                    rounded="xl"
                                 >
                                    <v-form
                                       ref="formCorreo"
                                       v-model="validForm"
                                       lazy-validation
                                    >
                                       <v-container grid-list-md fluid>
                                          <v-layout wrap>
                                             <v-flex xs12 sm4>
                                                <v-text-field
                                                   v-model="carnet"
                                                   v-mask="'####-AA-###'"
                                                   :disabled="
                                                      disableInputs ||
                                                      formularioDeshabilitado
                                                   "
                                                   :readonly="
                                                      disableInputs ||
                                                      formularioDeshabilitado
                                                   "
                                                   :rules="rulesCarnet"
                                                   label="Carnet"
                                                   color="primary"
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex xs12 sm8>
                                                <v-text-field
                                                   v-model="correo"
                                                   :disabled="
                                                      disableInputs ||
                                                      formularioDeshabilitado
                                                   "
                                                   :readonly="
                                                      disableInputs ||
                                                      formularioDeshabilitado
                                                   "
                                                   :rules="rulesCorreo"
                                                   label="Correo institucional"
                                                   color="primary"
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex v-if="countDown > 0" xs12>
                                                <span
                                                   class="colorPrimary font-weight-medium text-h6"
                                                   >Código de verificación</span
                                                >
                                             </v-flex>
                                             <v-flex v-if="countDown > 0" xs12>
                                                <v-text-field
                                                   v-model="codigoVerificacion"
                                                   v-mask="`######`"
                                                   class="centered-input"
                                                   color="primary"
                                                   hide-details
                                                   outlined
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex
                                                v-if="countDown > 0"
                                                xs12
                                                class="d-flex justify-center"
                                             >
                                                <span
                                                   class="colorPrimary font-weight-medium text-h6"
                                                >
                                                   {{ countDownToText }}
                                                </span>
                                             </v-flex>
                                          </v-layout>
                                       </v-container>
                                       <v-layout
                                          v-if="countDown == 0"
                                          justify-center
                                          align-center
                                          mt-2
                                       >
                                          <v-btn
                                             rounded
                                             color="primary"
                                             outlined
                                             :disabled="
                                                !validForm ||
                                                disableInputs ||
                                                formularioDeshabilitado
                                             "
                                             :width="
                                                $vuetify.breakpoint.xsOnly
                                                   ? '100%'
                                                   : ''
                                             "
                                             height="60px"
                                             x-large
                                             @click="enviarOTP"
                                          >
                                             {{ botonEnviarOtp }}
                                          </v-btn>
                                       </v-layout>
                                       <v-layout
                                          v-if="countDown > 0"
                                          justify-center
                                          align-center
                                          mt-2
                                       >
                                          <v-btn
                                             rounded
                                             color="primary"
                                             outlined
                                             :disabled="
                                                codigoVerificacion.length < 6
                                             "
                                             :width="
                                                $vuetify.breakpoint.xsOnly
                                                   ? '100%'
                                                   : ''
                                             "
                                             height="60px"
                                             x-large
                                             @click="verificarOTP"
                                          >
                                             Verificar
                                          </v-btn>
                                       </v-layout>
                                    </v-form>
                                 </v-card>
                              </v-flex>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 2 -->
                        <v-stepper-content
                           step="2"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout wrap>
                              <v-flex xs12 class="font-weight-medium text-h5">
                                 Formulario de preevaluación de becas
                              </v-flex>
                              <v-flex xs12 class="d-flex align-center py-4">
                                 <span
                                    class="colorPrimary font-weight-medium"
                                    style="font-size: 20px"
                                 >
                                    Indicaciones:
                                 </span>
                              </v-flex>
                              <v-flex xs12 mb-4>
                                 <ul>
                                    <li>
                                       <div class="font-weight-medium">
                                          Si deseas conocer si puedes aplicar a
                                          una beca parcial, por favor contesta
                                          las siguientes preguntas. Ten en
                                          cuenta que este es solamente un
                                          formulario con preguntas generales,
                                          posteriormente si el sistema te da la
                                          pauta para aplicar de manera
                                          definitiva al proceso, deberás
                                          respaldar tu solicitud con la
                                          documentación probatoria de tu
                                          situación socioeconómica y académica,
                                          la cual será exhaustivamente analizada
                                          y verificada por las autoridades de la
                                          Universidad.
                                       </div>
                                    </li>
                                 </ul>
                              </v-flex>
                              <v-flex
                                 v-for="(p, index) in preguntas"
                                 :key="p.preguntaId"
                                 xs12
                                 px-8
                              >
                                 <v-layout wrap>
                                    <v-flex xs12>
                                       <span class="font-weight-medium">
                                          {{ p.orden }}. {{ p.descripcion }}
                                       </span>
                                    </v-flex>
                                    <v-flex xs12 pl-6>
                                       <v-radio-group
                                          v-model="radioGroup[index]"
                                       >
                                          <v-radio
                                             v-for="op in p.opciones"
                                             :key="op.id"
                                             :label="`${String.fromCharCode(
                                                97 + op.orden - 1
                                             )}) ${op.respuesta}`"
                                             :value="op.id"
                                          ></v-radio>
                                       </v-radio-group>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12 class="d-flex justify-center py-4">
                                 <v-btn
                                    rounded
                                    :loading="enviarDisabled"
                                    :disabled="
                                       radioGroup.filter(
                                          (obj) => obj != undefined
                                       ).length < preguntas.length ||
                                       enviarDisabled
                                    "
                                    color="primary"
                                    outlined
                                    :width="
                                       $vuetify.breakpoint.xsOnly ? '100%' : ''
                                    "
                                    height="60px"
                                    x-large
                                    @click="validarPuntos"
                                 >
                                    Continuar
                                 </v-btn>
                              </v-flex>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 3 -->
                        <v-stepper-content
                           step="3"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-container>
                              <v-layout align-center wrap>
                                 <v-flex
                                    class="d-flex justify-center font-weight-medium text-center mb-8"
                                    xs12
                                 >
                                    <div
                                       style="
                                          max-width: 500px;
                                          font-size: 20px;
                                          word-break: break-word;
                                       "
                                    >
                                       <p class="text-justify">
                                          En base a los datos proporcionados
                                          usted podría ser candidato para
                                          solicitar una beca parcial.
                                       </p>
                                       <br />
                                       <p
                                          class="text-justify font-weight-medium"
                                       >
                                          Si desea continuar con el proceso
                                          tenga en cuenta que deberá
                                          proporcionar información exhaustiva
                                          sobre su situación socioeconómica, la
                                          cual será verificada por la
                                          Universidad. Entre los documentos que
                                          deberá presentar están: comprobantes
                                          de ingresos y egresos del grupo
                                          familiar, carencia de bienes (emitida
                                          por el CNR), declaración jurada de
                                          situación socioeconómica, referencias
                                          académicas y otros documentos que se
                                          soliciten.
                                       </p>
                                       <br />
                                       <p class="text-justify">
                                          Así mismo, se resalta que el número de
                                          becas a otorgar son limitadas y estas
                                          se otorgan a las personas que
                                          demuestran objetivamente su necesidad
                                          económica y que presenten un buen
                                          rendimiento académico.
                                       </p>
                                    </div>
                                 </v-flex>
                                 <v-flex
                                    xs12
                                    sm6
                                    :class="
                                       $vuetify.breakpoint.smAndUp
                                          ? 'd-flex justify-end pr-4'
                                          : 'd-flex justify-center'
                                    "
                                 >
                                    <v-btn
                                       :disabled="enviarDisabled"
                                       @click="sacameDeAqui"
                                    >
                                       No, no deseo continuar
                                    </v-btn>
                                 </v-flex>
                                 <v-flex
                                    xs12
                                    sm6
                                    :class="
                                       $vuetify.breakpoint.smAndUp
                                          ? 'd-flex justify-left pr-4'
                                          : 'd-flex justify-center mt-8'
                                    "
                                 >
                                    <v-btn
                                       :loading="enviarDisabled"
                                       :disabled="enviarDisabled"
                                       color="primary"
                                       @click="enviarFormulario"
                                    >
                                       Si, deseo continuar
                                    </v-btn>
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </v-stepper-content>

                        <!-- PASO 4 -->
                        <v-stepper-content
                           step="4"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-container>
                              <v-layout align-center wrap>
                                 <v-flex
                                    class="d-flex justify-center font-weight-medium text-center my-8"
                                    xs12
                                 >
                                    <div
                                       style="
                                          max-width: 500px;
                                          font-size: 20px;
                                          word-break: break-word;
                                       "
                                    >
                                       <p class="text-justify">
                                          Sentimos comunicarle que en base a los
                                          datos proporcionados no es candidato
                                          para solicitar una beca parcial. Si
                                          tiene dudas al respecto puede escribir
                                          al correo electrónico:
                                       </p>
                                       <span class="colorPrimary">
                                          direccion.serviciosestudiantiles@catolica.edu.sv
                                       </span>
                                    </div>
                                 </v-flex>
                                 <v-flex xs12 class="d-flex justify-center">
                                    <v-btn
                                       rounded
                                       color="primary"
                                       outlined
                                       :width="
                                          $vuetify.breakpoint.xsOnly
                                             ? '100%'
                                             : ''
                                       "
                                       height="60px"
                                       x-large
                                       @click="sacameDeAqui"
                                    >
                                       Salir
                                    </v-btn>
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </v-stepper-content>
                     </v-stepper-items>
                  </v-stepper>
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   name: 'FormularioPreevaluacion',
   directives: {
      mask
   },
   mixins: [formatters],
   data: () => ({
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      stepNum: 1,
      disableInputs: false,
      validForm: true,
      correo: '',
      countDown: 0,
      codigoVerificacion: '',
      preguntas: [],
      radioGroup: [],
      enviarDisabled: false,
      botonEnviarOtp: 'Continuar',
      formularioDeshabilitado: false,

      // Rules
      rulesCarnet: [
         (v) => !!v || 'Ingresa tu carnet',
         (v) => (v && v.length == 11) || 'Termina de escribir tu carnet'
      ],
      rulesCorreo: [
         (v) => !!v || 'Ingrese su correo institucional',
         (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
               v
            ) ||
            'Formato de correo incorrecto',
         (v) =>
            v === '' ||
            /@catolica.edu.sv$/.test(v) ||
            'El correo debe terminar en @catolica.edu.sv'
      ]
   }),
   computed: {
      ...authComputed,
      countDownToText() {
         var minutes = Math.floor(this.countDown / 60)
         var seconds = this.countDown - minutes * 60
         return `${this.padNum(minutes, 2)}:${this.padNum(seconds, 2)}`
      },
      totalPuntos() {
         var total = 0
         var index = 0
         this.radioGroup.forEach((el) => {
            total =
               Number(total) +
               Number(this.preguntas[index].opciones[el - 1].puntos)
            index++
         })
         return total
      }
   },
   watch: {},
   created() {
      this.listarConfiguraciones()
   },
   methods: {
      listarConfiguraciones() {
         axios
            .get('api/Configuracion/formBecasPreevaluacion')
            .then((response) => {
               if (response.data.valor == 'true') {
                  this.formularioDeshabilitado = false
               } else {
                  this.formularioDeshabilitado = true
               }
            })
            .finally(() => {
               if (!this.formularioDeshabilitado) {
                  this.listarPregruntas()
               }
            })
      },
      listarPregruntas() {
         axios.get('api/solicitudesdebecas/Preevaluacion').then((response) => {
            this.preguntas = response.data
         })
      },
      countDownTimer() {
         if (this.countDown > 0) {
            setTimeout(() => {
               this.countDown -= 1
               this.countDownTimer()
            }, 1000)
         } else if (this.countDown == 0) {
            this.disableInputs = false
         }
      },
      enviarOTP() {
         if (this.$refs.formCorreo.validate()) {
            this.disableInputs = true
            axios
               .post('api/solicitudesdebecas/Preevaluacion/generar-codigo', {
                  carnet: this.carnet,
                  email: this.correo
               })
               .then(() => {
                  this.countDown = 300
                  this.botonEnviarOtp = 'Reenviar código'
                  this.countDownTimer()
               })
               .catch((error) => {
                  this.disableInputs = false
                  this.showSnack(error.response.data.detail, 'snackError')
               })
         }
      },
      verificarOTP() {
         axios
            .get(
               `api/solicitudesdebecas/Preevaluacion/validar-correo?carnet=${this.carnet}&email=${this.correo}&code=${this.codigoVerificacion}`
            )
            .then(() => {
               this.listarPregruntas()
               this.stepNum = 2
            })
            .catch((error) => {
               this.showSnack(error.response.data.detail, 'snackError')
            })
      },
      validarPuntos() {
         var reprobadas = 1
         var irregular = 1
         axios
            .get(
               'api/solicitudesdebecas/Preevaluacion/validar-alumno?carnet=' +
                  this.carnet
            )
            .then((response) => {
               reprobadas = response.data.reprobadas
               irregular = response.data.irregular
            })
            .finally(() => {
               if (
                  this.totalPuntos >= 50 &&
                  reprobadas == 0 &&
                  irregular == 0
               ) {
                  // Aplica
                  this.stepNum = 3
               } else {
                  // No aplica
                  this.stepNum = 4
               }
            })
      },
      enviarFormulario() {
         this.enviarDisabled = true
         axios
            .post('api/solicitudesdebecas/Preevaluacion', {
               carnet: this.carnet,
               score: this.totalPuntos
            })
            .then(() => {
               // Aplica
               this.showSnack(
                  'Solicitud de beca enviada, por favor revisa tu bandeja de correo.',
                  'snackSuccess'
               )
            })
            .catch(() => {
               // No aplica
               this.showSnack('Algo ha ido mal.', 'snackError')
            })
            .finally(() => {
               setTimeout(() => {
                  this.sacameDeAqui()
               }, 4000)
            })
      },
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      }
   }
}
</script>

<style scoped>
.centered-input >>> input {
   text-align: center;
   font-weight: bold;
   font-size: 30px;
   color: var(--v-primary-base);
}
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
</style>
