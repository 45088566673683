<template>
   <v-layout align-start wrap>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}

            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR BODEGA -->
         <v-dialog v-model="dialogNucleo" persistent max-width="800px">
            <v-form ref="formNucleo" v-model="validNucleo" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm8>
                              <v-text-field
                                 v-model="nombre"
                                 class="upper"
                                 :rules="nombreRules"
                                 color="primary"
                                 label="Nombre"
                                 @keyup="uppercase"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm3>
                              <v-autocomplete
                                 v-model="selectParentesco"
                                 :items="cmbParentesco"
                                 :rules="paretescoRules"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="parentescoId"
                                 label="Parentesco"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              ></v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm1>
                              <v-text-field
                                 v-model="edad"
                                 label="Edad"
                                 type="number"
                                 :rules="edadRules"
                                 :min="1"
                                 :max="100"
                                 :step="1"
                              ></v-text-field>
                           </v-flex>

                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="profesion"
                                 :rules="profesionRules"
                                 color="primary"
                                 label="Profesión"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm4>
                              <v-checkbox
                                 v-model="estudiante"
                                 :label="`Actualmente es estudiante`"
                              ></v-checkbox>
                           </v-flex>
                           <v-flex xs12 sm8>
                              <v-text-field
                                 v-if="estudiante"
                                 v-model="institucion"
                                 :rules="institucionRules"
                                 color="primary"
                                 label="Institución"
                                 placeholder="Ingrese la institución donde estudia"
                              >
                              </v-text-field>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validNucleo"
                        @click.native="saveNucleo"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR  -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover familiar</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover el
                                 familiar?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ nombre }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerNucleo"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-flex xs12>
            <span class="font-weight-medium text-h5"
               >Datos del núcleo familiar
            </span>
         </v-flex>

         <v-flex xs12 class="d-flex align-center py-4">
            <span
               class="colorPrimary font-weight-medium"
               style="font-size: 20px"
            >
               Indicaciones:
            </span>
         </v-flex>
         <v-flex xs12>
            <ul>
               <li>
                  <div class="font-weight-medium">
                     Haga clic en el símbolo
                     <v-icon right left color="primary ">fas fa-plus</v-icon>
                     para agregar un nuevo elemento a la tabla.
                  </div>
               </li>
               <li>
                  <div class="font-weight-medium">
                     Agregue a la tabla la información de las personas que viven
                     en su casa.
                  </div>
               </li>
            </ul>
         </v-flex>

         <!-- TABLA -->
         <v-flex xs12 mt-4 mb-8>
            <v-card elevation="0">
               <v-toolbar color="primary" dark>
                  <v-spacer></v-spacer>
                  <v-text-field
                     ref="txtBusqueda"
                     v-model="search"
                     class="mt-3"
                     color="white"
                     append-icon="fa-search"
                     label="Búsqueda"
                     single-line
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           icon
                           color="white darken-3"
                           :disabled="completada"
                           v-on="on"
                           @click="completada ? false : nuevoNucleo()"
                        >
                           <v-icon>fas fa-plus</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Agregar </span>
                  </v-tooltip>
               </v-toolbar>

               <!-- DATATABLE nucleos -->
               <v-data-table
                  :headers="headers"
                  :items="nucleos"
                  :search="search"
                  :footer-props="{
                     itemsPerPageOptions: [10, 25, 50],
                     itemsPerPageText: 'Filas por página:'
                  }"
                  no-data-text="Aún no se ha ingresado ningún dato del núcleo familiar"
                  no-results-text="Búsqueda sin resultados"
                  class="elevation-1"
               >
                  <template #item.edad="{ item }">
                     <span v-if="item.edad === 0">N/A</span>
                     <span v-else>{{ item.edad }}</span></template
                  >
                  <template #item.opciones="{ item }">
                     <v-tooltip bottom max-width="300" color="primary">
                        <template #activator="{ on }">
                           <v-btn
                              text
                              small
                              fab
                              color="amber darken-3"
                              :disabled="completada"
                              v-on="on"
                              @click="completada ? false : editItem(item)"
                           >
                              <v-icon>fa-pen</v-icon>
                           </v-btn>
                        </template>
                        <span style="font-size: 15px"> Editar </span>
                     </v-tooltip>

                     <v-tooltip bottom max-width="300" color="primary">
                        <template #activator="{ on }">
                           <v-btn
                              text
                              small
                              fab
                              color="primary"
                              :disabled="completada"
                              v-on="on"
                              @click="completada ? false : deleteItem(item)"
                           >
                              <v-icon>fa-trash-alt</v-icon>
                           </v-btn>
                        </template>
                        <span style="font-size: 15px"> Eliminar </span>
                     </v-tooltip>
                  </template>
               </v-data-table>
            </v-card>
         </v-flex>
      </v-flex>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         my-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="grey lighten-3"
            @click="cancelarNucleoFamiliar"
         >
            Paso anterior
         </v-btn>
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            color="primary"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            @click="nextStepNucleoFamiliar"
         >
            Continuar
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'NucleroFamiliar',
   directives: {
      mask
   },
   props: {
      solicitudBecaId: {
         type: Number,
         default: null
      },
      completada: {
         type: Boolean,
         default: false
      }
   },
   data: () => ({
      // Variables
      dialogNucleo: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Nombre', value: 'nombre', class: 'titulo' },
         { text: 'Profesión', value: 'profesion', class: 'titulo' },
         { text: 'Parentesco', value: 'parentesco', class: 'titulo' },
         { text: 'Edad', value: 'edad', class: 'titulo' },
         {
            text: 'Institucion',
            value: 'institucionEducativa',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            width: 120,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      nucleos: [],
      swGeneraVenta: false,

      validNucleo: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades
      nucleoFamiliarId: '',
      nombre: '',
      profesion: '',
      selectParentesco: '',
      edad: '',
      institucion: '',
      estudiante: false,
      cmbParentesco: [],

      // Reglas de validacion de formularios
      nombreRules: [(v) => !!v || 'Ingrese una nombre'],
      profesionRules: [(v) => !!v || 'Ingrese una profesión'],
      paretescoRules: [(v) => !!v || 'Ingrese un parentesco'],
      edadRules: [
         (v) => !!v || 'Ingrese la edad',
         (v) => v < 100 || 'No puede ser mayor a 100',
         (v) => v >= 1 || 'Ingrese un numero mayor o igual a 1'
      ],
      institucionRules: [(v) => !!v || 'Ingrese la institución']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Agregar otro miembro de la familia'
            : 'Editar familiar'
      }
   },
   watch: {
      solicitudBecaId(val) {
         if (val != null) {
            this.listarNucleo()
         }
      },
      nucleos() {
         this.saveAll()
      },
      estudiante(newVal) {
         if (newVal) {
            this.profesion = 'Estudiante'
         }
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      uppercase() {
         this.nombre = this.nombre.toUpperCase()
      },
      nextStepNucleoFamiliar() {
         if (this.nucleos.length > 0) {
            this.$emit('nextStep', 3.3)
         } else {
            this.snackText =
               'Para poder continuar, es obligatorio ingresar al menos un dato de su núcleo familiar.'
            this.snackColor = 'snackWarning'
            this.snackbar = true
         }
      },
      cancelarNucleoFamiliar() {
         this.$emit('cancelGoStep', 3.1)
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarNucleo() {
         // Obteniendo nucleos
         axios
            .get(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/NucleoFamiliar'
            )
            .then((response) => {
               if (response.data.length > 0) {
                  this.nucleos = response.data
                  this.getCorrelativos()
               }
            })
      },
      listarComboboxes() {
         // Obteniendo nucleos
         axios
            .get('api/general/Parentescos')
            .then((response) => {
               this.cmbParentesco = response.data
            })
            .catch(() => {
               //console.log(error)
            })
      },
      // Cargando informacion del item a editar
      editItem(item) {
         this.nucleoFamiliarId = item.id
         this.nombre = item.nombre
         this.profesion = item.profesion
         this.selectParentesco = item.parentescoId
         this.edad = item.edad
         this.institucion = item.institucion
         this.estudiante = item.esEstudiante
         this.editedIndex = 1
         this.institucion = item.institucionEducativa
         this.dialogNucleo = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         this.dialogEliminar = true
         this.nombre = item.nombre
         this.nucleoFamiliarId = item.id

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogNucleo = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      saveNucleo() {
         if (this.$refs.formNucleo.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               this.nucleos.splice(this.getIndexOf(), 1)
               this.nucleos.push({
                  nombre: this.nombre.toUpperCase(),
                  parentescoId: this.selectParentesco,
                  parentesco: this.getParentesco(this.selectParentesco),
                  profesion: this.profesion,
                  edad: this.edad,
                  institucionEducativa: this.institucion,
                  solicitudBecaId: this.solicitudBecaId,
                  esEstudiante: this.estudiante
               })
               this.close()
               this.snackText = 'Familiar editado exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.close()
               this.getCorrelativos()
            } else {
               // Codigo para guardar
               this.nucleos.push({
                  nombre: this.nombre.toUpperCase(),
                  parentescoId: this.selectParentesco,
                  parentesco: this.getParentesco(this.selectParentesco),
                  profesion: this.profesion,
                  edad: this.edad,
                  institucionEducativa: this.institucion,
                  solicitudBecaId: this.solicitudBecaId,
                  esEstudiante: this.estudiante
               })

               this.close()
               this.snackText = 'Familiar agregado exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formNucleo.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombre = ''
         this.profesion = ''
         this.edad = ''
         this.institucion = ''
         this.selectParentesco = ''
         this.estudiante = false
      },
      nuevoNucleo() {
         this.dialogNucleo = true
      },
      removerNucleo() {
         // Enviando metodo delete
         this.nucleos.splice(this.getIndexOf(), 1)
         this.getCorrelativos()
         this.close()
      },
      getParentesco(parentescoId) {
         let nombre = ''
         this.cmbParentesco.forEach((element) => {
            //nombre = element.parentescoId == parentescoId ? element.nombre : ''
            if (element.parentescoId == parentescoId) {
               nombre = element.nombre
            }
         })
         return nombre
      },
      getCorrelativos() {
         for (let i = 0; i < this.nucleos.length; i++) {
            this.nucleos[i].id = i
         }
      },
      getIndexOf() {
         let index = -1
         for (let i = 0; i < this.nucleos.length; i++) {
            if (this.nucleos[i].id == this.nucleoFamiliarId) {
               index = i
            }
         }
         return index
      },
      saveAll() {
         axios.put(
            'api/solicitudesdebecas/' +
               this.solicitudBecaId +
               '/NucleoFamiliar',
            this.nucleos
         )
      }
   }
}
</script>
