<template>
   <v-layout align-start wrap>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}

         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <v-flex xs12>
         <span class="font-weight-medium text-h5"
            >Ingresos y egresos familiares</span
         >
      </v-flex>
      <v-flex xs12 class="d-flex align-center py-4">
         <span class="colorPrimary font-weight-medium" style="font-size: 20px">
            Indicaciones:
         </span>
      </v-flex>
      <v-flex xs12 mb-6>
         <ul>
            <li>
               <div class="font-weight-medium">
                  Haga clic en el símbolo
                  <v-icon right left color="primary ">fas fa-plus</v-icon> para
                  agregar nuevos elementos a las tablas.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Por favor complete las tablas que se muestran a continuación
                  con los ingresos y egresos familiares.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Como comprobante de ingreso del
                  <span class="colorPrimary">sector formal</span>, puede agregar
                  constancia de salario o boleta de pago actualizada. Si los
                  ingresos son del
                  <span class="colorPrimary">sector informal</span> debe
                  adjuntar una nota detallando el negocio o actividad económica
                  a la que se dedica, la persona que cubre los gastos del hogar
                  y además deberá adjuntar una fotografía del negocio o
                  actividad económica.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Como comprobantes de
                  <span class="colorPrimary">egresos</span> adjuntar los
                  comprobantes del último mes, datos desactualizados o
                  incompletos invalidan su solicitud.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Los comprobantes deben ser legibles y debe estar visible la
                  fecha de vencimiento, el nombre y dirección del propietario
               </div>
            </li>
         </ul>
      </v-flex>

      <!-- TABLA -->
      <v-flex xs12>
         <FormBecasIngresos
            :solicitud-beca-id="solicitudBecaId"
            :completada="completada"
         />
      </v-flex>
      <v-flex xs12 mt-6>
         <FormBecasEgresos
            :solicitud-beca-id="solicitudBecaId"
            :completada="completada"
         />
      </v-flex>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         my-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="grey lighten-3"
            @click="cancelarIngresoEgresos"
         >
            Paso anterior
         </v-btn>
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            color="primary"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            @click="nextStepIngresoEgresos"
         >
            Continuar
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import FormBecasEgresos from './FormularioBecasEgresos'
import FormBecasIngresos from './FormularioBecasIngresos'

export default {
   name: 'FormBecaContIngresosEgresos',
   components: {
      FormBecasEgresos,
      FormBecasIngresos
   },
   props: {
      solicitudBecaId: {
         type: Number,
         default: null
      },
      completada: {
         type: Boolean,
         default: false
      }
   },
   data: () => ({
      // Variables<
      laboralId: '',
      nombre: '',
      lugarDeTrabajo: '',
      tiempoLaboral: '',
      salario: '',
      jefeInmediato: '',
      selectParentesco: '',
      cmbParentesco: [],
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      beneficiarioId: null,

      // Reglas de validacion de formularios
      nombreRules: [(v) => !!v || 'Ingrese una nombre']
   }),
   computed: {
      ...authComputed
   },
   watch: {
      solicitudBecaId(val) {
         if (val != null) {
            axios
               .get('api/SolicitudDeBeca/' + val)
               .then((response) => {
                  this.beneficiarioId = response.data.beneficiarioId
               })
               .catch(() => {
                  // Do nothing
               })
         }
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      nextStepIngresoEgresos() {
         // Antes de continuar realizar validaciones
         var ingresos = []
         var egresos = []
         axios
            .get(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/SolicitudIngreso'
            )
            .then((response) => {
               ingresos = response.data
            })
            .finally(() => {
               // Debe tener al menos un ingreso
               if (ingresos.length > 0) {
                  // Revisar egresos
                  axios
                     .get(
                        'api/solicitudesdebecas/' +
                           this.solicitudBecaId +
                           '/SolicitudEgreso'
                     )
                     .then((response) => {
                        egresos = response.data
                     })
                     .finally(() => {
                        // Los egresos de energía eléctrica, agua, teléfono (internet), alimentación son obligatorios
                        if (
                           egresos.some((e) => e.egresoId == 2) &&
                           egresos.some((e) => e.egresoId == 3) &&
                           egresos.some((e) => e.egresoId == 4) &&
                           egresos.some((e) => e.egresoId == 5)
                        ) {
                           axios
                              .get(
                                 `api/solicitudesdebecas/${this.solicitudBecaId}/BienesFamiliares`
                              )
                              .then((response) => {
                                 var result = response.data
                                 // Si la vivienda es propia no mostrar comprobante de pago de vivienda
                                 if (result.tipoViviendaId == 1) {
                                    // Continuar
                                    if (this.beneficiarioId != 4) {
                                       this.$emit('nextStep', 3.5)
                                    } else {
                                       this.$emit('nextStep', 4)
                                    }
                                 } else {
                                    if (egresos.some((e) => e.egresoId == 9)) {
                                       // Continuar
                                       if (this.beneficiarioId != 4) {
                                          this.$emit('nextStep', 3.5)
                                       } else {
                                          this.$emit('nextStep', 4)
                                       }
                                    } else {
                                       this.snackText =
                                          'Debido a que en su vivienda es alquilada/promesa de venta es necesario que adjunte un comprobante de pago de vivienda.'
                                       this.snackColor = 'snackWarning'
                                       this.snackbar = true
                                    }
                                 }
                              })
                        } else {
                           this.snackText =
                              'Para poder continuar, es obligatorio adjuntar los comprobantes de agua, energía eléctrica, teléfono/internet y alimentación.'
                           this.snackColor = 'snackWarning'
                           this.snackbar = true
                        }
                     })
               } else {
                  this.snackText =
                     'Para poder continuar debe adjuntar al menos un ingreso.'
                  this.snackColor = 'snackWarning'
                  this.snackbar = true
               }
            })
      },
      cancelarIngresoEgresos() {
         this.$emit('cancelGoStep', 3.3)
      },
      listarComboboxes() {
         // Obteniendo laborales
         axios
            .get('api/general/Parentescos')
            .then((response) => {
               this.cmbParentesco = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
