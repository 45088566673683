<template>
   <v-layout align-start wrap>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}

         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <v-flex xs12>
         <span class="font-weight-medium text-h5">Ensayo</span>
      </v-flex>
      <v-flex xs12 class="d-flex align-center py-4">
         <span class="colorPrimary font-weight-medium" style="font-size: 20px">
            Indicaciones:
         </span>
      </v-flex>
      <v-flex xs12>
         <ul>
            <li>
               <div class="font-weight-medium">
                  Por favor describa: ¿Por qué se considera un buen candidato
                  para recibir la beca parcial? (Debe describir su situación
                  socioeconómica. El texto debe tener como mínimo 100 palabras y
                  como máximo 150 palabras).
               </div>
            </li>
         </ul>
      </v-flex>

      <!-- TABLA -->
      <v-flex
         xs12
         mt-4
         :style="
            $vuetify.breakpoint.mdAndUp
               ? 'margin-right: 12%; margin-left: 12%;'
               : ''
         "
      >
         <v-form ref="formEnsayo" v-model="validEnsayo" lazy-validation>
            <v-textarea
               v-model="ensayo"
               outlined
               rows="10"
               label="Ensayo"
               color="primary"
               :rules="rulesEnsayo"
               :readonly="completada"
               @keyup="countWords"
            ></v-textarea>
         </v-form>
      </v-flex>
      <v-flex xs12 class="d-flex justify-center mb-8">
         <span class="colorPrimary text-h5 font-weight-medium"
            >Cantidad de palabras: {{ numPalabras }}</span
         >
      </v-flex>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         my-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="grey lighten-3"
            @click="cancelarEnsayo"
         >
            Paso anterior
         </v-btn>
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            color="primary"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="!validEnsayo"
            @click="nextStepEnsayo"
         >
            Continuar
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'

export default {
   name: 'FormBecaEnsayo',
   props: {
      solicitudBecaId: {
         type: Number,
         default: null
      },
      completada: {
         type: Boolean,
         default: false
      }
   },
   data: (vm) => ({
      // Variables
      laboralId: '',
      nombre: '',
      lugarDeTrabajo: '',
      tiempoLaboral: '',
      salario: '',
      jefeInmediato: '',
      selectParentesco: '',
      cmbParentesco: [],
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      ensayo: '',
      numPalabras: 0,
      validEnsayo: true,

      // Reglas de validacion de formularios
      rulesEnsayo: [
         (v) =>
            (v && vm.numPalabras >= 100) ||
            'El ensayo debe tener al menos 100 palabras.',
         (v) =>
            (v && vm.numPalabras <= 150) ||
            'El ensayo de no debe exceder las 150 palabras.'
      ]
   }),
   computed: {
      ...authComputed
   },
   watch: {
      solicitudBecaId(val) {
         if (val != null) {
            axios
               .get('api/SolicitudDeBeca/' + val)
               .then((response) => {
                  this.ensayo =
                     response.data.ensayo == null ? '' : response.data.ensayo
                  this.countWords()
               })
               .catch(() => {
                  // Do nothing
               })
         }
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      countWords() {
         var str = this.ensayo
         str = str.replace(/(^\s*)|(\s*$)/gi, '')
         str = str.replace(/[ ]{2,}/gi, ' ')
         str = str.replace(/\n /, '\n')
         if (str == '') {
            this.numPalabras = 0
         } else {
            this.numPalabras = str.split(' ').length
         }
      },
      nextStepEnsayo() {
         if (this.$refs.formEnsayo.validate()) {
            axios
               .put('api/SolicitudDeBeca/ensayo/' + this.solicitudBecaId, {
                  text: this.ensayo
               })
               .catch(() => {
                  // Do nothing
               })
               .finally(() => {
                  this.$emit('nextStep', 4)
               })
         }
      },
      cancelarEnsayo() {
         this.$emit('cancelGoStep', 3.4)
      },
      listarComboboxes() {
         // Obteniendo laborales
         axios
            .get('api/general/Parentescos')
            .then((response) => {
               this.cmbParentesco = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
