<template>
   <v-layout align-start wrap>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}

         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <!-- DIALOG MENSAJE -->
      <v-dialog v-model="dialogCompletada" persistent max-width="425px">
         <v-card class="pb-4">
            <v-card-text class="text--primary">
               <v-container grid-list-md fluid>
                  <v-layout wrap>
                     <v-flex xs12 mt-2>
                        <p
                           class="text-justify font-weight-medium pt-4"
                           style="font-size: 18px"
                        >
                           <span>
                              Ha completado correctamente su solicitud de beca
                              parcial.
                           </span>
                           <br /><br />
                           <span>
                              Puede consultar el estado de su solicitud en
                              cualquier momento accediento por medio del
                              <span class="colorPrimary">enlace de acceso</span>
                              el cual le fue proveído por correo electrónico.
                           </span>
                           <v-img
                              src="/assets/thumbs-up-400.png"
                              class="tiltImage my-8"
                              contain
                              height="100"
                           ></v-img>
                        </p>
                     </v-flex>
                     <v-flex xs12>
                        <v-btn
                           :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
                           color="primary"
                           large
                           rounded
                           block
                           @click="sacameDeAqui"
                        >
                           Entendido
                        </v-btn>
                     </v-flex>
                  </v-layout>
               </v-container>
            </v-card-text>
         </v-card>
      </v-dialog>

      <v-flex xs12>
         <span class="font-weight-medium text-h5">Comprobantes</span>
      </v-flex>
      <v-flex xs12 class="d-flex align-center py-4">
         <span class="colorPrimary font-weight-medium" style="font-size: 20px">
            Indicaciones:
         </span>
      </v-flex>
      <v-flex xs12>
         <ul>
            <li>
               <div class="font-weight-medium">
                  Por favor suba a la aplicación los documentos que se le
                  solicitan a continuación.
               </div>
            </li>
            <li>
               <div class="font-weight-medium">
                  Los comprobantes deben ser legibles y debe estar visible la
                  fecha de vencimiento, el nombre y dirección del propietario
               </div>
            </li>
            <li v-if="beneficiarioId == 1">
               <div class="font-weight-medium">
                  La <span class="colorPrimary">carencia de bienes</span> se
                  debe solicitar a nombre del padre, madre o responsable del
                  estudiante.
               </div>
            </li>
            <li v-if="beneficiarioId == 2">
               <div class="font-weight-medium">
                  La <span class="colorPrimary">carencia de bienes</span> se
                  debe solicitar a nombre de la persona que costea los estudios
                  de maestría.
               </div>
            </li>
         </ul>
      </v-flex>

      <v-dialog v-model="dialogEditar" persistent max-width="600px">
         <v-form
            ref="formComprobante"
            v-model="validComprobante"
            lazy-validation
         >
            <v-card>
               <v-card-text class="text--primary" style="margin-top: -20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium">
                              Editar comprobante
                           </span>
                        </v-flex>
                     </v-layout>
                     <v-layout wrap>
                        <v-flex xs12 sm12>
                           <v-text-field
                              v-model="comprobante"
                              readonly
                              color="primary"
                              label="Comprobante"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                           <v-file-input
                              v-model="archivosAdjuntos"
                              color="primary"
                              :rules="adjuntosRules"
                              label="Archivos adjuntos"
                              placeholder="Selecciona tu archivo"
                              prepend-icon="fa-paperclip"
                              accept=".pdf, image/png, image/jpeg, image/bmp"
                              outlined
                              multiple
                              counter
                              :show-size="1000"
                           >
                              <template #selection="{ text }">
                                 <v-chip color="primary" dark label small>
                                    {{ text }}
                                 </v-chip>
                              </template>
                           </v-file-input>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions
                  style="
                     margin-top: -40px;
                     margin-right: 20px;
                     padding-bottom: 20px;
                  "
               >
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click.native="close"
                     >Cancelar</v-btn
                  >
                  <v-btn
                     color="primary white--text"
                     :disabled="!validComprobante"
                     @click.native="save"
                     >Enviar</v-btn
                  >
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <v-dialog v-model="dialogEnviandoComp" persistent width="300">
         <v-card color="primary" dark class="pt-5 pb-2">
            <v-card-text style="color: white">
               Enviando comprobante
               <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
               ></v-progress-linear>
            </v-card-text>
         </v-card>
      </v-dialog>

      <!-- DIALOG OBSERVACION EN COMPROBANTE -->
      <v-dialog v-model="dialogObsComprobante" width="500" persistent>
         <v-form ref="formObservacion" lazy-validation>
            <v-card>
               <v-card-title
                  class="text-h5 primary white--text font-weight-medium"
               >
                  Ingrese el comentario
               </v-card-title>
               <v-card-text class="mt-4">
                  <v-flex
                     xs12
                     mt-4
                     :style="
                        $vuetify.breakpoint.mdAndUp
                           ? 'margin-right: 12%; margin-left: 12%;'
                           : ''
                     "
                  >
                     <v-textarea
                        v-model="observacion"
                        :rules="rulesObservacion"
                        :loading="loadingNotificacion"
                        :readonly="!oidcUser"
                        outlined
                        rows="5"
                        label="Comentario"
                        color="primary"
                        no-resize
                     ></v-textarea>
                  </v-flex>
               </v-card-text>
               <v-divider></v-divider>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                     color="primary"
                     :disabled="observacion == null || observacion == ''"
                     text
                     @click="notificarComprobante"
                  >
                     Enviar
                  </v-btn>

                  <v-btn color="primary" text @click="closeObsComprobante">
                     Salir
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <!-- DIALOG COMENTARIOS ASESOR -->
      <v-dialog v-model="dialogObservacion" width="600">
         <v-card>
            <ComentarioAsesor :width="600" :comentarios="comentarios" />
            <v-divider></v-divider>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="primary" text @click="dialogObservacion = false">
                  Salir
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <!-- TABLA -->
      <v-flex xs12 mt-4 mb-8>
         <v-card elevation="0" outlined>
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Subir comprobantes de becas</v-toolbar-title
               >
               <v-spacer></v-spacer>
            </v-toolbar>

            <!-- DATATABLE detalles -->
            <v-data-table
               :headers="headers"
               :items="detalles"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [-1],
                  itemsPerPageText: 'Filas por página:'
               }"
               :items-per-page="-1"
               hide-default-footer
               :loading="loadingDetalles"
               loading-text="Listando detalles..."
               no-data-text="No necesita subir comprobantes adicionales"
               no-results-text="Búsqueda sin resultados"
               class="elevation-0"
            >
               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           v-if="item.path != null"
                           text
                           small
                           fab
                           :disabled="completada"
                           color="amber darken-3"
                           v-on="on"
                           @click="completada ? false : showEditar(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar</span>
                  </v-tooltip>

                  <v-btn
                     v-if="item.path == null"
                     text
                     small
                     fab
                     color="amber darken-3"
                     :disabled="item.path != null || completada"
                     @click="completada ? false : editItem(item)"
                  >
                     <v-icon>fa-upload</v-icon>
                  </v-btn>
                  <v-tooltip v-else right max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           v-if="item.path != null"
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="visualizar(item)"
                        >
                           <v-icon>fas fa-download</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Clic para ver </span>
                  </v-tooltip>

                  <v-btn
                     v-if="item.path == null && item.comprobanteId == 1"
                     text
                     small
                     color="primary"
                     @click="descargarPlantillaDeclaracion"
                  >
                     Descargar plantilla
                  </v-btn>
                  <v-btn
                     v-if="
                        item.path == null &&
                        (item.comprobanteId == 3 || item.comprobanteId == 15)
                     "
                     text
                     small
                     color="primary"
                     @click="descargarPlantillaReferencia"
                  >
                     Descargar plantilla
                  </v-btn>
               </template>
               <template #item.revision="{ item }">
                  <v-tooltip bottom color="primary" :open-on-focus="false">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :color="item.validado ? 'primary' : 'grey lighten-1'"
                           :disabled="item.path == null || item.validado"
                           v-on="on"
                           @click="validarComprobante(item)"
                        >
                           <v-icon>fa-check</v-icon>
                        </v-btn>
                     </template>
                     <span>Verificar</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary" :open-on-focus="false">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :color="
                              item.notificaciones > 0
                                 ? 'primary'
                                 : 'grey lighten-1'
                           "
                           :disabled="item.path == null || item.validado"
                           v-on="on"
                           @click="showObservacion(item)"
                        >
                           <v-icon>fas fa-times</v-icon>
                        </v-btn>
                     </template>
                     <span
                        >Número de Notificaciones:
                        {{ item.notificaciones }}</span
                     >
                  </v-tooltip>
               </template>

               <template #item.comentarios="{ item }">
                  <v-tooltip bottom color="primary" :open-on-focus="false">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           :disabled="
                              completada || item.path === null || item.validado
                           "
                           v-on="on"
                           @click="showObservacion(item)"
                        >
                           <v-icon>fas fa-file-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Observación</span>
                  </v-tooltip>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>

      <!-- COMENTARIOS -->
      <v-flex v-if="checkModificarAsesor()" xs12 mt-4>
         <p>
            <span class="font-weight-medium" style="font-size: 20px">
               Comentario de
               <span class="colorPrimary"> administrador de becas </span>
            </span>
         </p>
         <v-layout wrap>
            <v-flex xs12>
               <v-textarea
                  v-model="commAdminBecas"
                  :readonly="selectEstadoCaso == 3 || !checkRangoAlto()"
                  label="Comentario"
                  color="primary"
                  outlined
               >
               </v-textarea>
            </v-flex>
         </v-layout>
      </v-flex>
      <v-flex v-if="oidcUser" xs12 mt-4>
         <p class="mb-0 pb-0">
            <span class="font-weight-medium" style="font-size: 20px">
               Comentario de
               <span class="colorPrimary"> asesor </span>
            </span>
         </p>
         <v-layout wrap>
            <v-flex xs12 class="d-flex align-center">
               <span
                  class="colorPrimary font-weight-medium"
                  style="font-size: 20px"
               >
                  Indicaciones para asesor:
               </span>
            </v-flex>
            <v-flex xs12 mb-4>
               <ul>
                  <li>
                     <div class="font-weight-medium">
                        Verificar cada comprobante adjuntado por el estudiante,
                        que corresponda al rubro correcto y que esté
                        actualizado.
                     </div>
                  </li>
                  <li>
                     <div class="font-weight-medium">
                        Agregar en comentarios cualquier irregularidad
                        encontrada.
                     </div>
                  </li>
                  <li>
                     <div class="font-weight-medium">
                        Si cambia el estado de la solicitud a
                        <span class="colorPrimary">evaluado</span> está
                        indicando que evaluó con detalle cada parte de la
                        aplicación, que verificó que cada comprobante esté
                        correcto y que se entrevistó con el estudiante para
                        indagar más acerca de su situación socioeconómica.
                     </div>
                  </li>
               </ul>
            </v-flex>
            <v-flex xs12>
               <v-textarea
                  v-model="commAsesor"
                  label="Comentario"
                  :readonly="selectEstadoCaso == 3"
                  color="primary"
                  outlined
               >
               </v-textarea>
            </v-flex>
         </v-layout>
      </v-flex>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         my-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="grey lighten-3"
            @click="cancelarComprobante"
         >
            Paso anterior
         </v-btn>
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            :disabled="completada || dialogCompletada"
            color="primary"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            @click="completarFormulario"
         >
            Enviar formulario
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import debounce from 'lodash/debounce'
import ComentarioAsesor from '@/components/ComentarioAsesor.vue'

export default {
   name: 'FormBecaComprobantes',
   components: {
      ComentarioAsesor
   },
   mixins: [formatters],
   props: {
      solicitudBecaId: {
         type: Number,
         default: null
      },
      beneficiarioId: {
         type: Number,
         default: null
      },
      completada: {
         type: Boolean,
         default: false
      },
      listar: {
         type: Boolean,
         default: false
      }
   },
   data: () => ({
      // Variables
      laboralId: '',
      nombre: '',
      lugarDeTrabajo: '',
      tiempoLaboral: '',
      salario: '',
      jefeInmediato: '',
      selectParentesco: '',
      cmbParentesco: [],
      poseeVehiculo: false,
      dialogCompletada: false,

      // Variables
      dialogEditar: false,
      editedIndex: -1,
      loadingDetalles: false,
      selectCiclo: '',
      headers: [
         {
            text: 'Comprobante',
            value: 'comprobante',
            class: 'titulo',
            sortable: false
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'center',
            sortable: false,
            class: 'titulo'
         },
         {
            text: 'Revisión',
            value: 'revision',
            align: 'center',
            sortable: false,
            class: 'titulo'
         }
      ],
      dialogObsComprobante: false,
      dialogObservacion: false,
      comentarios: [],
      observacion: null,
      comprobanteInfo: {},
      loadingNotificacion: false,

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      detalles: [],
      swGeneraVenta: false,

      validComprobante: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      rutaArchivoAdjunto: '',
      comentarioAsesor: '',
      comentarioAdminBecas: '',
      solicitudInfo: [],
      selectEstadoCaso: '',

      // Propiedades bodega
      comprobanteId: '',
      comprobante: '',
      pathComprobanteId: '',
      archivosAdjuntos: [],
      dialogEnviandoComp: false,

      // Reglas de validacion de formularios
      adjuntosRules: [
         (files) => files.length > 0 || 'Seleccione un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'application/pdf') ||
            !files.some((file) => file.type != 'image/png') ||
            !files.some((file) => file.type != 'image/jpeg') ||
            !files.some((file) => file.type != 'image/bmp') ||
            'Solo se permiten archivos PDF e imágenes',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ],
      rulesObservacion: [(v) => !!v || 'Ingrese un comentario']
   }),
   computed: {
      ...authComputed,
      // Delay en comentario asesor
      commAsesor: {
         get() {
            return this.comentarioAsesor
         },
         set: debounce(function (newVal) {
            this.comentarioAsesor = newVal
            axios
               .put(
                  'api/SolicitudDeBeca/comentarioAsesor/' +
                     this.solicitudBecaId,
                  {
                     text: newVal
                  }
               )
               .then(() => {
                  this.snackText = 'Comentario de asesor actualizado'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
               })
               .catch(() => {
                  this.snackText =
                     'No se pudo actualizar el comentario de asesor'
                  this.snackColor = 'snackError'
                  this.snackbar = true
               })
         }, 500)
      },
      // Delay en comentario admin becas
      commAdminBecas: {
         get() {
            return this.comentarioAdminBecas
         },
         set: debounce(function (newVal) {
            this.comentarioAdminBecas = newVal
            axios
               .put(
                  'api/SolicitudDeBeca/comentarioAdmin/' + this.solicitudBecaId,
                  {
                     text: newVal
                  }
               )
               .then(() => {
                  this.snackText = 'Comentario de administrador actualizado'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
               })
               .catch(() => {
                  this.snackText =
                     'No se pudo actualizar el comentario de administrador'
                  this.snackColor = 'snackError'
                  this.snackbar = true
               })
         }, 500)
      }
   },
   watch: {
      listar() {
         if (
            this.solicitudBecaId != null &&
            this.solicitudBecaId != undefined
         ) {
            this.listarComprobantes()
         }
      },
      solicitudBecaId(val) {
         if (val != null) {
            this.listarComprobantes()
            axios.get('api/SolicitudDeBeca/' + val).then((response) => {
               this.solicitudInfo = response.data
            })
         }
      },
      solicitudInfo(val) {
         this.comentarioAdminBecas = val.comentarioAdministrador
         this.comentarioAsesor = val.comentarioAsesor
         this.selectEstadoCaso = val.estadoCasoId
      }
   },
   created() {
      this.listarComboboxes()
      //Cambiando Nombre de Columna Cuando el Alumno Consulta su Solicitud
      if (!this.oidcUser) {
         this.headers[this.headers.length - 1].text = 'Comentarios'
         this.headers[this.headers.length - 1].value = 'comentarios'
      }
   },
   methods: {
      showObservacion(item) {
         if (item.validado) return
         this.comprobanteInfo = item
         if (!this.oidcUser) {
            this.dialogObservacion = true
            axios
               .get(
                  '/api/FileUploadNotificationLog/comprobantes?solicitudId=' +
                     this.solicitudBecaId +
                     '&comprobanteId=' +
                     item.pathComprobanteId
               )
               .then((res) => {
                  this.comentarios = res.data
               })
               .catch((err) => console.log(err))
         } else {
            this.dialogObsComprobante = true
         }
      },
      notificarComprobante() {
         if (this.comprobanteInfo.validado || this.loadingNotificacion) return
         if (this.$refs.formObservacion.validate()) {
            this.loadingNotificacion = true
            axios
               .put(
                  '/api/solicitudesdebecas/' +
                     this.solicitudBecaId +
                     '/PathComprobante/' +
                     this.comprobanteInfo.pathComprobanteId +
                     '/notificar',
                  {
                     text: this.observacion
                  }
               )
               .then(() => {
                  this.snackText = 'Se ha enviado un correo al alumno'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.loadingNotificacion = false
                  this.closeObsComprobante()
               })
               .catch((err) => console.log(err))
               .finally(() => {
                  this.listarComprobantes()
                  this.loadingNotificacion = false
               })
         }
      },
      validarComprobante(item) {
         if (item.validado) return
         axios
            .put(
               '/api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/PathComprobante/' +
                  item.pathComprobanteId +
                  '/validar',
               {
                  value: true
               }
            )
            .then(() => {
               this.snackText = 'Validado Corectamente'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((err) => console.log(err))
            .finally(() => {
               this.listarComprobantes()
            })
      },
      closeObsComprobante() {
         this.dialogObsComprobante = false
         this.comprobanteInfo = {}
         this.$refs.formObservacion.reset()
         this.$refs.formObservacion.resetValidation()
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) == 0 ||
               Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      checkModificarAsesor() {
         if (this.oidcUser !== null) {
            return this.oidcUser.role === 'admin' ||
               this.oidcUser.nivelasesor === '0'
               ? true
               : false
         } else {
            return false
         }
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      },
      completarFormulario() {
         var validate = true
         this.detalles.forEach((el) => {
            if (el.path == null && el.comprobanteId != 17) {
               validate = false
            }
         })
         if (validate) {
            axios
               .put('api/SolicitudDeBeca/completar/' + this.solicitudBecaId, {
                  completar: true
               })
               .then(() => {
                  this.dialogCompletada = true
               })
               .catch(() => {
                  this.snackText = 'Error al actualizar estado de la solicitud'
                  this.snackColor = 'snackError'
                  this.snackbar = true
               })
         } else {
            this.snackText = 'Aun no ha adjuntado todos los comprobantes'
            this.snackColor = 'snackWarning'
            this.snackbar = true
         }
      },
      visualizar(item) {
         window.open(item.path, null)
      },
      descargarPlantillaDeclaracion() {
         window.open(
            'https://drive.google.com/file/d/1wfqshY-V7fd1C5nFz73lf-PvU1OEyXxy/view?usp=sharing',
            '_blank'
         )
      },
      descargarPlantillaReferencia() {
         window.open(
            'https://drive.google.com/file/d/1fzshlPH6JdLZs28k0dAQOYot16cLsCAD/view?usp=sharing',
            '_blank'
         )
      },
      cancelarComprobante() {
         if (this.beneficiarioId == 1 || this.beneficiarioId == 2) {
            this.$emit('cancelGoStep', 3.5)
         } else {
            this.$emit('cancelGoStep', 3)
         }
      },
      reset() {
         this.listarComprobantes()
      },
      listarComboboxes() {
         // Obteniendo laborales
         axios
            .get('api/general/Parentescos')
            .then((response) => {
               this.cmbParentesco = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComprobantes() {
         this.loadingDetalles = true
         // Obteniendo detalles
         axios
            .get(
               `api/solicitudesdebecas/${this.solicitudBecaId}/BienesFamiliares`
            )
            .then((response) => {
               var result = response.data
               this.poseeVehiculo = result.numeroDePlaca == null ? false : true
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               axios
                  .get(
                     `api/solicitudesdebecas/${this.solicitudBecaId}/PathComprobante`
                  )
                  .then((response) => {
                     this.detalles = response.data
                     this.loadingDetalles = false
                  })
                  .catch((error) => {
                     console.log(error)
                  })
                  .finally(() => {
                     if (
                        !this.poseeVehiculo &&
                        this.detalles.find((obj) => obj.comprobanteId == 7) !=
                           undefined
                     ) {
                        const index = this.detalles.findIndex(
                           (obj) => obj.comprobanteId == 7
                        )
                        if (index > -1) {
                           this.detalles.splice(index, 1)
                        }
                     }
                     setTimeout(() => {
                        this.detalles.sort(this.dynamicSort('comprobante'))
                     }, 10)
                  })
            })
      },
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/PathComprobante/' +
                  item.pathComprobanteId
            )
            .then((response) => {
               this.result = response.data
               this.pathComprobanteId = this.result.pathComprobanteId
               this.comprobante = this.result.comprobante
               this.comprobanteId = this.result.comprobanteId
               this.rutaArchivoAdjunto = this.result.comprobantePath
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogEditar = true
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formComprobante.validate()) {
            // Codigo para actualizar/editar
            this.dialogEnviandoComp = true
            let formData = new FormData()
            for (let file of this.archivosAdjuntos) {
               formData.append('files', file, file.name)
            }
            axios
               .put(
                  'api/solicitudesdebecas/' +
                     this.solicitudBecaId +
                     '/PathComprobante/' +
                     this.pathComprobanteId,
                  formData
               )
               .then(() => {
                  this.listarComprobantes()
                  this.close()
                  this.snackText = 'Comprobante adjuntado exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.dialogEnviandoComp = false
               })
               .catch((error) => {
                  console.log(error)
                  this.snackText = 'No se pudo subir el comprobante'
                  this.snackColor = 'snackError'
                  this.snackbar = true
                  this.dialogEnviandoComp = false
               })
         }
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogEditar = false
         this.dialogEditar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formComprobante.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.comprobante = ''
         this.comprobanteId = ''
         this.pathComprobanteId = ''
         this.archivosAdjuntos = []
      },
      showEditar(item) {
         this.comprobante = item.comprobante
         this.pathComprobanteId = item.pathComprobanteId
         this.dialogEditar = true
      },
      closedialogEditar() {
         this.clear()
         this.dialogEditar = false
      },
      editarComprobante() {
         this.archivosAdjuntos = []
         let formData = new FormData()
         for (let file of this.archivosAdjuntos) {
            formData.append('files', file, file.name)
         }
         axios
            .put(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/PathComprobante/' +
                  this.pathComprobanteId,
               formData
            )
            .then(() => {
               this.listarComprobantes()
               this.snackText = 'Comprobante anulado.'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.closedialogEditar()
            })
            .catch((error) => {
               console.log(error)
               this.listarComprobantes()
               this.snackText = 'Comprobante anulado.'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.closedialogEditar()
            })
      }
   }
}
</script>

<style scoped>
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
</style>
