<template>
   <v-layout aling-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG MENSAJE -->
         <v-dialog v-model="dialogMensaje" persistent max-width="400px">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12 mt-2>
                              <div
                                 class="text-justify font-weight-medium"
                                 style="font-size: 18px"
                              >
                                 {{ mensajeDialog }}
                              </div>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogMensaje = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ENVIANDO FORMULARIO -->
         <v-dialog v-model="dialogEnviandoFormulario" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white" class="text-center">
                  {{ mensajeEnviando }}
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <!-- DIALOG UPDATE FECHA CUOTA PLAN PAGO-->
         <v-dialog v-model="dialogUpdateCuotaPlan" persistent max-width="450px">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12
                              ><span class="text-h5"
                                 >Fecha a pagar cuota # {{ cuotaNumero }} -
                                 {{ cicloPlanCuota }}</span
                              ></v-flex
                           >
                           <v-flex xs12>
                              <CustomDatePicker
                                 :min="toISOLocal(new Date()).substr(0, 10)"
                                 :date="fechaPagareCuota"
                                 label="Fecha pagaré"
                                 @updateFecha="fechaPagareCuota = $event"
                              />
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="closePlanCuota"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        @click.native="savePlanCuota"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-container fluid>
            <v-layout
               v-if="!formularioDeshabilitado || checkRangoAlto()"
               wrap
               :style="
                  $vuetify.breakpoint.xlOnly
                     ? 'padding-right: 10%; padding-left: 10%'
                     : ''
               "
            >
               <v-flex v-if="mostrarMensaje" class="mb-8" xs12>
                  <v-card class="borderDetallesPrimary">
                     <v-layout
                        wrap
                        fill-height
                        align-center
                        :class="
                           $vuetify.breakpoint.smAndUp ? 'px-6' : 'px-4 py-4'
                        "
                     >
                        <v-flex xs12 sm1 text-center>
                           <v-icon size="35" color="primary">
                              fa-info-circle
                           </v-icon>
                        </v-flex>
                        <v-flex xs12 sm11 text-justify class="pt-2">
                           <span class="font-weight-bold colorPrimary">
                              AVISO IMPORTANTE:
                           </span>
                           <p
                              class="font-weight-medium"
                              style="margin-bottom: 10px"
                           >
                              {{ mensajePlanPagos }}
                           </p>
                        </v-flex>
                     </v-layout>
                  </v-card>
               </v-flex>
               <v-flex v-if="!formEnviado" xs12>
                  <v-stepper
                     v-model="stepNum"
                     :class="$vuetify.breakpoint.xsOnly ? 'elevation-0' : ''"
                  >
                     <v-stepper-header
                        v-if="
                           !verFormularioEnviado && $vuetify.breakpoint.smAndUp
                        "
                     >
                        <v-stepper-step :complete="stepNum > 1" step="1"
                           >Identificación</v-stepper-step
                        >

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepNum > 2" step="2"
                           >Completar formulario</v-stepper-step
                        >
                     </v-stepper-header>

                     <v-stepper-items
                        :class="$vuetify.breakpoint.smAndUp ? 'px-8' : 'py-2'"
                     >
                        <!-- PASO 1 -->
                        <v-stepper-content
                           step="1"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout v-if="!verFormularioEnviado" wrap>
                              <v-flex xs12>
                                 <span class="font-weight-medium text-h5"
                                    >Formulario de solicitud de plan de pago
                                 </span>
                                 <br />
                                 <br />
                                 <span
                                    class="font-weight-medium"
                                    style="font-size: 20px"
                                 >
                                    Por favor ingresa los siguientes datos para
                                    poder identificarte
                                 </span>
                              </v-flex>
                              <v-flex xs12>
                                 <v-form
                                    ref="formId"
                                    v-model="validId"
                                    lazy-validation
                                 >
                                    <v-card elevation="0">
                                       <v-card-text
                                          class="text--primary ml-0 pl-0"
                                       >
                                          <v-container
                                             class="px-1"
                                             grid-list-md
                                             fluid
                                          >
                                             <v-layout wrap>
                                                <!-- FORM CARNET / NUM TALONARIO -->
                                                <v-flex xs12 sm6>
                                                   <v-layout wrap>
                                                      <v-flex
                                                         xs12
                                                         :class="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                               ? 'mr-8'
                                                               : ''
                                                         "
                                                      >
                                                         <v-text-field
                                                            v-model="carnet"
                                                            v-mask="
                                                               '####-AA-###'
                                                            "
                                                            :readonly="
                                                               talonario != null
                                                            "
                                                            :rules="carnetRules"
                                                            label="Carnet"
                                                            color="primary"
                                                         ></v-text-field>
                                                      </v-flex>
                                                      <v-flex
                                                         xs12
                                                         :class="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                               ? 'mr-8'
                                                               : ''
                                                         "
                                                      >
                                                         <v-text-field
                                                            v-model="
                                                               numTalonario
                                                            "
                                                            :readonly="
                                                               talonario != null
                                                            "
                                                            :rules="
                                                               numTalonarioRules
                                                            "
                                                            :label="
                                                               'Número de talonario ' +
                                                               cicloPasado
                                                            "
                                                            color="primary"
                                                         ></v-text-field>
                                                      </v-flex>
                                                   </v-layout>
                                                </v-flex>
                                                <!-- MENSAJES DE RESPUESTA -->
                                                <v-flex xs12 sm6>
                                                   <v-layout
                                                      align-center
                                                      fill-height
                                                      justify-center
                                                      wrap
                                                   >
                                                      <!-- ICONO VERIFICAR / CHECK -->
                                                      <v-flex
                                                         xs12
                                                         class="text-center"
                                                         :style="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                               ? 'margin-top:-30px'
                                                               : ''
                                                         "
                                                      >
                                                         <v-icon
                                                            color="primary"
                                                            size="90"
                                                            >{{
                                                               iconVerificar
                                                            }}</v-icon
                                                         >
                                                      </v-flex>
                                                      <!-- MENSAJE -->
                                                      <v-flex
                                                         xs12
                                                         class="text-center"
                                                      >
                                                         <!-- PUEDE ENVIAR UN FORMULARIO -->
                                                         <span
                                                            v-if="checkIdOk"
                                                            class="font-weight-medium"
                                                            style="
                                                               font-size: 20px;
                                                            "
                                                         >
                                                            Hola
                                                            <span
                                                               class="primaryColor"
                                                            >
                                                               {{ aluNombre }}
                                                            </span>
                                                            <br />
                                                            Ya puedes hacer clic
                                                            en el botón
                                                            "Continuar"
                                                         </span>
                                                         <!-- NO PUEDE ENVIAR UN FORMULARIO -->
                                                         <div
                                                            v-else
                                                            class="text-h6"
                                                            style="
                                                               white-space: pre-wrap;
                                                            "
                                                         >
                                                            {{
                                                               messageVerificar
                                                            }}
                                                         </div>
                                                         <span
                                                            v-if="
                                                               checkShowEmail
                                                            "
                                                            class="primaryColor font-weight-medium"
                                                            style="
                                                               font-size: 20px;
                                                            "
                                                         >
                                                            {{ correoAdm }}
                                                         </span>
                                                      </v-flex>
                                                      <!-- BOTON VERIFICAR IDENTIDAD -->
                                                      <v-flex xs12 mt-4>
                                                         <v-btn
                                                            v-if="
                                                               !checkOldSolicitud
                                                            "
                                                            :loading="
                                                               loadingVerificar
                                                            "
                                                            :disabled="
                                                               loadingVerificar ||
                                                               !validId ||
                                                               checkIdOk ||
                                                               formsAprobadosPendientes
                                                            "
                                                            rounded
                                                            color="primary"
                                                            width="100%"
                                                            height="60px"
                                                            x-large
                                                            @click="
                                                               verificarIdentidad
                                                            "
                                                            >Verificar
                                                         </v-btn>
                                                         <!-- SI YA ENVIO UN FORMULARIO / VER SOLICITUD -->
                                                         <v-flex
                                                            v-if="
                                                               checkOldSolicitud
                                                            "
                                                            xs12
                                                         >
                                                            <v-btn
                                                               rounded
                                                               color="primary"
                                                               width="100%"
                                                               height="60px"
                                                               x-large
                                                               @click="
                                                                  verFormularioEnviado = true
                                                               "
                                                               >Ver formulario
                                                               enviado
                                                            </v-btn>
                                                         </v-flex>
                                                      </v-flex>
                                                   </v-layout>
                                                </v-flex>
                                             </v-layout>
                                          </v-container>
                                       </v-card-text>
                                    </v-card>
                                 </v-form>
                              </v-flex>
                              <v-flex xs12>
                                 <v-btn
                                    color="primary"
                                    :disabled="!checkIdOk"
                                    @click="revisarMorosidad"
                                 >
                                    Continuar
                                 </v-btn>
                              </v-flex>
                           </v-layout>

                           <!-- MOSTRAR FORMULARIO ENVIADO -->
                           <v-layout v-if="verFormularioEnviado">
                              <v-container grid-list-md fluid>
                                 <v-layout wrap>
                                    <v-flex xs12>
                                       <span class="text-h5 font-weight-medium">
                                          Estado de formulario enviado:
                                          <span class="colorPrimary">
                                             {{ estadoNombre }}
                                          </span>
                                       </span>
                                    </v-flex>
                                    <v-flex xs12 sm3>
                                       <v-autocomplete
                                          v-model="selectEstadoCaso"
                                          :items="cmbEstadoCaso"
                                          color="primary"
                                          item-text="nombre"
                                          item-value="estadoCasoId"
                                          label="Estado"
                                          placeholder="Seleccionar..."
                                          readonly
                                       >
                                       </v-autocomplete>
                                    </v-flex>
                                    <v-flex xs12 sm3>
                                       <v-text-field
                                          v-model="numeroCaso"
                                          label="N° de caso"
                                          color="primary"
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3>
                                       <v-text-field
                                          v-model="carnet"
                                          label="Carnet"
                                          color="primary"
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3>
                                       <v-text-field
                                          v-model="numTalonario"
                                          label="Número de talonario"
                                          color="primary"
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                       <v-layout wrap>
                                          <v-flex xs12 sm6>
                                             <v-text-field
                                                v-model="nombres"
                                                label="Nombres"
                                                color="primary"
                                                readonly
                                             ></v-text-field>
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <v-text-field
                                                v-model="apellidos"
                                                label="Apellidos"
                                                color="primary"
                                                readonly
                                             ></v-text-field>
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <v-autocomplete
                                                v-model="selectCodigoCarrera"
                                                :items="cmbCarreras"
                                                item-text="nombre"
                                                item-value="carreraUniversitariaId"
                                                label="Carrera"
                                                color="primary"
                                                readonly
                                             ></v-autocomplete>
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <CustomDatePicker
                                                :date="fechaSolicitud"
                                                label="Fecha"
                                                readonly
                                                @updateFecha="
                                                   fechaSolicitud = $event
                                                "
                                             />
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <v-text-field
                                                v-model="email"
                                                label="Correo institucional"
                                                prepend-icon="fa-at"
                                                color="primary"
                                                readonly
                                             ></v-text-field>
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <v-text-field
                                                v-model="telefono"
                                                v-mask="'####-####'"
                                                prepend-icon="fab fa-whatsapp"
                                                label="Telefono"
                                                color="primary"
                                                readonly
                                             ></v-text-field>
                                          </v-flex>
                                          <v-flex xs12>
                                             <v-textarea
                                                v-model="comentarioAlumno"
                                                prepend-icon="fa-comment-dots"
                                                label="Justificación"
                                                color="primary"
                                                rows="5"
                                                outlined
                                                counter
                                                readonly
                                             ></v-textarea>
                                          </v-flex>

                                          <!-- MOSTRAR ADJUNTO YA ENVIADO -->
                                          <v-flex
                                             v-if="
                                                rutaArchivoAdjunto.trim() != ''
                                             "
                                             xs12
                                             class="text-subtitle-1"
                                             style="
                                                margin-top: -20px;
                                                margin-bottom: 20px;
                                             "
                                          >
                                             <span
                                                class="text-subtitle-1 font-weight-medium mr-2"
                                                >Archivo adjunto:</span
                                             >
                                             <v-chip color="primary">
                                                {{
                                                   rutaArchivoAdjunto.match(
                                                      /[^\\]*(?=[.][a-zA-Z]+$)/
                                                   ) + '.pdf'
                                                }}</v-chip
                                             >
                                          </v-flex>

                                          <!-- ANEXAR ADJUNTO A FORMULARIO EXISTENTE -->
                                          <v-flex
                                             v-if="
                                                rutaArchivoAdjunto.trim() == ''
                                             "
                                             xs12
                                          >
                                             <p
                                                class="font-weight-medium text-justify"
                                                style="font-size: 18px"
                                             >
                                                Si cuentas con algun documento
                                                que justifique tu caso en
                                                particular, puedes adjuntarlo en
                                                el siguiente campo. <br />
                                                (Solo se permite adjuntar un
                                                archivo PDF y su tamaño no puede
                                                exceder los 15MB)
                                             </p>
                                          </v-flex>
                                          <v-flex
                                             v-if="
                                                rutaArchivoAdjunto.trim() ==
                                                   '' && !anexoEnviado
                                             "
                                             xs12
                                          >
                                             <v-form
                                                ref="formAnexarAdjunto"
                                                v-model="validAnexo"
                                                lazy-validation
                                             >
                                                <v-layout wrap>
                                                   <v-flex xs12 sm10>
                                                      <v-file-input
                                                         v-model="
                                                            archivosAdjuntos
                                                         "
                                                         color="primary"
                                                         :rules="
                                                            anexoAdjuntosRules
                                                         "
                                                         label="Anexar archivo adjunto"
                                                         placeholder="Selecciona tu archivo"
                                                         prepend-icon="fa-paperclip"
                                                         accept=".pdf"
                                                         outlined
                                                         multiple
                                                         counter
                                                         :show-size="1000"
                                                      >
                                                         <template
                                                            #selection="{
                                                               text
                                                            }"
                                                         >
                                                            <v-chip
                                                               color="primary"
                                                               dark
                                                               label
                                                               small
                                                            >
                                                               {{ text }}
                                                            </v-chip>
                                                         </template>
                                                      </v-file-input>
                                                   </v-flex>
                                                   <v-flex xs12 sm2>
                                                      <v-btn
                                                         :disabled="!validAnexo"
                                                         class="mt-2"
                                                         width="100%"
                                                         color="primary"
                                                         @click="enviarAnexo"
                                                         >Subir archivo</v-btn
                                                      >
                                                   </v-flex>
                                                </v-layout>
                                             </v-form>
                                          </v-flex>
                                          <v-flex v-if="anexoEnviado" xs12>
                                             <span
                                                class="font-weight-medium text-justify colorPrimary"
                                                style="font-size: 20px"
                                                >Tu archivo se ha enviado
                                                correctamente 👍!
                                             </span>
                                          </v-flex>
                                          <v-flex
                                             xs12
                                             style="margin-top: -10px"
                                             class="mt-2"
                                          >
                                             <v-card elevation="2">
                                                <v-toolbar color="primary" dark>
                                                   <v-toolbar-title>
                                                      Plan de pago
                                                   </v-toolbar-title>
                                                   <v-spacer></v-spacer>
                                                </v-toolbar>
                                                <v-data-table
                                                   :headers="headersPlanPago"
                                                   :items="planPagoMoras"
                                                   hide-default-footer
                                                   class="elevation-0"
                                                   disable-sort
                                                   :items-per-page="-1"
                                                   :footer-props="{
                                                      itemsPerPageOptions: [-1],
                                                      itemsPerPageText:
                                                         'Filas por página:'
                                                   }"
                                                   ><!-- Si no hay datos, mostrar boton para listar de nuevo -->
                                                   <template slot="no-data">
                                                      <div
                                                         v-if="
                                                            planPagoMoras.length ==
                                                            0
                                                         "
                                                      >
                                                         <v-container>
                                                            <v-layout
                                                               wrap
                                                               align-center
                                                            >
                                                               <v-flex
                                                                  xs12
                                                                  class="text-center"
                                                               >
                                                                  <v-btn
                                                                     color="primary"
                                                                     @click="
                                                                        reset
                                                                     "
                                                                     >No hay
                                                                     Cuotas
                                                                     Pendientes</v-btn
                                                                  >
                                                               </v-flex>
                                                            </v-layout>
                                                         </v-container>
                                                      </div>
                                                   </template>
                                                   <template
                                                      #item.moraExonerada="{
                                                         item
                                                      }"
                                                   >
                                                      $
                                                      {{
                                                         numFormatIntl(
                                                            item.moraExonerada
                                                         )
                                                      }}
                                                   </template>

                                                   <template
                                                      #item.estado="{ item }"
                                                   >
                                                      <v-chip
                                                         :color="
                                                            getColorEstadoPlan(
                                                               item
                                                            )
                                                         "
                                                         dark
                                                      >
                                                         <span>{{
                                                            item.facturado
                                                               ? 'Cancelado'
                                                               : getStatusPlan(
                                                                    item
                                                                 )
                                                         }}</span>
                                                      </v-chip>
                                                   </template>
                                                   <template
                                                      #item.monto="{ item }"
                                                   >
                                                      $
                                                      {{
                                                         numFormatIntl(
                                                            item.monto
                                                         )
                                                      }}
                                                   </template>
                                                   <template
                                                      #body.append="{ headers }"
                                                   >
                                                      <!-- Linea de totales -->
                                                      <tr
                                                         v-if="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                         "
                                                      >
                                                         <td
                                                            colspan="4"
                                                            align="right"
                                                            class="text-body-1 font-weight-medium"
                                                            style="
                                                               padding-top: 4px;
                                                            "
                                                         >
                                                            TOTALES
                                                         </td>
                                                         <td
                                                            class="text-body-1 font-weight-medium"
                                                         >
                                                            <v-text-field
                                                               v-model="
                                                                  totalMoraExoneradaPlan
                                                               "
                                                               readonly
                                                               reverse
                                                               dense
                                                               hide-details
                                                               style="
                                                                  margin-top: 20px;
                                                                  margin-bottom: 10px;
                                                               "
                                                            ></v-text-field>
                                                         </td>
                                                         <td
                                                            class="text-body-1 font-weight-medium"
                                                         >
                                                            <v-text-field
                                                               v-model="
                                                                  totalPlanTabla
                                                               "
                                                               readonly
                                                               reverse
                                                               dense
                                                               hide-details
                                                               style="
                                                                  margin-top: 20px;
                                                                  margin-bottom: 10px;
                                                               "
                                                            ></v-text-field>
                                                         </td>
                                                      </tr>

                                                      <!-- VISTA EN MODO EXTRA SMALL -->
                                                      <tr
                                                         v-if="
                                                            $vuetify.breakpoint
                                                               .xsOnly
                                                         "
                                                      >
                                                         <td
                                                            :colspan="
                                                               headers.length
                                                            "
                                                            class="text-body-1 font-weight-medium"
                                                         >
                                                            <span
                                                               class="text-h6 colorPrimary"
                                                            >
                                                               TOTALES
                                                            </span>
                                                            <v-text-field
                                                               v-model="
                                                                  totalMoraExoneradaPlan
                                                               "
                                                               label="Total recargo exonerado"
                                                               readonly
                                                               reverse
                                                            ></v-text-field>
                                                            <v-text-field
                                                               v-model="
                                                                  totalPlanTabla
                                                               "
                                                               label="Total a pagar"
                                                               readonly
                                                               reverse
                                                            ></v-text-field>
                                                         </td>
                                                      </tr>
                                                   </template>
                                                </v-data-table>
                                             </v-card>
                                          </v-flex>
                                          <v-flex xs12 mt-4>
                                             <v-card elevation="0">
                                                <span
                                                   class="text-subtitle-1 font-weight-medium"
                                                >
                                                   Términos y condiciones
                                                </span>
                                                <p class="text-justify">
                                                   Me doy por notificado de la
                                                   resolución que antecede. De
                                                   no cumplir con las fechas
                                                   establecidas en el plan de
                                                   pago, autorizo a la
                                                   Universidad a que haga
                                                   efectivo el
                                                   <span class="primaryColor">
                                                      retiro de materias
                                                   </span>
                                                   del presente ciclo.
                                                </p>
                                             </v-card>
                                             <v-checkbox
                                                v-model="checkedTrue"
                                                label="Acepto los términos y condiciones"
                                                color="primary"
                                                style="margin-left: 5px"
                                                readonly
                                             ></v-checkbox>
                                          </v-flex>
                                          <v-flex xs12 class="text-right">
                                             <v-btn
                                                color="primary"
                                                @click="sacameDeAqui"
                                             >
                                                Salir
                                             </v-btn>
                                          </v-flex>
                                       </v-layout>
                                    </v-flex>
                                 </v-layout>
                              </v-container>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 2 -->
                        <v-stepper-content
                           step="2"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <!-- SI TIENE MORA MOSTRAR FORMULARIO -->
                           <v-layout v-if="poseeMora" wrap>
                              <v-form
                                 ref="formFormulario"
                                 v-model="validFormulario"
                                 lazy-validation
                              >
                                 <v-container grid-list-md fluid>
                                    <v-layout wrap>
                                       <v-flex xs12>
                                          <span
                                             class="font-weight-medium"
                                             style="font-size: 22px"
                                             >Formulario de plan de pago por
                                             morosidad</span
                                          >
                                          <br />
                                          <span
                                             class="font-weight-medium"
                                             style="font-size: 20px"
                                             >complementa los campos de este
                                             formulario</span
                                          >
                                       </v-flex>
                                       <v-flex xs12 sm3>
                                          <v-text-field
                                             v-model="carnet"
                                             label="Carnet"
                                             color="primary"
                                             readonly
                                          ></v-text-field>
                                       </v-flex>
                                       <v-flex xs12 sm3>
                                          <v-text-field
                                             v-model="numTalonario"
                                             label="Número de talonario"
                                             color="primary"
                                             readonly
                                          ></v-text-field>
                                       </v-flex>
                                       <v-flex xs12>
                                          <v-layout wrap>
                                             <v-flex xs12 sm6>
                                                <v-text-field
                                                   v-model="nombres"
                                                   label="Nombres"
                                                   color="primary"
                                                   readonly
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex xs12 sm6>
                                                <v-text-field
                                                   v-model="apellidos"
                                                   label="Apellidos"
                                                   color="primary"
                                                   readonly
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex xs12 sm6>
                                                <v-autocomplete
                                                   v-model="selectCodigoCarrera"
                                                   :items="cmbCarreras"
                                                   item-text="nombre"
                                                   item-value="codigo"
                                                   label="Carrera"
                                                   color="primary"
                                                   readonly
                                                ></v-autocomplete>
                                             </v-flex>
                                             <v-flex xs12 sm6>
                                                <CustomDatePicker
                                                   :date="fechaSolicitud"
                                                   label="Fecha"
                                                   readonly
                                                   @updateFecha="
                                                      fechaSolicitud = $event
                                                   "
                                                />
                                             </v-flex>
                                             <v-flex xs12>
                                                <p
                                                   class="font-weight-medium text-justify"
                                                   style="font-size: 18px"
                                                >
                                                   Hola
                                                   <span class="primaryColor">
                                                      {{ aluNombre }}</span
                                                   >, uno de nuestros asesores
                                                   se pondrá en contacto contigo
                                                   lo mas pronto posible. 😉<br />
                                                   Por favor ingresa tu
                                                   dirección de correo
                                                   institucional y un número de
                                                   teléfono con WhatsApp con los
                                                   cuales podamos contactarte.
                                                </p>
                                             </v-flex>
                                             <v-flex xs12 sm6>
                                                <v-text-field
                                                   v-model="email"
                                                   :rules="emailRules"
                                                   label="Correo institucional"
                                                   prepend-icon="fa-at"
                                                   color="primary"
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex xs12 sm6>
                                                <v-text-field
                                                   v-model="telefono"
                                                   v-mask="'####-####'"
                                                   :rules="telefonoRules"
                                                   prepend-icon="fab fa-whatsapp"
                                                   label="Telefono"
                                                   color="primary"
                                                ></v-text-field>
                                             </v-flex>
                                             <v-flex xs12 mt-2>
                                                <p
                                                   class="font-weight-medium text-justify"
                                                   style="font-size: 18px"
                                                >
                                                   La Universidad Católica de El
                                                   Salvador está dispuesta a
                                                   ayudarte y buscar la mejor
                                                   solución para que puedas
                                                   continuar con tus estudios
                                                   académicos, escríbenos y
                                                   explícanos tu situación
                                                   particular, uno de nuestros
                                                   asesores con gusto te
                                                   atenderá
                                                </p>
                                             </v-flex>
                                             <v-flex xs12>
                                                <v-textarea
                                                   v-model="comentarioAlumno"
                                                   :rules="comentarioRules"
                                                   prepend-icon="fa-comment-dots"
                                                   label="Justificación"
                                                   color="primary"
                                                   rows="5"
                                                   persistent-hint
                                                   hint="Longitud máxima: 1,500 caracteres"
                                                   outlined
                                                   counter
                                                ></v-textarea>
                                             </v-flex>
                                             <v-flex xs12>
                                                <span
                                                   class="font-weight-medium"
                                                   style="font-size: 20px"
                                                >
                                                   Estado de Cuenta asociado al
                                                   carnet
                                                   <span class="primaryColor">
                                                      {{ carnet }}
                                                   </span>
                                                </span>
                                                <br />
                                                <span
                                                   class="font-weight-medium"
                                                   style="font-size: 20px"
                                                >
                                                   Ingresa las fechas en las que
                                                   consideras pagar las cuotas
                                                </span>
                                             </v-flex>
                                             <!-- DATA TABLE CUOTAS -->
                                             <v-flex xs12>
                                                <v-card elevation="2">
                                                   <v-toolbar
                                                      color="primary"
                                                      dark
                                                   >
                                                      <v-toolbar-title>
                                                         Cuotas pendientes
                                                      </v-toolbar-title>
                                                      <v-spacer></v-spacer>
                                                   </v-toolbar>

                                                   <v-data-table
                                                      v-if="poseeMora"
                                                      disable-sort
                                                      :headers="headersCuotas"
                                                      :items="cuotasConMora"
                                                      hide-default-footer
                                                      :items-per-page="-1"
                                                      :footer-props="{
                                                         itemsPerPageOptions: [
                                                            -1
                                                         ],
                                                         itemsPerPageText:
                                                            'Filas por página:'
                                                      }"
                                                      class="elevation-0"
                                                      ><!-- Si no hay datos, mostrar boton para listar de nuevo -->
                                                      <template slot="no-data">
                                                         <div
                                                            v-if="
                                                               cuotasConMora.length ==
                                                               0
                                                            "
                                                         >
                                                            <v-container>
                                                               <v-layout
                                                                  wrap
                                                                  align-center
                                                               >
                                                               </v-layout>
                                                            </v-container>
                                                         </div>
                                                      </template>

                                                      <template
                                                         #body.append="{
                                                            headers
                                                         }"
                                                      >
                                                         <!-- Linea de totales -->
                                                         <tr
                                                            v-if="
                                                               $vuetify
                                                                  .breakpoint
                                                                  .smAndUp
                                                            "
                                                         >
                                                            <td
                                                               colspan="3"
                                                               align="right"
                                                               class="text-body-1 font-weight-medium"
                                                               style="
                                                                  padding-top: 4px;
                                                               "
                                                            >
                                                               TOTALES
                                                            </td>
                                                            <td
                                                               class="text-body-1 font-weight-medium"
                                                            >
                                                               <v-text-field
                                                                  v-model="
                                                                     totalMoraExoneradaCuotas
                                                                  "
                                                                  readonly
                                                                  reverse
                                                                  dense
                                                                  hide-details
                                                                  style="
                                                                     margin-top: 20px;
                                                                     margin-bottom: 10px;
                                                                  "
                                                               ></v-text-field>
                                                            </td>
                                                            <td
                                                               class="text-body-1 font-weight-medium"
                                                            >
                                                               <v-text-field
                                                                  v-model="
                                                                     totalCuotasMora
                                                                  "
                                                                  readonly
                                                                  reverse
                                                                  dense
                                                                  hide-details
                                                                  style="
                                                                     margin-top: 20px;
                                                                     margin-bottom: 10px;
                                                                  "
                                                               ></v-text-field>
                                                            </td>
                                                            <td></td>
                                                         </tr>

                                                         <!-- VISTA EN MODO EXTRA SMALL -->
                                                         <tr
                                                            v-if="
                                                               $vuetify
                                                                  .breakpoint
                                                                  .xsOnly
                                                            "
                                                         >
                                                            <td
                                                               :colspan="
                                                                  headers.length
                                                               "
                                                               class="text-body-1 font-weight-medium"
                                                            >
                                                               <span
                                                                  class="text-h6 colorPrimary"
                                                               >
                                                                  TOTALES
                                                               </span>
                                                               <v-text-field
                                                                  v-model="
                                                                     totalMoraExoneradaCuotas
                                                                  "
                                                                  label="Total recargo exonerado"
                                                                  readonly
                                                                  reverse
                                                               ></v-text-field>
                                                               <v-text-field
                                                                  v-model="
                                                                     totalCuotasMora
                                                                  "
                                                                  label="Total a pagar"
                                                                  readonly
                                                                  reverse
                                                               ></v-text-field>
                                                            </td>
                                                         </tr>
                                                      </template>
                                                      <template
                                                         #item.monto="{ item }"
                                                      >
                                                         $
                                                         {{
                                                            numFormatIntl(
                                                               item.monto
                                                            )
                                                         }}
                                                      </template>
                                                      <template
                                                         #item.moraExonerada="{
                                                            item
                                                         }"
                                                      >
                                                         $
                                                         {{
                                                            numFormatIntl(
                                                               item.moraExonerada
                                                            )
                                                         }}
                                                      </template>
                                                      <template
                                                         #item.estado="{ item }"
                                                      >
                                                         <v-chip
                                                            :color="
                                                               getColorEstado(
                                                                  item
                                                               )
                                                            "
                                                            dark
                                                         >
                                                            <span>{{
                                                               item.facturado
                                                                  ? 'Cancelado'
                                                                  : getStatus(
                                                                       item
                                                                    )
                                                            }}</span>
                                                         </v-chip>
                                                      </template>
                                                      <template
                                                         #item.monto="{ item }"
                                                      >
                                                         $
                                                         {{
                                                            numFormatIntl(
                                                               item.monto
                                                            )
                                                         }}
                                                      </template>
                                                      <template
                                                         #item.fechaPagare="{
                                                            item
                                                         }"
                                                      >
                                                         {{
                                                            formatDate(
                                                               item.fechaPagare
                                                            )
                                                         }}
                                                      </template>
                                                      <template
                                                         #item.opciones="{
                                                            item
                                                         }"
                                                      >
                                                         <v-btn
                                                            text
                                                            small
                                                            fab
                                                            color="amber darken-3"
                                                            :disabled="
                                                               item.facturado
                                                            "
                                                            @click="
                                                               item.facturado
                                                                  ? false
                                                                  : editPlanPagoCuota(
                                                                       item
                                                                    )
                                                            "
                                                         >
                                                            <v-icon
                                                               >fa-pen</v-icon
                                                            >
                                                         </v-btn>
                                                      </template>
                                                   </v-data-table>
                                                </v-card>
                                             </v-flex>
                                             <v-flex xs12 mt-2>
                                                <p
                                                   class="font-weight-medium text-justify"
                                                   style="font-size: 18px"
                                                >
                                                   Si cuentas con algun
                                                   documento que justifique tu
                                                   caso en particular, puedes
                                                   adjuntarlo en el siguiente
                                                   campo. <br />
                                                   (Solo se permite adjuntar un
                                                   archivo PDF y su tamaño no
                                                   puede exceder los 15MB)
                                                </p>
                                             </v-flex>
                                             <v-flex xs12>
                                                <v-file-input
                                                   v-model="archivosAdjuntos"
                                                   color="primary"
                                                   :rules="adjuntosRules"
                                                   label="Archivos adjuntos"
                                                   placeholder="Selecciona tu archivo"
                                                   prepend-icon="fa-paperclip"
                                                   accept=".pdf"
                                                   outlined
                                                   multiple
                                                   counter
                                                   :show-size="1000"
                                                >
                                                   <template
                                                      #selection="{ text }"
                                                   >
                                                      <v-chip
                                                         color="primary"
                                                         dark
                                                         label
                                                         small
                                                      >
                                                         {{ text }}
                                                      </v-chip>
                                                   </template>
                                                </v-file-input>
                                             </v-flex>
                                             <v-flex xs12>
                                                <v-card elevation="0">
                                                   <span
                                                      class="text-subtitle-1 font-weight-medium"
                                                   >
                                                      Términos y condiciones
                                                   </span>
                                                   <p class="text-justify">
                                                      Me doy por notificado de
                                                      la resolución que
                                                      antecede. De no cumplir
                                                      con las fechas
                                                      establecidas en el plan de
                                                      pago, autorizo a la
                                                      Universidad a que haga
                                                      efectivo el
                                                      <span
                                                         class="primaryColor"
                                                      >
                                                         retiro de materias
                                                      </span>
                                                      del presente ciclo.
                                                   </p>
                                                </v-card>
                                                <v-checkbox
                                                   v-model="acepto"
                                                   label="Acepto los términos y condiciones"
                                                   color="primary"
                                                   style="margin-left: 5px"
                                                   :rules="aceptoRules"
                                                ></v-checkbox>
                                             </v-flex>
                                          </v-layout>
                                       </v-flex>
                                       <v-flex xs12>
                                          <v-btn
                                             color="primary"
                                             :block="$vuetify.breakpoint.xsOnly"
                                             :disabled="!validFormulario"
                                             @click="enviarFormulario"
                                          >
                                             Enviar formulario
                                          </v-btn>

                                          <v-btn
                                             :class="
                                                $vuetify.breakpoint.smAndUp
                                                   ? 'ml-2'
                                                   : 'mt-2'
                                             "
                                             :block="$vuetify.breakpoint.xsOnly"
                                             color="grey lighten-3"
                                             @click="sacameDeAqui"
                                          >
                                             Cancelar
                                          </v-btn>
                                       </v-flex>
                                    </v-layout>
                                 </v-container>
                              </v-form>
                           </v-layout>

                           <!-- SI NO TIENE MORA MOSTRAR MENSAJE -->
                           <v-layout v-if="!poseeMora">
                              <v-flex xs12>
                                 <v-card elevation="0">
                                    <v-card-text
                                       class="text--primary ml-0 pl-0"
                                    >
                                       <v-container
                                          class="px-1"
                                          grid-list-md
                                          fluid
                                       >
                                          <v-layout wrap>
                                             <v-flex
                                                xs12
                                                sm8
                                                :class="
                                                   $vuetify.breakpoint.smAndUp
                                                      ? 'pr-8'
                                                      : ''
                                                "
                                             >
                                                <v-layout
                                                   align-center
                                                   fill-height
                                                >
                                                   <span
                                                      class="font-weight-medium"
                                                      style="font-size: 20px"
                                                      >Buenas noticias
                                                      <span
                                                         class="primaryColor"
                                                      >
                                                         {{ aluNombre }}</span
                                                      >, te encuentras al dia
                                                      con tus pagos!.<br />
                                                      <br />
                                                      No se encontraron cuotas
                                                      con mora relacionadas con
                                                      tu carnet
                                                      <span
                                                         class="primaryColor"
                                                      >
                                                         {{ carnet }}</span
                                                      >. <br />
                                                      <br />
                                                      Puedes regresar a la
                                                      pagina de UNICAES haciendo
                                                      clic en el 👍.
                                                   </span>
                                                </v-layout>
                                             </v-flex>
                                             <v-flex xs12 sm4>
                                                <v-layout
                                                   align-center
                                                   fill-height
                                                   justify-center
                                                   wrap
                                                >
                                                   <v-flex xs12>
                                                      <v-img
                                                         src="/assets/thumbs-up-400.png"
                                                         class="tiltImage my-3"
                                                         contain
                                                         height="145"
                                                         @click="sacameDeAqui"
                                                      ></v-img>
                                                   </v-flex>
                                                </v-layout>
                                             </v-flex>
                                          </v-layout>
                                       </v-container>
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                           </v-layout>
                        </v-stepper-content>
                     </v-stepper-items>
                  </v-stepper>
               </v-flex>
               <v-flex v-if="formEnviado" xs12>
                  <v-container fluid>
                     <v-layout justify-center wrap>
                        <v-flex xs12 style="margin-top: 150px"
                           ><v-img
                              src="/assets/thumbs-up-400.png"
                              class="tiltImage"
                              contain
                              height="145"
                              @click="sacameDeAqui"
                           ></v-img
                        ></v-flex>
                        <v-flex xs12 class="text-center mt-8">
                           <span class="font-weight-medium text-h3">
                              Tu formulario se envió correctamente.
                           </span>
                        </v-flex>
                        <v-flex xs12 class="text-center mt-2">
                           <span
                              class="font-weight-medium"
                              style="font-size: 24px"
                           >
                              Uno de nuestros asesores se pondrá en contacto
                              contigo lo mas pronto posible. <br />
                              Puedes salir de esta pagina haciendo clic en el
                              👍.
                           </span>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-flex>
            </v-layout>
            <v-layout
               v-if="formularioDeshabilitado"
               align-center
               justify-center
               fill-height
               wrap
               :style="
                  $vuetify.breakpoint.xlOnly
                     ? 'padding-right: 10%; padding-left: 10%'
                     : ''
               "
            >
               <v-flex xs12 class="text-center" style="margin-top: 150px">
                  <v-icon color="primary" size="145">fa-bible</v-icon>
               </v-flex>
               <v-flex xs12 class="text-center mt-8">
                  <span
                     class="font-weight-medium"
                     :style="
                        $vuetify.breakpoint.smAndUp
                           ? 'font-size: 40px;'
                           : 'font-size: 28px;'
                     "
                  >
                     El formulario para la solicitud de plan de pago está
                     deshabilitado, solo es posible consultar los formularios ya
                     enviados.
                  </span>
               </v-flex>
               <v-flex xs12 class="text-center mt-2">
                  <span class="font-weight-medium" style="font-size: 24px">
                     Gracias por su comprensión.
                  </span>
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
const CustomDatePicker = () => import('./custom/CustomDatePicker')

export default {
   name: 'FormularioPlanPagos',
   directives: {
      mask
   },
   components: {
      CustomDatePicker
   },
   mixins: [formatters],
   data: (vm) => ({
      mensajePlanPagos: '',
      mostrarMensaje: false,
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      correoAdm: process.env.VUE_APP_CORREO_ADM,
      mensajeEnviando: 'Enviando formulario y subiendo archivos',
      anexoEnviado: false,
      checkedTrue: true,
      formsAprobadosPendientes: false,
      formularioDeshabilitado: false,
      stepNum: 1,
      carnet: '',
      cicloActual: null,
      cicloPasado: null,
      numTalonario: '',
      aluNombre: '',
      talonario: null,
      validId: true,
      checkIdOk: false,
      checkShowEmail: false,
      checkOldSolicitud: false,
      loadingVerificar: false,
      iconVerificar: 'far fa-id-card',
      messageVerificar: 'Has clic aqui para verificar tus datos',
      cuotasConMora: [],
      cuotasConMoraOrig: [],
      poseeMora: true,
      alumnoInfo: [],
      result: null,
      cmbEstadoCaso: [],
      selectEstadoCaso: '',
      cicloPlanCuota: '',
      estadoNombre: '',

      // Variables extras de formulario
      numeroCaso: '',
      nombres: '',
      apellidos: '',
      cmbCarreras: [],
      selectCodigoCarrera: '',
      fechaSolicitud: vm.toISOLocal(new Date()).substr(0, 10),
      email: '',
      telefono: '',
      comentarioAlumno: '',
      archivosAdjuntos: [],
      rutaArchivoAdjunto: '',
      validFormulario: true,
      validAnexo: true,
      codigoCarrera: '',
      formEnviado: false,
      dialogEnviandoFormulario: false,
      dialogUpdateCuotaPlan: false,
      cuotaPlan: [],
      fechaPagareCuota: '',
      cuotaNumero: '',
      fechaInicioAbsolucion: null,
      fechaFinAbsolucion: null,
      cicloExonerado: null,
      dialogMensaje: false,
      mensajeDialog: '',
      formularioIngresadoId: '',
      planPagoMoras: [],
      verFormularioEnviado: false,
      carrerasPlanPagos: [],

      // Headers de data-table de cuotas
      headersCuotas: [
         {
            text: 'N° Cuota',
            value: 'cuota',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Ciclo',
            value: 'periodoLectivo',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Fecha de vencimiento',
            value: 'fechaVencimiento',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Recargo exonerado',
            value: 'moraExonerada',
            align: 'right',
            class: 'titulo'
         },
         {
            text: 'Monto',
            value: 'monto',
            align: 'right',
            class: 'titulo'
         },
         {
            text: 'Estado',
            value: 'estado',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Pagaré / Fecha',
            value: 'fechaPagare',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'center',
            class: 'titulo'
         }
      ],
      acepto: false,
      headersPlanPago: [
         {
            text: 'N° Cuota',
            value: 'cuotaNumero',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Ciclo',
            value: 'periodoLectivo',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Fecha Vencimiento',
            value: 'fechaVencimiento',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Pagaré / Fecha',
            value: 'fechaPago',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Recargo exonerado',
            value: 'moraExonerada',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Monto',
            value: 'monto',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Estado',
            value: 'estado',
            align: 'center',
            class: 'titulo'
         }
      ],

      // Reglas de validacion
      carnetRules: [
         (v) => !!v || 'Ingresa tu carnet',
         (v) => (v && v.length == 11) || 'Termina de escribir tu carnet'
      ],
      numTalonarioRules: [
         (v) => !!v || 'Ingresa el número de talonario del ciclo solicitado',
         (v) => v === '' || /^(\d*)$/.test(v) || 'Formato incorrecto'
      ],
      telefonoRules: [
         (v) => !!v || 'Ingresa un número de teléfono',
         (v) =>
            (v && v.length == 9) || 'Termina de escribir tu número de teléfono'
      ],
      emailRules: [
         (v) => !!v || 'Ingrese su correo institucional',
         (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
               v
            ) ||
            'Formato de correo incorrecto',
         (v) =>
            v === '' ||
            /@catolica.edu.sv$/.test(v) ||
            'El correo debe terminar en @catolica.edu.sv'
      ],
      comentarioRules: [
         (v) => !!v || 'Ingresa una justificación',
         (v) =>
            (v && v.length <= 1500) ||
            'Reduce la justificación a: 1,500 caracteres'
      ],
      aceptoRules: [
         (v) =>
            !!v || 'Debes aceptar los términos y condiciones del Plan de Pago'
      ],
      adjuntosRules: [
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'application/pdf') ||
            'Solo se permiten archivos PDF',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ],
      anexoAdjuntosRules: [
         (files) => files.length > 0 || 'Selecciona un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'application/pdf') ||
            'Solo se permiten archivos PDF',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ]
   }),
   computed: {
      ...authComputed,
      // Calculo de totales de datatable plan de pago
      totalPlanTabla() {
         var res = 0
         for (let i = 0; i < this.planPagoMoras.length; i++) {
            const element = this.planPagoMoras[i]
            res = Number(res) + Number(element.monto)
         }
         return '$ ' + this.numFormatIntl(res)
      },
      totalCuotasMora() {
         var res = 0
         for (let i = 0; i < this.cuotasConMora.length; i++) {
            const element = this.cuotasConMora[i]
            res = Number(res) + Number(element.monto)
         }
         return '$ ' + this.numFormatIntl(res)
      },
      totalMoraExoneradaCuotas() {
         var res = 0
         for (let i = 0; i < this.cuotasConMora.length; i++) {
            const element = this.cuotasConMora[i]
            res = Number(res) + Number(element.moraExonerada)
         }
         return '$ ' + this.numFormatIntl(res)
      },
      totalMoraExoneradaPlan() {
         var res = 0
         for (let i = 0; i < this.planPagoMoras.length; i++) {
            const element = this.planPagoMoras[i]
            res = Number(res) + Number(element.moraExonerada)
         }
         return '$ ' + this.numFormatIntl(res)
      }
   },
   watch: {
      cuotasConMora(val) {
         if (val.length > 0) {
            // Calcular mora exonerada
            val.forEach((el) => {
               if (
                  (this.parseDate(el.fechaVencimiento) >=
                     this.fechaInicioAbsolucion &&
                     this.parseDate(el.fechaVencimiento) <=
                        this.fechaFinAbsolucion) ||
                  el.periodoLectivo == this.cicloExonerado
               ) {
                  el.moraExonerada = 12
               } else {
                  el.moraExonerada = 0
               }
            })
         }
      },
      planPagoMoras(val) {
         if (val.length > 0) {
            // Calcular mora exonerada y revisar cuotas ya pagadas
            val.forEach((el) => {
               // Revisando exoneración
               if (
                  (this.parseDate(el.fechaVencimiento) >=
                     this.fechaInicioAbsolucion &&
                     this.parseDate(el.fechaVencimiento) <=
                        this.fechaFinAbsolucion) ||
                  el.periodoLectivo == this.cicloExonerado
               ) {
                  el.moraExonerada = 12
               } else {
                  el.moraExonerada = 0
               }

               // Revisando si ya cancelo la cuota
               axios
                  .get('api/Pago?Npe=' + el.npe + '&ResponseCode=00')
                  .then((response) => {
                     if (response.data.length > 0) {
                        var res = response.data[0]
                        // Ya se registro un pago autorizado
                        el.facturado = true
                        el.fechaPago = res.fecha
                     }
                  })
                  .catch(() => {
                     //console.log(error)
                  })
            })
         }
      }
   },
   created() {
      this.listarComboBoxes()
   },
   methods: {
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      enviarAnexo() {
         this.anexoEnviado = false
         if (this.$refs.formAnexarAdjunto.validate()) {
            this.mensajeEnviando = 'Subiendo archivo adjunto'
            this.dialogEnviandoFormulario = true
            let formData = new FormData()
            for (let file of this.archivosAdjuntos) {
               formData.append('files', file, file.name)
            }
            axios
               .put('api/my/formularioplanPago/' + this.numeroCaso, formData)
               .then((response) => {
                  this.rutaArchivoAdjunto = response.data.rutaArchivoAdjunto
                  this.dialogEnviandoFormulario = false
                  this.anexoEnviado = true
               })
               .catch(() => {
                  this.dialogEnviandoFormulario = false
               })
         }
      },
      revisarFechas() {
         return this.cuotasConMora.some((el) => el.fechaPagare === null)
      },
      closePlanCuota() {
         this.dialogUpdateCuotaPlan = false
         this.cuotaPlan = []
         this.cuotaNumero = ''
         this.fechaPagareCuota
      },
      savePlanCuota() {
         this.cuotasConMora.forEach((element, index) => {
            if (
               element.cuota == this.cuotaNumero &&
               element.periodoLectivo == this.cicloPlanCuota
            ) {
               // Si pone una fecha de pago mayor a la fecha de vencimiento y la fecha de
               // vencimiento de la cuota esta fuera de el rango de absolucion o su ciclo
               // no esta exnoerado y aun no no se le ha cargado 12 dolares, se le suman 12 dolares
               if (
                  this.fechaPagareCuota >
                     this.parseDate(element.fechaVencimiento) &&
                  (this.parseDate(element.fechaVencimiento) <
                     this.fechaInicioAbsolucion ||
                     this.parseDate(element.fechaVencimiento) >
                        this.fechaFinAbsolucion) &&
                  element.monto == this.cuotasConMoraOrig[index].monto &&
                  element.periodoLectivo != this.cicloExonerado
               ) {
                  element.monto = this.toFixedRegex(
                     Number(element.monto) + 12,
                     2
                  )
                  this.mensajeDialog =
                     'La fecha de pago es mayor a la fecha de vencimiento, recargo por PAGO EXTEMPORANEO aplicado'
                  this.dialogMensaje = true
               }

               // Si pone una fecha de pago menor de la fecha de vencimiento y ya tiene
               // aplicado 12 dolares, se le restan 12 dolares
               if (
                  this.fechaPagareCuota <=
                     this.parseDate(element.fechaVencimiento) &&
                  element.monto != this.cuotasConMoraOrig[index].monto &&
                  element.periodoLectivo != this.cicloExonerado
               ) {
                  element.monto = this.toFixedRegex(
                     Number(element.monto) - 12,
                     2
                  )

                  this.mensajeDialog =
                     'Recargo por PAGO EXTEMPORANEO desaplicado'
                  this.dialogMensaje = true
               }

               // Cambiar fecha
               element.fechaPagare = this.fechaPagareCuota
            }
         })
         this.closePlanCuota()
      },
      editPlanPagoCuota(item) {
         this.cuotaPlan = item
         this.cicloPlanCuota = this.cuotaPlan.periodoLectivo
         this.cuotaNumero = this.cuotaPlan.cuota
         this.fechaPagareCuota = this.cuotaPlan.fechaPagare
         this.dialogUpdateCuotaPlan = true
      },
      listarComboBoxes() {
         axios
            .get('api/general/CarreraUniversitaria')
            .then((response) => {
               this.cmbCarreras = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/EstadoCaso?parabecas=false')
            .then((response) => {
               this.cmbEstadoCaso = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/fechaInicioAbs')
            .then((response) => {
               this.fechaInicioAbsolucion = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/fechaFinAbs')
            .then((response) => {
               this.fechaFinAbsolucion = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/carrerasPlanPagos')
            .then((response) => {
               this.carrerasPlanPagos = response.data.valor.split(',')
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/cicloActual')
            .then((response) => {
               this.cicloActual = response.data.valor
               var cicloSplit = this.cicloActual.split('-')
               if (cicloSplit[0] == 'I') {
                  this.cicloPasado = 'II-' + (parseInt(cicloSplit[1]) - 1)
               } else {
                  this.cicloPasado = 'I-' + cicloSplit[1]
               }
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/cicloExonerado')
            .then((response) => {
               this.cicloExonerado = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         // Mensaje desde DB
         axios
            .get('api/Configuracion/mensajePlanPagos')
            .then((response) => {
               if (
                  response.data.valor.trim() != '' &&
                  response.data.valor != null
               ) {
                  this.mensajePlanPagos = response.data.valor
                  this.mostrarMensaje = true
               } else {
                  this.mostrarMensaje = false
                  this.mensajePlanPagos = ''
               }
            })
            .catch(() => {
               //console.log(error)
            })
      },
      verificarIdentidad() {
         if (this.$refs.formId.validate()) {
            this.formsAprobadosPendientes = false
            this.result = null
            this.checkShowEmail = false
            this.loadingVerificar = true
            this.messageVerificar = 'Verificando...'
            this.talonario = null

            // Obteniendo informacion de alumno
            axios
               .get('api/FormularioPlanPago/AlumnoInfo?carnet=' + this.carnet)
               .then((response) => {
                  var aluInfo = response.data[0]
                  var codCarrera = aluInfo.carreraCodigo
                  if (codCarrera.toString().trim().length == 4) {
                     codCarrera = codCarrera.toString().trim().substr(1, 3)
                  }
                  if (
                     !this.carrerasPlanPagos.includes(
                        codCarrera.toString().trim()
                     ) &&
                     this.carrerasPlanPagos.length > 0
                  ) {
                     // Informacion incorrecta
                     this.iconVerificar = 'fa-times'
                     this.checkIdOk = false
                     this.checkShowEmail = true
                     this.loadingVerificar = false
                     this.messageVerificar =
                        'Los formularios de planes de pago aún no se encuentran habilitados para su carrera. Para más consultas puedes escribirnos al correo:'
                  } else {
                     // Revisando codigo de talonario de ciclo anterior
                     axios
                        .get(
                           'api/Contrato?Carnet=' +
                              this.carnet +
                              '&Ciclo=' +
                              this.cicloPasado +
                              '&CodigoTalonario=' +
                              this.numTalonario
                        )
                        .then((response) => {
                           this.talonario = response.data[0]
                           this.aluNombre = this.titleCase(
                              this.talonario.nombre.split(' ')[0],
                              1
                           )
                        })
                        .catch(() => {
                           //console.log(error)
                        })
                        .finally(() => {
                           setTimeout(() => {
                              this.loadingVerificar = false
                              if (this.talonario) {
                                 // Informacion correcta
                                 var matriculaActual = null
                                 // Verificar que cuente con un talonario valido para el ciclo actual
                                 axios
                                    .get(
                                       'api/Contrato/Cuotas?Carnet=' +
                                          this.carnet +
                                          '&&PeriodoLectivo=' +
                                          this.cicloActual
                                    )
                                    .then((response) => {
                                       if (response.data.length > 0) {
                                          matriculaActual = response.data[0].npe
                                       }
                                    })
                                    .finally(() => {
                                       if (matriculaActual) {
                                          // Verificar que no haya enviado ya un formulario
                                          axios
                                             .get(
                                                'api/FormularioPlanPago?Carnet=' +
                                                   this.carnet
                                             )
                                             .then((response) => {
                                                // Analizando formularios del alumno
                                                response.data.forEach((f) => {
                                                   if (
                                                      f.estadoCasoId == 3 &&
                                                      f.progreso !=
                                                         'Completado' &&
                                                      f.cicloAplicacion !=
                                                         this.cicloActual
                                                   ) {
                                                      this.formsAprobadosPendientes = true
                                                   }

                                                   // Si ya ha enviado un formulario de plan de pago para el ciclo actual
                                                   if (
                                                      f.cicloAplicacion ==
                                                      this.cicloActual
                                                   ) {
                                                      this.result = f
                                                   }
                                                })
                                             })
                                             .catch(() => {
                                                //console.log(error)
                                             })
                                             .finally(() => {
                                                this.planPagoMoras = []
                                                if (
                                                   this
                                                      .formsAprobadosPendientes ==
                                                      false &&
                                                   this.result != null
                                                ) {
                                                   // Ya tiene un formulario, cargar variables
                                                   this.numeroCaso =
                                                      this.result.numeroCaso
                                                   this.nombres =
                                                      this.result.nombres
                                                   this.apellidos =
                                                      this.result.apellidos
                                                   this.selectCodigoCarrera =
                                                      this.result.carreraUniversitariaId
                                                   this.fechaSolicitud =
                                                      this.parseDate(
                                                         this.result
                                                            .fechaSolicitud
                                                      )
                                                   this.email =
                                                      this.result.email
                                                   this.telefono =
                                                      this.result.telefono
                                                   this.comentarioAlumno =
                                                      this.result.comentarioAlumno
                                                   this.rutaArchivoAdjunto =
                                                      this.result.rutaArchivoAdjunto
                                                   this.selectEstadoCaso =
                                                      this.result.estadoCasoId
                                                   this.estadoNombre =
                                                      this.result.estadoCaso

                                                   // Traer detalles de plan de pago
                                                   axios
                                                      .get(
                                                         'api/FormularioPlanPago/' +
                                                            this.numeroCaso +
                                                            '/DetallePlanPago'
                                                      )
                                                      .then((response) => {
                                                         var res = response.data
                                                         for (
                                                            let i = 0;
                                                            i < res.length;
                                                            i++
                                                         ) {
                                                            const el = res[i]
                                                            this.planPagoMoras.push(
                                                               el
                                                            )
                                                            //console.log(el)
                                                            if (
                                                               el.cuotaNumero ==
                                                                  1 &&
                                                               el.periodoLectivo ==
                                                                  this
                                                                     .cicloActual
                                                            ) {
                                                               break
                                                            }
                                                         }
                                                      })
                                                      .catch(() => {
                                                         //console.log(error)
                                                      })

                                                   // Mostrar boton para  ver formulario
                                                   this.iconVerificar =
                                                      'fa-search'
                                                   this.checkIdOk = false
                                                   this.checkOldSolicitud = true
                                                   this.messageVerificar =
                                                      'Ya enviaste un formulario.\nPuedes ver el estado de tu formulario haciendo clic aqui.'
                                                } else if (
                                                   this
                                                      .formsAprobadosPendientes ==
                                                      false &&
                                                   this.result == null
                                                ) {
                                                   // Aun no ha enviado un formulario para el ciclo actual
                                                   // Habilitar opcion para enviar nuevo formulario
                                                   axios
                                                      .get(
                                                         'api/Configuracion/formPlanPagos'
                                                      )
                                                      .then((response) => {
                                                         //console.log(response)
                                                         if (
                                                            response.data
                                                               .valor ==
                                                               'true' ||
                                                            this.checkRangoAlto()
                                                         ) {
                                                            this.checkOldSolicitud = false
                                                            this.iconVerificar =
                                                               'fa-check'
                                                            this.checkIdOk = true
                                                         } else {
                                                            this.formularioDeshabilitado = true
                                                         }
                                                      })
                                                      .catch((error) => {
                                                         console.log(error)
                                                      })
                                                } else if (
                                                   this
                                                      .formsAprobadosPendientes ==
                                                   true
                                                ) {
                                                   // Ya posee un plan de pago antiguo aprobado y aun no lo ha completado
                                                   this.messageVerificar =
                                                      'Usted ya cuenta con un plan de pago aprobado para un ciclo anterior, el cual aún no se ha completado. Si desea aplicar por un nuevo plan de pago, por favor envíe un correo explicando su caso a la siguiente dirección:'
                                                   this.iconVerificar = ''
                                                   this.checkIdOk = false
                                                   this.checkShowEmail = true
                                                }
                                             })
                                       } else {
                                          // Informacion incorrecta
                                          this.iconVerificar = 'fa-times'
                                          this.checkIdOk = false
                                          this.checkShowEmail = true
                                          this.messageVerificar =
                                             'Su talonario para el ciclo actual aún no esta disponible.'
                                       }
                                    })
                              } else {
                                 // Informacion incorrecta
                                 this.iconVerificar = 'fa-times'
                                 this.checkIdOk = false
                                 this.checkShowEmail = true
                                 this.messageVerificar =
                                    'Tu carnet o tu talonario estan incorrectos.\nSi necesitas ayuda puedes escribirnos a'
                              }
                           }, 2000)
                        })
                  }
               })
         }
      },
      revisarMorosidad() {
         // Consulta para revision de morosidad
         axios
            .get(
               'api/Contrato/Cuotas?Carnet=' + this.carnet + '&Facturado=false'
            )
            .then((response) => {
               this.cuotasConMora = []
               this.cuotasConMoraOrig = []

               var res = response.data
               for (let i = 0; i < res.length; i++) {
                  const el = res[i]
                  this.cuotasConMora.push(el)
                  //console.log(el)
                  if (el.cuota == 1 && el.periodoLectivo == this.cicloActual) {
                     break
                  }
               }
               this.cuotasConMoraOrig = JSON.parse(
                  JSON.stringify(this.cuotasConMora)
               )
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               if (this.cuotasConMora.length > 0) {
                  // Avanzar al step 2
                  this.poseeMora = true

                  // Agregar propiedad fechaPagare a arreglo de mora
                  this.cuotasConMora.forEach((element) => {
                     // Agregando fechaPagare
                     element.fechaPagare = null
                  })
                  this.mostrarFormulario()
               } else {
                  // Avanzar al step 2
                  this.poseeMora = false
                  this.stepNum = 2
               }
            })
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      },
      mostrarFormulario() {
         axios
            .get('api/FormularioPlanPago/AlumnoInfo?carnet=' + this.carnet)
            .then((response) => {
               this.alumnoInfo = response.data[0]
               this.nombres =
                  this.alumnoInfo.primerNombre +
                  ' ' +
                  this.alumnoInfo.segundoNombre
               this.apellidos =
                  this.alumnoInfo.primerApellido +
                  ' ' +
                  this.alumnoInfo.segundoApellido +
                  '' +
                  this.alumnoInfo.tercerApellido
               this.codigoCarrera = this.alumnoInfo.carreraCodigo
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               if (this.codigoCarrera.toString().trim().length == 4) {
                  this.selectCodigoCarrera = this.codigoCarrera
                     .toString()
                     .trim()
                     .substr(1, 3)
               } else {
                  this.selectCodigoCarrera = this.codigoCarrera
                     .toString()
                     .trim()
               }
               //console.log(me.selectCodigoCarrera)
               this.nombres.trim().replace(/  +/g, ' ')
               this.apellidos.trim().replace(/  +/g, ' ')
               this.formEnviado = false
               this.stepNum = 2
            })
      },
      getStatus(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
            this.parseDate(item.fechaVencimiento)
         ) {
            return 'En mora'
         } else {
            return 'Pendiente'
         }
      },
      getStatusPlan(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
            this.parseDate(item.fechaPago)
         ) {
            return 'En mora'
         } else {
            return 'Pendiente'
         }
      },
      getColorEstado(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
               this.parseDate(item.fechaVencimiento) &&
            !item.facturado
         ) {
            return 'red'
         } else {
            if (item.facturado) {
               return 'green'
            } else {
               return 'blue'
            }
         }
      },
      getColorEstadoPlan(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
               this.parseDate(item.fechaPago) &&
            !item.facturado
         ) {
            return 'red'
         } else {
            if (item.facturado) {
               return 'green'
            } else {
               return 'blue'
            }
         }
      },
      enviarFormulario() {
         // Preparando arreglo de detalle plan pago
         var detallePlanCuotas = this.cuotasConMora.map(function (item) {
            return {
               npe: item['npe'],
               montoPago: item['monto'],
               fechaPago: item['fechaPagare']
            }
         })

         if (this.revisarFechas()) {
            this.mensajeDialog =
               'Por favor, completa las fechas del plan de pago.'
            this.dialogMensaje = true
         } else {
            if (this.$refs.formFormulario.validate()) {
               this.mensajeEnviando = 'Enviando formulario y subiendo archivos'
               this.dialogEnviandoFormulario = true
               var carreraId = this.cmbCarreras.find((obj) => {
                  return obj.codigo === this.selectCodigoCarrera
               }).carreraUniversitariaId
               let formData = new FormData()
               for (let file of this.archivosAdjuntos) {
                  formData.append('files', file, file.name)
               }

               formData.append(
                  'model',
                  JSON.stringify({
                     carnet: this.carnet,
                     numeroTalonario: this.numTalonario,
                     nombres: this.nombres,
                     apellidos: this.apellidos,
                     carreraUniversitariaId: carreraId,
                     fechaSolicitud: this.fechaSolicitud,
                     email: this.email,
                     telefono: this.telefono,
                     asesorId: 'ab84be9d-eee9-4d25-8764-1742d1d44c62',
                     rutaArchivoAdjunto: '',
                     comentarioAsesor: '',
                     comentarioAlumno: this.comentarioAlumno,
                     estadoCasoId: 1,
                     tipoCasoId: 1
                  })
               )
               axios
                  .post('api/FormularioPlanPago', formData)
                  .then((response) => {
                     //console.log(response.data)
                     this.formularioIngresadoId = response.data.numeroCaso
                     // Enviar detalle plan pago
                     axios
                        .post(
                           'api/FormularioPlanPago/' +
                              this.formularioIngresadoId +
                              '/DetallePlanPago',
                           detallePlanCuotas
                        )
                        .then(() => {
                           this.dialogEnviandoFormulario = false
                           this.formEnviado = true
                        })
                        .catch(() => {
                           //console.log(error)
                           this.dialogEnviandoFormulario = false
                        })
                  })
                  .catch(() => {
                     //console.log({ error })
                     this.dialogEnviandoFormulario = false
                     this.showSnack(
                        'No se pudo subir tu formulario de plan de pago',
                        'snackError'
                     )
                  })
            }
         }
      }
   }
}
</script>

<style scoped>
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
.primaryColor {
   color: var(--v-primary-base) !important;
}
</style>
