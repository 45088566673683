<template>
   <v-text-field
      :label="label"
      :outlined="outlined"
      :rules="rules"
      :value="value"
      hide-details="auto"
      style="text-align: center"
      readonly
      @change="$emit('input', $event)"
   >
      <v-btn
         slot="prepend"
         large
         icon
         :disabled="readonly"
         color="primary"
         style="margin-top: -10px"
         @click.stop="decrease"
      >
         <v-icon> fa-minus </v-icon>
      </v-btn>

      <v-btn
         slot="append-outer"
         large
         :disabled="readonly"
         icon
         color="primary"
         style="margin-top: -10px"
         @click.stop="increase"
      >
         <v-icon> fa-plus </v-icon>
      </v-btn>
   </v-text-field>
</template>

<script>
export default {
   name: 'VInputNumber',
   props: {
      label: {
         type: String,
         default: ''
      },
      min: {
         type: Number,
         default: 0
      },
      max: {
         type: Number,
         default: 9999
      },
      outlined: {
         type: Boolean,
         default: true
      },
      rules: {
         type: Array,
         default: () => []
      },
      step: {
         type: Number,
         default: 1
      },
      readonly: {
         type: Boolean,
         default: false
      },
      value: {
         type: Number,
         default: 0
      }
   },
   computed: {},
   methods: {
      increase() {
         if (isNaN(parseInt(this.value))) return this.$emit('input', this.step)
         if (this.value === this.max) return
         this.$emit('input', parseInt(this.value) + this.step)
      },

      decrease() {
         if (isNaN(parseInt(this.value))) return this.$emit('input', this.min)
         if (this.value === this.min) return
         this.$emit('input', parseInt(this.value) - this.step)
      }
   }
}
</script>
