/* eslint-disable no-unused-vars */
import Vue from 'vue'
import vuetify from './plugins/vuetify' // path to vuetify export
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

/**************************************************/
/*        Juan Haroldo Linares Martinez           */
/*        Kevin Rodrigo Aguilar Cruz              */
/*        Diego Fernando Henriquez Hernandez      */
/*        Carlos Martin Pineda Fuentes            */
/*                ©PROYCON 2020                   */
/**************************************************/

axios.defaults.baseURL = process.env.VUE_APP_API

// Agregando token de acceso para las peticiones
axios.interceptors.request.use(
   (config) => {
      const authToken = store.state.oidcStore.access_token
      if (authToken) {
         config.headers.Authorization = `Bearer ${authToken}`
      }
      return config
   },
   (err) => {
      //What do you want to do when a call fails?
      console.log('Request a API fallido.')
   }
)

Vue.config.productionTip = false

new Vue({
   vuetify,
   router,
   store,
   render: (h) => h(App)
}).$mount('#app')
