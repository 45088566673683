export default {
   methods: {
      // ----------------- FORMATEO DE FECHAS ------------------------
      /* Funciones para dar formato a las fechas */

      // Calcular edad en base a fecha de nacimiento
      getAge(YYYYMMDD) {
         var today = new Date()
         var birthDate = new Date(YYYYMMDD)
         var age = today.getFullYear() - birthDate.getFullYear()
         var m = today.getMonth() - birthDate.getMonth()
         console.log(today.getDate())
         console.log(birthDate.getDate())
         if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
            age--
         }
         return age
      },
      // Convierte yyyy-MM-dd a dd/MM/yyyy
      formatDate(date) {
         if (!date) return null
         const [year, month, day] = date.split('-')
         return `${day}/${month}/${year}`
      },
      // Convierte dd/MM/yyyy a yyyy-MM-dd
      parseDate(date) {
         if (!date) return null
         const [day, month, year] = date.split('/')
         return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      // Convierte yyyy-MM-dd a MM/dd/yyyy
      formatDateMonthFirst(date) {
         if (!date) return null
         const [year, month, day] = date.split('-')
         return `${month}/${day}/${year}`
      },
      // Convierte yyyy-MM-dd a MM-dd-yyyy
      parseDateMonthFirst(date) {
         if (!date) return null
         const [year, month, day] = date.split('-')
         return `${month}-${day}-${year}`
      },
      // Convierte yyyy-MM a yyyy/MM
      formatYearMonth(date) {
         if (!date) return null
         const [year, month] = date.split('-')
         return `${year}/${month}`
      },
      // Convierte yyyy/MM a yyyy-MM
      parseYearMonth(date) {
         if (!date) return null
         const [year, month] = date.split('/')
         return `${year}-${month.padStart(2, '0')}`
      },
      // Retorna la fecha en formato ISO,con zona horaria local
      /* La funcion principal toISOString()  retorna la fecha en UTC */
      toISOLocal(d) {
         var z = (n) => (n < 10 ? '0' : '') + n
         var off = d.getTimezoneOffset()
         var sign = off < 0 ? '+' : '-'
         off = Math.abs(off)

         return (
            d.getFullYear() +
            '-' +
            z(d.getMonth() + 1) +
            '-' +
            z(d.getDate()) +
            'T' +
            z(d.getHours()) +
            ':' +
            z(d.getMinutes()) +
            ':' +
            z(d.getSeconds()) +
            sign +
            z((off / 60) | 0) +
            z(off % 60)
         )
      },
      // Retorna el nombre del mes de la fecha proporcionada
      obtenerMes(fecha) {
         var meses = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
         ]
         if (fecha != '') {
            return meses[Number(fecha.substr(5, 2) - 1)]
         }
      },
      obtenerMesFromNumber(num) {
         var meses = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
         ]
         if (num != 0) {
            return meses[num - 1]
         }
      },
      // ----------------- FORMATEO DE HORA ---------------------------
      /* Funciones para dar formato a strings de tiempo/hora */

      // Convertir formato 24 horas a 12 horas
      t12Convert(time, sec = false) {
         // Check correct time format and split into components
         time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

         if (time.length > 1) {
            // If time format correct
            time = time.slice(1) // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
            time[0] = +time[0] % 12 || 12 // Adjust hours
         }

         // Removing seconds
         if (time[3] != null && sec == false) {
            time.splice(3, 1)
         }

         // Adding hour leading zeros
         time[0] = this.padNum(time[0], 2)

         return time.join('') // return adjusted time or original string
      },

      // Sumar dos cantidades de tiempo, horas acumulativas, minutos y segundos base 60
      /**
       * Add two string time values (HH:mm:ss) with javascript
       *
       * Usage:
       *  > addTimes('04:20:10', '21:15:10');
       *  > "25:35:20"
       *  > addTimes('04:35:10', '21:35:10');
       *  > "26:10:20"
       *  > addTimes('30:59', '17:10');
       *  > "48:09:00"
       *  > addTimes('19:30:00', '00:30:00');
       *  > "20:00:00"
       */
      addTimes(startTime, endTime) {
         var times = [0, 0, 0]
         var max = times.length

         var a = (startTime || '').split(':')
         var b = (endTime || '').split(':')

         // normalize time values
         for (var i = 0; i < max; i++) {
            a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
            b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
         }

         // store time values
         // eslint-disable-next-line no-redeclare
         for (var i = 0; i < max; i++) {
            times[i] = a[i] + b[i]
         }

         var hours = times[0]
         var minutes = times[1]
         var seconds = times[2]

         if (seconds >= 60) {
            var m = (seconds / 60) << 0
            minutes += m
            seconds -= 60 * m
         }

         if (minutes >= 50) {
            var h = (minutes / 50) << 0
            hours += h
            minutes -= 50 * h
         }

         return (
            (hours.toString().length < 2 ? '0' + hours : hours) +
            ':' +
            ('0' + minutes).slice(-2) +
            ':' +
            ('0' + seconds).slice(-2)
         )
      },
      obtenerStrCiclo() {
         var today = this.toISOLocal(new Date()).substr(0, 10)
         return (
            (today <= today.substr(0, 4) + '-07-31' ? 'I-' : 'II-') +
            today.substr(0, 4)
         )
      },

      // ----------------- FORMATEO DE NUMEROS ------------------------
      /* Funciones para dar formato a numeros */

      // Formatear numbero con zeros a la izquierda
      padNum(num, size) {
         var s = num + ''
         while (s.length < size) s = '0' + s
         return s
      },
      padString(pad, str, padLeft) {
         if (typeof str === 'undefined') return pad
         if (padLeft) {
            return (pad + str).slice(-pad.length)
         } else {
            return (str + pad).substring(0, pad.length)
         }
      },
      // Agregar formato internacional a un numero Ej. 123,456.78
      numFormatIntl(num, decimals = 2) {
         return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: decimals
         }).format(this.toFixedRegex(num, decimals))
      },
      // Convertir numero entero en romano
      intToRoman(num) {
         if (typeof num !== 'number') return false

         var digits = String(+num).split(''),
            key = [
               '',
               'C',
               'CC',
               'CCC',
               'CD',
               'D',
               'DC',
               'DCC',
               'DCCC',
               'CM',
               '',
               'X',
               'XX',
               'XXX',
               'XL',
               'L',
               'LX',
               'LXX',
               'LXXX',
               'XC',
               '',
               'I',
               'II',
               'III',
               'IV',
               'V',
               'VI',
               'VII',
               'VIII',
               'IX'
            ],
            roman_num = '',
            i = 3
         while (i--) roman_num = (key[+digits.pop() + i * 10] || '') + roman_num
         return Array(+digits.join('') + 1).join('M') + roman_num
      },
      // Cantidad de decimales sin redondear
      toFixedRegex(num, fixed) {
         if (this.countDecimals(num) <= fixed) {
            return Number(num).toFixed(fixed)
         } else {
            var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
            return num.toString().match(re)[0]
         }
      },
      // Obtener cantidad de decimales de un numero
      countDecimals(value) {
         if (!value.toString().includes('.')) return 0
         return value.toString().split('.')[1].length || 0
      },
      // Convierte un dui a letras en formato de escrito legal
      duiLetras(dui) {
         if (dui.toString().length == 9 || dui.toString().length == 10) {
            if (dui.includes('-')) {
               var res = ''
               var duiPart = dui.split('-')
               var ceros = duiPart[0].match(/^(0)+/g)
               var cont = ceros.toString().length
               while (cont-- > 0) {
                  res = res + 'CERO '
               }
               res =
                  res +
                  this.numeroLetras(duiPart[0]) +
                  ' GUION ' +
                  this.numeroLetras(duiPart[1])
               return res.replace(/  +/g, ' ')
            } else {
               return 'MAL FORMATO - FALTA GUION'
            }
         } else {
            return 'MAL FORMATO - LONGITUD INCORRECTA'
         }
      },
      // Convierte un numero a letras
      numeroLetras(num, currency) {
         currency = currency || {}
         let data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: Math.round(num * 100) - Math.floor(num) * 100,
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || '',
            letrasMonedaSingular: currency.singular || '',
            letrasMonedaCentavoPlural: currency.centPlural || '',
            letrasMonedaCentavoSingular: currency.centSingular || ''
         }

         if (data.centavos > 0) {
            data.letrasCentavos =
               'CON ' +
               (function () {
                  if (data.centavos == 1)
                     return (
                        this.Millones(data.centavos) +
                        ' ' +
                        data.letrasMonedaCentavoSingular
                     )
                  else
                     return (
                        this.Millones(data.centavos) +
                        ' ' +
                        data.letrasMonedaCentavoPlural
                     )
               })()
         }

         if (data.enteros === 0)
            return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos
         if (data.enteros == 1)
            return (
               this.Millones(data.enteros) +
               ' ' +
               data.letrasMonedaSingular +
               ' ' +
               data.letrasCentavos
            ).replace(/  +/g, ' ')
         else
            return (
               this.Millones(data.enteros) +
               ' ' +
               data.letrasMonedaPlural +
               ' ' +
               data.letrasCentavos
            ).replace(/  +/g, ' ')
      },
      // Complemento de unidades para numeroLetras
      Unidades(num) {
         switch (num) {
            case 1:
               return 'UNO'
            case 2:
               return 'DOS'
            case 3:
               return 'TRES'
            case 4:
               return 'CUATRO'
            case 5:
               return 'CINCO'
            case 6:
               return 'SEIS'
            case 7:
               return 'SIETE'
            case 8:
               return 'OCHO'
            case 9:
               return 'NUEVE'
         }
         return ''
      },
      // Complemento de decenas para numeroLetras
      Decenas(num) {
         let decena = Math.floor(num / 10)
         let unidad = num - decena * 10

         switch (decena) {
            case 1:
               switch (unidad) {
                  case 0:
                     return 'DIEZ'
                  case 1:
                     return 'ONCE'
                  case 2:
                     return 'DOCE'
                  case 3:
                     return 'TRECE'
                  case 4:
                     return 'CATORCE'
                  case 5:
                     return 'QUINCE'
                  default:
                     return 'DIECI' + this.Unidades(unidad)
               }
               break
            case 2:
               switch (unidad) {
                  case 0:
                     return 'VEINTE'
                  default:
                     return 'VEINTI' + this.Unidades(unidad)
               }
               break
            case 3:
               return this.DecenasY('TREINTA', unidad)
            case 4:
               return this.DecenasY('CUARENTA', unidad)
            case 5:
               return this.DecenasY('CINCUENTA', unidad)
            case 6:
               return this.DecenasY('SESENTA', unidad)
            case 7:
               return this.DecenasY('SETENTA', unidad)
            case 8:
               return this.DecenasY('OCHENTA', unidad)
            case 9:
               return this.DecenasY('NOVENTA', unidad)
            case 0:
               return this.Unidades(unidad)
         }
      },
      // Complemento de "decenas y" para numeroLetras
      DecenasY(strSin, numUnidades) {
         if (numUnidades > 0) return strSin + ' Y ' + this.Unidades(numUnidades)

         return strSin
      },
      // Complemento de centenas para numeroLetras
      Centenas(num) {
         let centenas = Math.floor(num / 100)
         let decenas = num - centenas * 100

         switch (centenas) {
            case 1:
               if (decenas > 0) {
                  return 'CIENTO ' + this.Decenas(decenas)
               } else {
                  return 'CIEN'
               }
               break
            case 2:
               return 'DOSCIENTOS ' + this.Decenas(decenas)
            case 3:
               return 'TRESCIENTOS ' + this.Decenas(decenas)
            case 4:
               return 'CUATROCIENTOS ' + this.Decenas(decenas)
            case 5:
               return 'QUINIENTOS ' + this.Decenas(decenas)
            case 6:
               return 'SEISCIENTOS ' + this.Decenas(decenas)
            case 7:
               return 'SETECIENTOS ' + this.Decenas(decenas)
            case 8:
               return 'OCHOCIENTOS ' + this.Decenas(decenas)
            case 9:
               return 'NOVECIENTOS ' + this.Decenas(decenas)
         }

         return this.Decenas(decenas)
      },
      // Complemento de seccion para numeroLetras
      Seccion(num, divisor, strSingular, strPlural) {
         let cientos = Math.floor(num / divisor)
         let resto = num - cientos * divisor

         let letras = ''

         if (cientos > 0)
            if (cientos > 1) {
               letras = this.Centenas(cientos) + ' ' + strPlural
               letras = letras.replace('Y UNO MIL', 'Y UN MIL')
            } else letras = strSingular

         if (resto > 0) letras += ''

         return letras
      },
      // Complemento de miles para numeroLetras
      Miles(num) {
         let divisor = 1000
         let cientos = Math.floor(num / divisor)
         let resto = num - cientos * divisor

         let strMiles = this.Seccion(num, divisor, 'UN MIL', 'MIL')
         let strCentenas = this.Centenas(resto)

         if (strMiles === '') return strCentenas

         return strMiles + ' ' + strCentenas
      },
      // Complemento de millones para numeroLetras
      Millones(num) {
         let divisor = 1000000
         let cientos = Math.floor(num / divisor)
         let resto = num - cientos * divisor

         let strMillones = this.Seccion(num, divisor, 'UN MILLON', 'MILLONES')
         let strMiles = this.Miles(resto)

         if (strMillones === '') return strMiles

         return strMillones + ' ' + strMiles
      },

      // ----------------- FORMATEO DE TEXTOS ------------------------
      /* Funciones para dar formato a texto */

      // Capitalizar palabras de un string, que tengan mas de 3 letras
      titleCase(str, minLen = 3) {
         var splitStr = str.toLowerCase().split(' ')
         for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            if (splitStr[i].length > minLen) {
               splitStr[i] =
                  splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
            }
         }
         // Directly return the joined string
         return splitStr.join(' ')
      },

      // ----------------- ORDENAMIENTO DE ARREGLOS ------------------------
      /* Función para ordenar arreglos segun propiedades 
      People.sort(dynamicSort("Name"));
      People.sort(dynamicSort("Surname"));
      People.sort(dynamicSort("-Surname")); <-- ("-" para invertir selección) 
      data.sort(dynamicSortMultiple("materia", "#fecha", "claseHoraInicio")) ("#" para fechas DD/MM/YYYY)*/

      dynamicSort(property) {
         var sortOrder = 1
         var dateMod = false
         if (property[0] === '-') {
            sortOrder = -1
            property = property.substr(1)
         }
         if (property[0] === '#') {
            dateMod = true
            property = property.substr(1)
         }
         return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result = ''
            if (dateMod == true) {
               var date1 = a[property].replace(
                  /(\d+)\/(\d+)\/(\d+)/,
                  '$3/$2/$1'
               )
               var date2 = b[property].replace(
                  /(\d+)\/(\d+)\/(\d+)/,
                  '$3/$2/$1'
               )

               result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0
            } else {
               result =
                  a[property] < b[property]
                     ? -1
                     : a[property] > b[property]
                     ? 1
                     : 0
            }
            return result * sortOrder
         }
      },

      dynamicSortMultiple() {
         var me = this
         /*
          * save the arguments object as it will be overwritten
          * note that arguments object is an array-like object
          * consisting of the names of the properties to sort by
          */
         var props = arguments
         return function (obj1, obj2) {
            var i = 0,
               result = 0,
               numberOfProperties = props.length
            /* try getting a different result from 0 (equal)
             * as long as we have extra properties to compare
             */
            while (result === 0 && i < numberOfProperties) {
               result = me.dynamicSort(props[i])(obj1, obj2)
               i++
            }
            return result
         }
      },

      // ----------------- CLIPBOARD ------------------------
      /* Copiar un string al clipboard */
      copyStringToClipboard(str) {
         // Create new element
         var el = document.createElement('textarea')
         // Set value (string to be copied)
         el.value = str
         // Set non-editable to avoid focus and move outside of view
         el.setAttribute('readonly', '')
         el.style = { position: 'absolute', left: '-9999px' }
         document.body.appendChild(el)
         // Select text inside element
         el.select()
         // Copy text to clipboard
         document.execCommand('copy')
         // Remove temporary element
         document.body.removeChild(el)
      },

      // ----------------- EXCEL ------------------------
      /* Autoajustar anchos de columna */
      autofitColumns(json, worksheet, header = null) {
         const jsonKeys = header ? header : Object.keys(json[0])

         let objectMaxLength = []
         for (let i = 0; i < json.length; i++) {
            let value = json[i]
            for (let j = 0; j < jsonKeys.length; j++) {
               if (typeof value[jsonKeys[j]] == 'number') {
                  objectMaxLength[j] = 10
               } else {
                  const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0

                  objectMaxLength[j] =
                     objectMaxLength[j] >= l ? objectMaxLength[j] : l
               }
            }

            let key = jsonKeys
            for (let j = 0; j < key.length; j++) {
               objectMaxLength[j] =
                  objectMaxLength[j] >= key[j].length
                     ? objectMaxLength[j]
                     : key[j].length
            }
         }

         const wscols = objectMaxLength.map((w) => {
            return { width: w }
         })

         worksheet['!cols'] = wscols
      }
   }
}
