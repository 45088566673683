<template>
   <v-layout align-start wrap>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}

         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <v-flex xs12>
         <span class="font-weight-medium text-h5">Bienes familiares</span>
      </v-flex>
      <v-flex xs12 class="d-flex align-center py-4">
         <span class="colorPrimary font-weight-medium" style="font-size: 20px">
            Indicaciones:
         </span>
      </v-flex>
      <v-flex xs12>
         <ul>
            <li>
               <div class="font-weight-medium">
                  Responda las interrogantes que se le solicitan a continuación:
               </div>
            </li>
         </ul>
      </v-flex>

      <!-- TABLA -->
      <v-form
         ref="formBienesFamiliares"
         v-model="validBienesFamiliares"
         lazy-validation
      >
         <v-layout
            xs12
            mt-4
            mb-8
            wrap
            :style="
               $vuetify.breakpoint.mdAndUp
                  ? 'margin-right: 5%; margin-left: 5%;'
                  : ''
            "
         >
            <v-flex xs12>
               <p>
                  <span class="font-weight-medium" style="font-size: 20px">
                     ¿De que material esta hecha su vivienda?
                  </span>
               </p>
               <v-autocomplete
                  v-model="selectMaterialVivienda"
                  label="Material"
                  :rules="rulesMaterialVivienda"
                  :items="cmbMaterialVivienda"
                  :readonly="completada"
                  item-text="nombre"
                  item-value="materialDeViviendaId"
               ></v-autocomplete>
            </v-flex>
            <v-flex xs12 mt-8>
               <p>
                  <span class="font-weight-medium" style="font-size: 20px">
                     Su vivienda es...
                  </span>
               </p>
               <v-autocomplete
                  v-model="selectTipoVivienda"
                  label="Tipo de vivienda"
                  :rules="rulesTipoVivienda"
                  :items="cmbTipoVivienda"
                  :readonly="completada"
                  item-text="nombre"
                  item-value="tipoDeViviendaId"
               ></v-autocomplete>
            </v-flex>
            <v-flex xs12 mt-8>
               <p>
                  <span class="font-weight-medium" style="font-size: 20px">
                     ¿Posee vehículo?
                  </span>
                  <br />
                  <span class="font-weight-medium" style="font-size: 16px">
                     Si la respuesta es afirmativa, por favor detalle sus
                     características
                  </span>
               </p>
            </v-flex>
            <v-flex xs12>
               <v-radio-group
                  v-model="radioPoseeVehiculo"
                  :readonly="completada"
                  row
               >
                  <v-radio label="No" value="no" color="primary"></v-radio>
                  <v-radio label="Si" value="si" color="primary"></v-radio>
               </v-radio-group>
            </v-flex>
            <v-flex xs12 sm6>
               <v-autocomplete
                  v-model="selectTipoVehiculo"
                  :items="cmbTipoVehiculo"
                  :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
                  :disabled="radioPoseeVehiculo != 'si'"
                  :readonly="completada"
                  item-text="nombre"
                  item-value="tipoDeVehiculoId"
                  :rules="radioPoseeVehiculo == 'si' ? rulesVehiculoTipo : []"
                  label="Tipo"
               ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6>
               <v-text-field
                  v-model="vehiculoPlaca"
                  :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
                  :disabled="radioPoseeVehiculo != 'si'"
                  :readonly="completada"
                  :rules="radioPoseeVehiculo == 'si' ? rulesVehiculoPlaca : []"
                  label="Placa"
               ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
               <v-text-field
                  v-model="vehiculoModelo"
                  :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
                  :disabled="radioPoseeVehiculo != 'si'"
                  :readonly="completada"
                  :rules="radioPoseeVehiculo == 'si' ? rulesVehiculoModelo : []"
                  label="Modelo"
               ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
               <v-text-field
                  v-model="vehiculoAnnio"
                  :disabled="radioPoseeVehiculo != 'si'"
                  :readonly="completada"
                  :rules="radioPoseeVehiculo == 'si' ? rulesVehiculoAnnio : []"
                  label="Año"
               ></v-text-field>
            </v-flex>
         </v-layout>
      </v-form>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         my-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="grey lighten-3"
            @click="cancelarBienesFamiliares"
         >
            Paso anterior
         </v-btn>
         <v-btn
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            color="primary"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="!validBienesFamiliares"
            @click="nextStepBienesFamiliares"
         >
            Continuar
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'

export default {
   name: 'FormBecaBienesFamiliares',
   props: {
      solicitudBecaId: {
         type: Number,
         default: null
      },
      completada: {
         type: Boolean,
         default: false
      }
   },
   data: () => ({
      // Variables
      selectMaterialVivienda: '',
      cmbMaterialVivienda: [],
      selectTipoVivienda: '',
      cmbTipoVivienda: [],
      selectTipoVehiculo: '',
      cmbTipoVehiculo: [],
      radioPoseeVehiculo: 'no',
      vehiculoPlaca: '',
      vehiculoModelo: '',
      vehiculoAnnio: '',
      validBienesFamiliares: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Reglas de validacion de formularios
      rulesVehiculoPlaca: [(v) => !!v || 'Ingrese el número de placa'],
      rulesVehiculoTipo: [(v) => !!v || 'Seleccione un tipo'],
      rulesVehiculoModelo: [(v) => !!v || 'Ingrese el modelo'],
      rulesVehiculoAnnio: [
         (v) => !!v || 'Ingrese el año',
         (v) =>
            v === '' || /^(\d*\.)?\d+$/.test(v) || 'Formato de número inválido'
      ],
      rulesMaterialVivienda: [
         (v) => !!v || 'Seleccione un material de vivienda'
      ],
      rulesTipoVivienda: [(v) => !!v || 'Seleccione un tipo de vivienda']
   }),
   computed: {
      ...authComputed
   },
   watch: {
      solicitudBecaId(val) {
         if (val != null && val != undefined) {
            // Listar bienes familiares}
            axios
               .get(`api/solicitudesdebecas/${val}/BienesFamiliares`)
               .then((response) => {
                  var result = response.data
                  this.selectMaterialVivienda = result.materialViviendaId
                  this.selectTipoVivienda = result.tipoViviendaId
                  this.radioPoseeVehiculo =
                     result.numeroDePlaca == null ? 'no' : 'si'
                  this.vehiculoPlaca = result.numeroDePlaca
                  this.selectTipoVehiculo = result.tipoDeVehiculoId
                  this.vehiculoModelo = result.modelo
                  this.vehiculoAnnio = result.annio == 0 ? '' : result.annio
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },
      radioPoseeVehiculo(val) {
         if (val == 'no') {
            this.vehiculoPlaca = ''
            this.vehiculoModelo = ''
            this.vehiculoAnnio = ''
            this.selectTipoVehiculo = null
         } else {
            this.$refs.formBienesFamiliares.resetValidation()
         }
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      async nextStepBienesFamiliares() {
         if (this.$refs.formBienesFamiliares.validate()) {
            let helperData
            //Obteniendo Informacion de BienesFamiliares
            await axios
               .get(
                  `api/solicitudesdebecas/${this.solicitudBecaId}/BienesFamiliares`
               )
               .then((response) => {
                  helperData = response.data
               })
               .catch((error) => {
                  console.log(error)
               })

            //Verificando si Tiene Vehiculo
            let getPoseeVehiculo =
               helperData.numeroDePlaca == null ? 'no' : 'si'

            //Verificando si hay Algun Cambio en la Informacion del Vehiculo
            let vehiculoChanged = false
            if (getPoseeVehiculo == 'si') {
               if (
                  helperData.numeroDePlaca != this.vehiculoPlaca ||
                  helperData.annio != this.vehiculoAnnio ||
                  helperData.modelo != this.vehiculoModelo ||
                  helperData.tipoDeVehiculoId != this.selectTipoVehiculo
               ) {
                  vehiculoChanged = true
               }
            }

            /*
               Cambio: Hacer el PUT
               Sin Cambios: Pasar al Siguiente Paso
            */
            if (
               helperData.materialViviendaId != this.selectMaterialVivienda ||
               helperData.tipoViviendaId != this.selectTipoVivienda ||
               getPoseeVehiculo != this.radioPoseeVehiculo ||
               vehiculoChanged
            ) {
               axios
                  .put(
                     `/api/solicitudesdebecas/${this.solicitudBecaId}/BienesFamiliares`,
                     {
                        numeroDePlaca:
                           this.radioPoseeVehiculo == 'si'
                              ? this.vehiculoPlaca
                              : null,
                        modelo:
                           this.radioPoseeVehiculo == 'si'
                              ? this.vehiculoModelo
                              : null,
                        annio:
                           this.radioPoseeVehiculo == 'si'
                              ? this.vehiculoAnnio
                              : 0,
                        tipoDeVehiculoId:
                           this.radioPoseeVehiculo == 'si'
                              ? this.selectTipoVehiculo
                              : null,
                        tipoViviendaId: this.selectTipoVivienda,
                        materialViviendaId: this.selectMaterialVivienda
                     }
                  )
                  .catch(() => {
                     // Do nothing
                  })
                  .finally(() => {
                     this.$emit('nextStep', 3.4)
                  })
            } else {
               this.$emit('nextStep', 3.4)
            }
         }
      },
      cancelarBienesFamiliares() {
         this.$emit('cancelGoStep', 3.2)
      },
      listarComboboxes() {
         axios
            .get('api/general/MaterialesDeViviendas')
            .then((response) => {
               this.cmbMaterialVivienda = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/general/TiposDeViviendas')
            .then((response) => {
               this.cmbTipoVivienda = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/general/TiposDeVehiculos')
            .then((response) => {
               this.cmbTipoVehiculo = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
