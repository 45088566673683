var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"aling-start":""}},[_c('v-flex',[_c('v-snackbar',{attrs:{"color":_vm.snackColor,"right":true,"bottom":true,"timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-layout',{attrs:{"wrap":""}},[(_vm.mostrarMensaje)?_c('v-flex',{staticClass:"mb-8",attrs:{"xs12":""}},[_c('v-card',{staticClass:"borderDetallesPrimary"},[_c('v-layout',{class:_vm.$vuetify.breakpoint.smAndUp ? 'px-6' : 'px-4 py-4',attrs:{"wrap":"","fill-height":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","text-center":""}},[_c('v-icon',{attrs:{"size":"35","color":"primary"}},[_vm._v(" fa-info-circle ")])],1),_c('v-flex',{staticClass:"pt-2",attrs:{"xs12":"","sm11":"","text-justify":""}},[_c('span',{staticClass:"font-weight-bold colorPrimary"},[_vm._v(" AVISO IMPORTANTE: ")]),_c('p',{staticClass:"font-weight-medium",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.mensajeBecas)+" ")])])],1)],1)],1):_vm._e(),(_vm.solicitudInfo != null)?_c('v-flex',{staticClass:"font-weight-medium text-h6",attrs:{"xs12":"","text-center":"","mb-4":""}},[_vm._v(" Su número de referencia es: "),_c('br'),_c('span',{staticClass:"colorPrimary font-weight-bold"},[_vm._v(_vm._s(_vm.solicitudInfo.numeroReferencia))])]):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-stepper',{class:_vm.$vuetify.breakpoint.xsOnly ? 'elevation-0' : '',attrs:{"non-linear":""},model:{value:(_vm.stepNum),callback:function ($$v) {_vm.stepNum=$$v},expression:"stepNum"}},[_c('v-stepper-items',{class:_vm.$vuetify.breakpoint.smAndUp ? 'px-8' : 'py-2'},[_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"1"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"my-12",attrs:{"xs12":""}},[_c('v-form',{ref:"formReferencia",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.revisarSolicitud.apply(null, arguments)}},model:{value:(_vm.validReferencia),callback:function ($$v) {_vm.validReferencia=$$v},expression:"validReferencia"}},[_c('p',{staticClass:"font-weight-medium text-center text-h5"},[_vm._v(" Para consultar tu solicitud de beca ingresa tú número de referencia: ")]),_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('div',{style:(_vm.$vuetify.breakpoint.smAndUp
                                             ? 'width: 300px'
                                             : '')},[_c('v-text-field',{attrs:{"rules":_vm.rulesReferencia,"label":"Referencia","color":"primary"},model:{value:(_vm.referencia),callback:function ($$v) {_vm.referencia=$$v},expression:"referencia"}})],1)]),_c('v-layout',{attrs:{"justify-center":"","align-center":"","mt-2":""}},[_c('v-btn',{attrs:{"rounded":"","color":"primary","outlined":"","disabled":!_vm.validReferencia,"width":_vm.$vuetify.breakpoint.xsOnly
                                             ? '100%'
                                             : '',"height":"60px","x-large":""},on:{"click":_vm.revisarSolicitud}},[_vm._v(" Revisar solicitud ")])],1)],1)],1)],1)],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"3"}},[_c('FormBecasDatosGenerales',{attrs:{"carnet-prop":_vm.carnet,"beneficiario-id":_vm.selectBeneficiario,"solicitud-info-prop":_vm.solicitudInfo},on:{"cancelGoStep":function($event){_vm.stepNum = $event},"nextStep":function($event){return _vm.nextStepDatosGenerales($event)}}})],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"3.1"}},[_c('FormBecasLaboral',{attrs:{"solicitud-beca-id":_vm.solicitudBecaId,"completada":_vm.completada},on:{"cancelGoStep":function($event){_vm.stepNum = $event},"nextStep":function($event){_vm.stepNum = $event}}})],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"3.2"}},[_c('FormBecasNucleoFamiliar',{attrs:{"solicitud-beca-id":_vm.solicitudBecaId,"completada":_vm.completada},on:{"cancelGoStep":function($event){_vm.stepNum = $event},"nextStep":function($event){_vm.stepNum = $event}}})],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"3.3"}},[_c('FormBecasBienesFamiliares',{attrs:{"solicitud-beca-id":_vm.solicitudBecaId,"completada":_vm.completada},on:{"cancelGoStep":function($event){_vm.stepNum = $event},"nextStep":function($event){_vm.stepNum = $event}}})],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"3.4"}},[_c('FormBecasContIngresosEgresos',{attrs:{"solicitud-beca-id":_vm.solicitudBecaId,"completada":_vm.completada},on:{"cancelGoStep":function($event){_vm.stepNum = $event},"nextStep":function($event){_vm.stepNum = $event}}})],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"3.5"}},[_c('FormBecasEnsayo',{attrs:{"solicitud-beca-id":_vm.solicitudBecaId,"completada":_vm.completada},on:{"cancelGoStep":function($event){_vm.stepNum = $event},"nextStep":function($event){_vm.stepNum = $event}}})],1),_c('v-stepper-content',{style:(_vm.$vuetify.breakpoint.xsOnly
                              ? 'margin: 0px 0px; padding: 0px 0px;'
                              : ''),attrs:{"step":"4"}},[_c('FormBecasComprobantes',{attrs:{"solicitud-beca-id":_vm.solicitudBecaId,"beneficiario-id":_vm.selectBeneficiario,"completada":_vm.completada,"listar":_vm.stepNum == 4},on:{"cancelGoStep":function($event){_vm.stepNum = $event}}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }