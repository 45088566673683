<template>
   <v-layout align-start wrap>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}

         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <!-- DIALOG MENSAJE -->
      <v-dialog v-model="dialogMensaje" persistent max-width="400px">
         <v-form>
            <v-card class="pb-4">
               <v-card-text class="text--primary">
                  <v-container grid-list-md fluid>
                     <v-layout wrap>
                        <v-flex xs12 mt-2>
                           <p
                              class="text-justify font-weight-medium pt-4"
                              style="font-size: 18px"
                           >
                              <span>
                                 La preinscripción de solicitud de beca ha sido
                                 generada correctamente.
                              </span>
                              <br /><br />
                              <span>
                                 Se ha enviado un enlace de acceso con el
                                 siguiente
                                 <span class="colorPrimary"
                                    >número de referencia</span
                                 >
                                 al correo institucional proveido:
                              </span>
                              <br /><br />
                              <v-layout justify-center>
                                 <span
                                    class="colorPrimary text-h6 font-weight-medium text-center"
                                 >
                                    {{ solicitudBecaRef }}
                                 </span>
                              </v-layout>
                           </p>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions>
                  <v-layout class="d-flex justify-center px-16">
                     <v-btn
                        :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
                        color="primary"
                        large
                        rounded
                        block
                        @click.native="entendidoNextStep()"
                     >
                        Entendido
                     </v-btn>
                  </v-layout>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <!-- DIALOG CONSULTA NOTAS -->
      <v-dialog v-model="dialogNotas" eager max-width="1200px">
         <v-card>
            <v-card-text class="text--primary" style="margin-top: -20px">
               <v-layout wrap class="px-4">
                  <RecordAlu
                     :carnet="carnet"
                     :all="false"
                     :show-dialog="dialogNotas"
                  />
               </v-layout>
            </v-card-text>
            <v-card-actions style="margin-top: -10px; padding-bottom: 20px">
               <v-spacer></v-spacer>
               <v-btn
                  color="primary"
                  dark
                  outlined
                  min-width="150"
                  @click.native="dialogNotas = false"
                  >Salir</v-btn
               >
               <v-spacer></v-spacer>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <!-- DIALOG HISTORIAL BECA -->
      <v-dialog v-model="dialogHistorialBecas" eager max-width="1200px">
         <v-card class="pt-6">
            <v-card-text class="text--primary">
               <v-container grid-list-md>
                  <v-layout wrap>
                     <HistorialBecas :carnet="carnet" />
                  </v-layout>
               </v-container>
            </v-card-text>
            <v-card-actions style="margin-top: -10px; padding-bottom: 20px">
               <v-spacer></v-spacer>
               <v-btn
                  color="primary"
                  dark
                  outlined
                  min-width="150"
                  @click.native="dialogHistorialBecas = false"
                  >Salir</v-btn
               >
               <v-spacer></v-spacer>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <!-- DIALOG PENSUM -->
      <v-dialog
         v-model="dialogPensum"
         fullscreen
         hide-overlay
         transition="dialog-bottom-transition"
      >
         <v-card>
            <v-toolbar dark color="primary">
               <v-toolbar-title>Pensum de {{ carnet }}</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn icon dark @click="dialogPensum = false">
                  <v-icon>fas fa-times</v-icon>
               </v-btn>
            </v-toolbar>
            <div>
               <Pensum :carnet="carnet" />
            </div>
         </v-card>
      </v-dialog>

      <!-- DIALOG CAMBIAR EMAIl -->
      <v-dialog v-model="dialogCambiarEmail" persistent max-width="450px">
         <v-form
            ref="formCambiarEmail"
            v-model="validCambiarEmail"
            lazy-validation
         >
            <v-card class="pb-4">
               <v-card-text class="text--primary">
                  <v-container grid-list-md fluid>
                     <v-layout wrap>
                        <v-flex xs12 mt-1 mb-4 mx-4>
                           <p
                              class="text-justify font-weight-medium mb-0 pb-0"
                              style="font-size: 18px"
                           >
                              <span class="text-h5 font-weight-medium">
                                 Actualizar correo institucional
                              </span>
                              <br /><br />
                              <span>
                                 Se generará un nuevo número de referencia y se
                                 enviará una nueva notificación a la nueva
                                 dirección de correo proveída.
                                 <br /><br />
                                 El enlace de acceso generado previamente para
                                 esta solicitud de beca dejará de funcionar.
                              </span>
                              <br /><br />
                              <v-layout justify-center>
                                 <span
                                    class="colorPrimary text-h6 font-weight-medium text-center"
                                 >
                                    {{ solicitudBecaRef }}
                                 </span>
                              </v-layout>
                           </p>
                           <v-text-field
                              v-model="nuevoEmail"
                              label="Nuevo correo"
                              :rules="rulesEmail"
                              color="primary"
                           ></v-text-field>
                        </v-flex>
                     </v-layout>

                     <v-layout class="d-flex justify-center px-16">
                        <v-btn
                           class="mr-1"
                           color="primary"
                           large
                           outlined
                           rounded
                           @click.native="cancelarCambiarEmail"
                        >
                           Cancelar
                        </v-btn>
                        <v-btn
                           class="ml-1"
                           color="primary"
                           large
                           rounded
                           :loading="loadingCambiandoCorreo"
                           :disabled="
                              !validCambiarEmail || loadingCambiandoCorreo
                           "
                           @click.native="actualizarEmail"
                        >
                           Actualizar
                        </v-btn>
                     </v-layout>
                  </v-container>
               </v-card-text>
            </v-card>
         </v-form>
      </v-dialog>

      <!-- DIALOG SUBIENDO FOTO -->
      <v-dialog v-model="dialogEnviando" persistent width="300">
         <v-card color="primary" dark class="pt-5 pb-2">
            <v-card-text style="color: white">
               Subiendo archivo
               <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
               ></v-progress-linear>
            </v-card-text>
         </v-card>
      </v-dialog>

      <!-- DIALOG SUBIR FOTOGRAFIA -->
      <v-dialog v-model="dialogFotografia" persistent max-width="600px">
         <v-form ref="formFotografia" v-model="validFotografia" lazy-validation>
            <v-card>
               <v-card-text class="text--primary" style="margin-top: -20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium">
                              Subir fotografía
                           </span>
                        </v-flex>
                     </v-layout>
                     <v-layout wrap class="my-4">
                        <v-flex xs12>
                           <v-file-input
                              v-model="archivosAdjuntos"
                              color="primary"
                              :rules="rulesFotografia"
                              label="Archivo adjunto"
                              placeholder="Selecciona tu archivo"
                              prepend-icon="fa-paperclip"
                              accept="image/png, image/jpeg, image/bmp"
                              outlined
                              multiple
                              counter
                              :show-size="1000"
                           >
                              <template #selection="{ text }">
                                 <v-chip color="primary" dark label small>
                                    {{ text }}
                                 </v-chip>
                              </template>
                           </v-file-input>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions
                  style="
                     margin-top: -40px;
                     margin-right: 20px;
                     padding-bottom: 20px;
                  "
               >
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click.native="closeFotografia"
                     >Cancelar</v-btn
                  >
                  <v-btn
                     color="primary white--text"
                     :disabled="!validFotografia"
                     @click.native="subirFotografia"
                     >Subir</v-btn
                  >
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>
      <v-flex v-if="!preinscripcion" mb-8 xs12>
         <span class="text-h5 font-weight-medium">
            Estado de solicitud:
            <span class="colorPrimary">
               {{ estadoNombre + tipoBecaDescripcion }}
            </span>
         </span>
      </v-flex>

      <v-flex xs12 class="d-flex flex-wrap align-center justify-end">
         <div
            v-if="oidcUser"
            class="mr-auto my-2 colorPrimary font-weight-medium"
            style="font-size: 20px"
         >
            {{ `Total de Materias: ${totalMaterias}` }}
         </div>
         <div
            v-if="oidcUser"
            class="mr-auto my-2 colorPrimary font-weight-medium"
            style="font-size: 20px"
         >
            {{ `Materias Aprobadas: ${materiasAprobadas}` }}
         </div>
         <v-btn
            v-if="checkRangoAlto()"
            class="ma-2"
            :disabled="accessCode == '' ? true : false"
            color="grey lighten-3"
            @click="irColecturiaDigital"
            >Colecturia Digital
         </v-btn>
         <v-btn
            v-if="oidcUser"
            class="ma-2"
            color="grey lighten-3"
            @click="dialogNotas = true"
            >Notas
         </v-btn>
         <v-btn
            v-if="oidcUser"
            class="ma-2 grey lighten-3"
            @click="dialogPensum = true"
            >Pensum
         </v-btn>
         <v-btn
            v-if="oidcUser"
            class="ma-2 grey lighten-3"
            @click="dialogHistorialBecas = true"
            >Historial Becas
         </v-btn>
      </v-flex>
      <v-flex xs12>
         <span class="font-weight-medium text-h5">Datos generales</span>
      </v-flex>
      <v-flex xs12 class="d-flex align-center py-4">
         <span class="colorPrimary font-weight-medium" style="font-size: 20px">
            Indicaciones:
         </span>
      </v-flex>
      <v-flex xs12>
         <ul>
            <li>
               <div class="font-weight-medium">
                  Ingrese los datos que se le solicitan a continuación:
               </div>
            </li>
         </ul>
      </v-flex>

      <!-- DATOS GENERALES -->
      <v-flex xs12>
         <v-form
            ref="formDatosGenerales"
            v-model="validDatosGenerales"
            lazy-validation
         >
            <v-container grid-list-lg fluid>
               <v-layout
                  wrap
                  :style="
                     $vuetify.breakpoint.mdAndUp
                        ? 'margin-right: 12%; margin-left: 12%;'
                        : ''
                  "
               >
                  <v-flex v-if="!preinscripcion" class="text-center" xs12 my-4>
                     <p class="mb-2">
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Fotografía de rostro
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Subir fotografía con fondo blanco.
                        </span>
                     </p>
                     <v-layout align-center justify-center wrap>
                        <v-flex xs12>
                           <v-avatar
                              class="avatarBorder elevation-3 mb-4 mt-2"
                              color="primary"
                              style="border: solid red 6px"
                              size="200"
                              @click="verFotografia(profilePicturePath)"
                           >
                              <v-img :src="profilePicturePath" alt="avatar" />
                           </v-avatar>
                        </v-flex>
                        <v-flex xs12>
                           <v-btn
                              v-if="!completada"
                              color="primary"
                              outlined
                              rounded
                              class="mb-4"
                              :disabled="completada"
                              @click="dialogFotografia = true"
                           >
                              Subir imagen
                           </v-btn>
                        </v-flex>
                     </v-layout>
                  </v-flex>
                  <v-flex
                     v-if="!preinscripcion && checkRangoAlto()"
                     xs6
                     sm3
                     mt-4
                  >
                     <p
                        style="cursor: pointer"
                        @click="checkRangoAlto() ? alternarBloqueado() : false"
                     >
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Bloqueado: <br />
                           <span class="colorPrimary">
                              {{ bloqueado ? 'Si' : 'No' }}
                           </span>
                        </span>
                     </p>
                  </v-flex>
                  <v-flex
                     v-if="!preinscripcion && oidcUser"
                     :class="
                        !checkRangoAlto() ? 'd-flex justify-end pr-12' : ''
                     "
                     xs6
                     sm3
                     mt-4
                  >
                     <p
                        style="cursor: pointer"
                        @click="
                           checkRangoAlto() ? alternarReaplicacion() : false
                        "
                     >
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Reaplicación: <br />
                           <span class="colorPrimary">
                              {{ reaplicacion ? 'Si' : 'No' }}
                           </span>
                        </span>
                     </p>
                  </v-flex>
                  <v-flex
                     v-if="!preinscripcion && checkRangoAlto()"
                     xs6
                     sm3
                     mt-4
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Notificación: <br />
                           <span class="colorPrimary">
                              {{ notificado ? 'Enviada' : 'Sin enviar' }}
                           </span>
                        </span>
                     </p>
                  </v-flex>
                  <v-flex
                     v-if="!preinscripcion && checkRangoAlto()"
                     xs6
                     sm3
                     mt-4
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Tipo de beneficio: <br />
                           <span class="colorPrimary">
                              {{ tipoBeneficioNombre }}
                           </span>
                        </span>
                     </p>
                  </v-flex>
                  <v-flex
                     v-if="
                        !preinscripcion &&
                        checkRangoAlto() &&
                        (irregular || noCumple || procesoDeGraduacion)
                     "
                     xs12
                     mt-4
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Al momento de pre-inscribir la solicitud de beca, el
                           alumno:
                           <span class="colorPrimary">
                              {{ generarMensajeRequisitos() }}
                           </span>
                        </span>
                     </p>
                  </v-flex>
                  <v-flex v-if="!preinscripcion && checkRangoAlto()" xs12 mt-4>
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Tipo de beca a aplicar
                        </span>
                     </p>
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-autocomplete
                              v-model="selectTipoBeca"
                              label="Tipo de beca"
                              :items="cmbTipoBeca"
                              item-text="descripcion"
                              item-value="tipoBecaId"
                              :readonly="
                                 !checkIsAdmin() || selectEstadoCaso == 3
                              "
                           ></v-autocomplete>
                        </v-flex>
                     </v-layout>
                  </v-flex>
                  <v-flex v-if="checkInsAuspiciadora">
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Auspiciadora
                        </span>
                     </p>
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-autocomplete
                              v-model="selectAuspiciador"
                              label="Institución Auspiciadora"
                              :items="cmbInsAuspiciadora"
                              item-text="nombre"
                              item-value="institucionPatrocinadoraId"
                           ></v-autocomplete>
                        </v-flex>
                     </v-layout>
                  </v-flex>
                  <v-flex v-if="!preinscripcion && checkRangoAlto()" xs12 mt-4>
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Datos de solicitud
                        </span>
                     </p>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.smAndUp" xs12 sm5>
                     <v-autocomplete
                        v-model="selectBeneficiario"
                        label="Tipo de beneficiario"
                        :items="cmbBeneficiarios"
                        item-text="nombre"
                        item-value="beneficiarioId"
                        :readonly="!checkRangoAlto()"
                     ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 :sm5="!checkRangoAlto()" :sm2="checkRangoAlto()">
                     <v-autocomplete
                        v-model="selectEstadoCaso"
                        label="Estado de solicitud"
                        :items="cmbEstadoCasoFiltered"
                        item-text="nombre"
                        item-value="estadoCasoId"
                        :readonly="preinscripcion || !oidcUser"
                        @focus="filterEstadosFocus()"
                        @blur="filterEstadosBlur()"
                     >
                     </v-autocomplete>
                  </v-flex>
                  <v-flex v-if="checkRangoAlto()" xs12 sm3>
                     <v-autocomplete
                        v-model="selectPrioridad"
                        :readonly="preinscripcion"
                        label="Prioridad"
                        :items="cmbPrioridad"
                        item-text="nombre"
                        item-value="id"
                     >
                        <template #item="data">
                           <v-list-item-avatar>
                              <v-icon
                                 size="18"
                                 :color="
                                    data.item.id == 0
                                       ? 'primary'
                                       : data.item.id == 1
                                       ? 'amber darken-3'
                                       : 'green darken-2'
                                 "
                                 v-on="on"
                                 >fas fa-circle</v-icon
                              >
                           </v-list-item-avatar>
                           <v-list-item-content>
                              <v-list-item-title>
                                 {{ data.item.nombre }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </template>
                     </v-autocomplete>
                  </v-flex>
                  <v-flex v-if="oidcUser !== null" xs12 sm2>
                     <v-autocomplete
                        v-model="selectAsesor"
                        :readonly="checkModificarAsesor()"
                        :items="cmbAsesores"
                        item-value="id"
                        item-text="userClaims[0].claimValue"
                        label="Asesor"
                        color="primary"
                     ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm2>
                     <v-text-field
                        v-model="carnet"
                        v-mask="'####-AA-###'"
                        label="Carnet"
                        color="primary"
                        :rules="rulesCarnet"
                        :readonly="
                           !preinscripcion ||
                           selectBeneficiario == 1 ||
                           selectBeneficiario == 2
                        "
                     >
                     </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5>
                     <v-text-field
                        v-model="nombres"
                        :rules="rulesNombre"
                        class="upper"
                        label="Nombres"
                        color="primary"
                        :readonly="
                           !preinscripcion ||
                           selectBeneficiario == 1 ||
                           selectBeneficiario == 2
                        "
                        @keyup="uppercase"
                     >
                     </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5>
                     <v-text-field
                        v-model="apellidos"
                        :rules="rulesApellido"
                        class="upper"
                        label="Apellidos"
                        color="primary"
                        :readonly="
                           !preinscripcion ||
                           selectBeneficiario == 1 ||
                           selectBeneficiario == 2
                        "
                        @keyup="uppercase"
                     >
                     </v-text-field>
                  </v-flex>

                  <v-flex v-if="!preinscripcion" xs12 sm4>
                     <v-text-field
                        v-model="telefonoPrincipal"
                        v-mask="'####-####'"
                        :readonly="completada"
                        label="Teléfono principal"
                        :rules="rulesTelefonoPrincipal"
                        color="primary"
                     >
                     </v-text-field>
                  </v-flex>
                  <v-flex v-if="!preinscripcion" xs12 sm4>
                     <v-text-field
                        v-model="telefonoSecundario"
                        v-mask="'####-####'"
                        :readonly="completada"
                        label="Teléfono secundario"
                        color="primary"
                     >
                     </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                     <v-text-field
                        v-model="email"
                        :readonly="!preinscripcion"
                        label="Correo institucional"
                        :rules="rulesEmail"
                        color="primary"
                     >
                        <template
                           v-if="checkRangoAlto() && !preinscripcion"
                           #append-outer
                        >
                           <v-tooltip bottom max-width="300" color="primary">
                              <template #activator="{ on }">
                                 <v-btn
                                    text
                                    small
                                    fab
                                    color="amber darken-3"
                                    style="margin-top: -8px"
                                    v-on="on"
                                    @click="showCambiarEmail"
                                 >
                                    <v-icon>fa-pen</v-icon>
                                 </v-btn>
                              </template>
                              <span style="font-size: 15px"> Editar </span>
                           </v-tooltip>
                        </template>
                     </v-text-field>
                  </v-flex>
                  <v-flex v-if="!preinscripcion" xs12 sm4>
                     <CustomDatePicker
                        :date="fechaNacimiento"
                        :readonly="completada"
                        label="Fecha de nacimiento"
                        year
                        :max="toISOLocal(new Date()).substr(0, 10)"
                        :rules="rulesFechaNacimiento"
                        @updateFecha="fechaNacimiento = $event"
                     />
                  </v-flex>
                  <v-flex v-if="!preinscripcion" xs12 sm4>
                     <CustomDatePicker
                        :date="fechaGraduacion"
                        :readonly="completada || selectBeneficiario != 2"
                        label="Fecha de graduación"
                        clearable
                        year
                        :max="toISOLocal(new Date()).substr(0, 10)"
                        @updateFecha="fechaGraduacion = $event"
                     />
                  </v-flex>
                  <v-flex
                     v-if="
                        !preinscripcion &&
                        selectBeneficiario != 5 &&
                        selectBeneficiario != 6
                     "
                     xs12
                     sm4
                  >
                     <v-autocomplete
                        v-model="selectEstadoCivil"
                        :readonly="completada"
                        :rules="rulesEstadoCivil"
                        label="Estado civil"
                        :items="cmbEstadoCivil"
                        item-text="nombre"
                        item-value="estadoCivilId"
                     ></v-autocomplete>
                  </v-flex>
                  <v-flex v-if="!preinscripcion" xs12 sm4>
                     <v-autocomplete
                        v-model="selectNivelAcademico"
                        :readonly="completada"
                        :rules="rulesNivelAcademico"
                        label="Nivel académico"
                        :items="cmbNivelAcademico"
                        item-text="nombre"
                        item-value="nivelAcademicoId"
                     ></v-autocomplete>
                  </v-flex>
                  <v-flex
                     v-if="!preinscripcion"
                     xs12
                     :sm8="selectBeneficiario != 5 && selectBeneficiario != 6"
                     :sm6="selectBeneficiario == 5 || selectBeneficiario == 6"
                  >
                     <v-text-field
                        v-model="direccion"
                        :readonly="completada"
                        :rules="rulesDireccion"
                        label="Dirección"
                        color="primary"
                     >
                     </v-text-field>
                  </v-flex>
                  <v-flex v-if="!preinscripcion" xs12 sm6>
                     <v-autocomplete
                        v-model="selectedDepartamento"
                        :rules="rulesDepartamento"
                        label="Departamento"
                        :readonly="completada"
                        :items="cmbDepartamentos"
                        item-text="nombre"
                        item-value="departamentoId"
                     ></v-autocomplete>
                  </v-flex>
                  <v-flex v-if="!preinscripcion" xs12 sm6>
                     <v-autocomplete
                        v-model="selectedMunicipio"
                        :rules="rulesMunicipio"
                        label="Municipio"
                        :readonly="completada"
                        :items="filteredMunicipios"
                        item-text="nombre"
                        item-value="municipioId"
                     ></v-autocomplete>
                  </v-flex>
                  <v-flex
                     v-if="
                        !preinscripcion &&
                        selectBeneficiario != 5 &&
                        selectBeneficiario != 6
                     "
                     xs12
                     sm4
                  >
                     <v-input-number
                        :value="cantHijos"
                        label="N° de hijos"
                        :readonly="completada"
                        :min="0"
                        @input="cantHijos = $event"
                     ></v-input-number>
                  </v-flex>
                  <v-flex
                     xs12
                     :sm8="
                        (selectBeneficiario != 5 && selectBeneficiario != 6) ||
                        preinscripcion
                     "
                     :sm6="
                        (selectBeneficiario == 5 || selectBeneficiario == 6) &&
                        !preinscripcion
                     "
                  >
                     <v-autocomplete
                        v-model="carreraCodigoById"
                        label="Carrera universitaria (Que estudia o va a estudiar)"
                        :items="cmbCarreraUniversitaria"
                        :rules="rulesCarreraUniversitaria"
                        item-text="nombre"
                        item-value="codigo"
                        :readonly="
                           !preinscripcion ||
                           selectBeneficiario == 1 ||
                           selectBeneficiario == 2
                        "
                     ></v-autocomplete>
                  </v-flex>

                  <v-flex
                     v-if="!preinscripcion && selectBeneficiario == 5"
                     xs12
                     mt-8
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Campos adicionales de
                           <span class="colorPrimary"> sacerdote </span>
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Por favor ingrese el nombre de la parroquia a la que
                           usted pertenece
                        </span>
                     </p>
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-text-field
                              v-model="congregacion"
                              :readonly="completada"
                              :rules="rulesCongregacion"
                              label="Parroquia a la que pertenece"
                              color="primary"
                           >
                           </v-text-field>
                        </v-flex>
                     </v-layout>
                  </v-flex>

                  <v-flex
                     v-if="!preinscripcion && selectBeneficiario == 6"
                     xs12
                     mt-8
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Campos adicionales de
                           <span class="colorPrimary"> religiosas </span>
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Por favor ingrese el nombre de la congregación a la
                           que usted pertenece
                        </span>
                     </p>
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-text-field
                              v-model="congregacion"
                              :readonly="completada"
                              :rules="rulesCongregacion"
                              label="Congregación a la que pertenece"
                              color="primary"
                           >
                           </v-text-field>
                        </v-flex>
                     </v-layout>
                  </v-flex>

                  <v-flex
                     v-if="!preinscripcion && selectBeneficiario == 3"
                     xs12
                     mt-8
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Campos adicionales de
                           <span class="colorPrimary"> empleado UNICAES </span>
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Por favor ingrese la unidad de trabajo a la que usted
                           pertenece
                        </span>
                     </p>
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-text-field
                              v-model="empleadoUnidadDeTrabajo"
                              :readonly="completada"
                              :rules="rulesEmpleadoUnidad"
                              label="Unidad de trabajo de empleado"
                              color="primary"
                           >
                           </v-text-field>
                        </v-flex>
                     </v-layout>
                  </v-flex>

                  <v-flex
                     v-if="!preinscripcion && selectBeneficiario == 7"
                     xs12
                     mt-8
                  >
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Campos adicionales de
                           <span class="colorPrimary"> hijo de empleado </span>
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Por favor ingrese el nombre completo y la unidad de
                           trabajo del empleado UNICAES
                        </span>
                     </p>
                     <v-layout wrap>
                        <v-flex xs12 sm4>
                           <v-text-field
                              v-model="empleadoNombre"
                              :readonly="completada"
                              :rules="rulesEmpleadoNombre"
                              label="Nombre de empleado"
                              class="upper"
                              @keyup="uppercase"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                           <v-text-field
                              v-model="empleadoUnidadDeTrabajo"
                              :readonly="completada"
                              :rules="rulesEmpleadoUnidad"
                              label="Unidad de trabajo de empleado"
                              color="primary"
                           >
                           </v-text-field>
                        </v-flex>
                        <v-flex xs12 style="margin-top: 50px">
                           <p>
                              <span
                                 class="font-weight-medium"
                                 style="font-size: 20px"
                              >
                                 ¿Cuenta con hermanos(as) que posean una beca
                                 actualmente en UNICAES?
                              </span>
                              <br />
                              <span
                                 class="font-weight-medium"
                                 style="font-size: 16px"
                              >
                                 Si la respuesta es afirmativa, por favor
                                 escriba el número de carnet y nombre de ellos
                              </span>
                           </p>
                           <v-radio-group
                              v-model="radioHermanosBeca"
                              :readonly="completada"
                              row
                           >
                              <v-radio
                                 label="No"
                                 value="no"
                                 color="primary"
                              ></v-radio>
                              <v-radio
                                 label="Si"
                                 value="si"
                                 color="primary"
                              ></v-radio>
                              <v-textarea
                                 v-model="hermanosBeca"
                                 :readonly="completada"
                                 :disabled="radioHermanosBeca != 'si'"
                                 outlined
                                 class="upper"
                                 :rules="
                                    radioHermanosBeca == 'si'
                                       ? rulesHermanosBeca
                                       : []
                                 "
                                 label="Detalles"
                                 @keyup="uppercase"
                              ></v-textarea>
                           </v-radio-group>
                        </v-flex>
                     </v-layout>
                  </v-flex>

                  <v-flex v-if="!preinscripcion" xs12 mt-8>
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           ¿Quien costea sus estudios?
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Si escogió otro, por favor escriba quién costea sus
                           estudios
                        </span>
                     </p>
                     <v-radio-group
                        v-model="radioCosteaEstudios"
                        :readonly="completada"
                        row
                     >
                        <v-radio
                           label="Yo"
                           value="yo"
                           color="primary"
                        ></v-radio>
                        <v-radio
                           label="Otro"
                           value="otro"
                           color="primary"
                        ></v-radio>
                        <v-text-field
                           v-model="costeaEstudiosOtro"
                           :disabled="radioCosteaEstudios != 'otro'"
                           :readonly="completada"
                           class="upper"
                           :rules="
                              radioCosteaEstudios == 'otro'
                                 ? rulesCosteaEstudioOtro
                                 : []
                           "
                           label="Nombre"
                           @keyup="uppercase"
                        ></v-text-field>
                        <v-autocomplete
                           v-model="selectCosteaEstudioParentesco"
                           :readonly="completada"
                           label="Parentesco"
                           :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
                           :items="cmbParentesco"
                           item-text="nombre"
                           item-value="parentescoId"
                           :disabled="radioCosteaEstudios != 'otro'"
                           :rules="
                              radioCosteaEstudios == 'otro'
                                 ? rulesCosteaEstudioParentesco
                                 : []
                           "
                        ></v-autocomplete>
                     </v-radio-group>
                  </v-flex>

                  <v-flex v-if="!preinscripcion" xs12>
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           ¿Ha contado con beca alguna vez?
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Si la respuesta es afirmativa, por favor escriba
                           quién fue el patrocinador de su antigua beca
                        </span>
                     </p>
                     <v-radio-group
                        v-model="radioHaTenidoBeca"
                        :readonly="completada"
                        row
                     >
                        <v-radio
                           label="No"
                           value="no"
                           color="primary"
                        ></v-radio>
                        <v-radio
                           label="Si"
                           value="si"
                           color="primary"
                        ></v-radio>
                        <v-text-field
                           v-model="origenBecaAnterior"
                           :readonly="completada"
                           :disabled="radioHaTenidoBeca != 'si'"
                           class="upper"
                           :rules="
                              radioHaTenidoBeca == 'si' ? rulesHaTenidoBeca : []
                           "
                           label="Nombre"
                           @keyup="uppercase"
                        ></v-text-field>
                     </v-radio-group>
                  </v-flex>

                  <v-flex v-if="!preinscripcion" xs12>
                     <p>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           ¿Posee disponibilidad para colaborar con la
                           Universidad en caso se le solicite?
                        </span>
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           Si escogió No, por favor escriba la razón
                        </span>
                     </p>
                     <v-radio-group
                        v-model="radioColaborar"
                        :readonly="completada"
                        row
                     >
                        <v-radio
                           label="Si"
                           :value="true"
                           color="primary"
                        ></v-radio>
                        <v-radio
                           label="No"
                           :value="false"
                           color="primary"
                        ></v-radio>
                     </v-radio-group>
                     <v-textarea
                        v-if="!radioColaborar"
                        v-model="justificacionColaborador"
                        :readonly="completada"
                        outlined
                        rows="7"
                        label="¿Por qué?"
                        color="primary"
                        @keyup="countWords"
                     ></v-textarea>
                  </v-flex>
                  <v-flex
                     v-if="!radioColaborar && !completada"
                     xs12
                     class="d-flex justify-center mb-8"
                  >
                     <span class="colorPrimary text-h5 font-weight-medium"
                        >Cantidad de palabras: {{ numPalabras }}</span
                     >
                  </v-flex>

                  <v-flex v-if="!preinscripcion" xs12>
                     <p class="text-justify">
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Consentimiento
                        </span>
                        <br />
                        <br />
                        <span
                           class="font-weight-medium"
                           style="font-size: 16px"
                        >
                           El proceso puede requerir una entrevista o visita
                           domiciliar. Con la información expuesta en este
                           formulario, autorizo a la Universidad Católica de El
                           Salvador para que pueda comprobar la veracidad de
                           ésta; a la vez me doy por enterado que: al entregar
                           los documentos requeridos se seguirá el proceso
                           establecido por la Universidad, por ende, al
                           presentar la documentación no se me otorga
                           automáticamente la beca.
                           <br /><br />
                           Marque la siguiente casilla dando fe de que ha leído
                           lo anterior y está de acuerdo.
                        </span>
                     </p>
                     <v-checkbox
                        v-model="acepto"
                        :readonly="completada || completada == true"
                        label="Acepto los términos y condiciones"
                        color="primary"
                        style="margin-left: 5px"
                        :rules="oidcUser ? [] : rulesAcepto"
                     ></v-checkbox>
                  </v-flex>
               </v-layout>
            </v-container>
         </v-form>
      </v-flex>

      <!-- BOTONES DE NAVEGACION -->
      <v-flex
         xs12
         mt-8
         mb-4
         :class="
            $vuetify.breakpoint.smAndUp
               ? 'd-flex justify-center'
               : 'd-flex flex-column-reverse justify-center'
         "
      >
         <v-btn
            v-if="solicitudBecaId == null"
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="completada && !preinscripcion"
            color="grey lighten-3"
            @click="cancelarDatosGenerales"
         >
            Cancelar
         </v-btn>
         <v-btn
            v-if="solicitudBecaId != null || preinscripcion"
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-3'"
            color="primary"
            :disabled="!validDatosGenerales || loadingGenerar"
            :loading="loadingGenerar"
            large
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            @click="nextStepDatosGenerales"
         >
            {{ preinscripcion ? 'Generar solicitud' : 'Continuar' }}
         </v-btn>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import InputNumber from '../components/custom/InputNumber'
const RecordAlu = () => import('./RecordAlu')
const Pensum = () => import('./Pensum.vue')
const HistorialBecas = () => import('./custom/BecasAplicadas.vue')
const CustomDatePicker = () => import('./custom/CustomDatePicker')

export default {
   name: 'FormBecasDatosGenerales',
   directives: {
      mask
   },
   components: {
      CustomDatePicker: CustomDatePicker,
      'v-input-number': InputNumber,
      RecordAlu: RecordAlu,
      Pensum: Pensum,
      HistorialBecas: HistorialBecas
   },
   mixins: [formatters],
   props: {
      carnetProp: {
         type: String,
         default: ''
      },
      solicitudInfoProp: {
         type: Object,
         default: null
      },
      detalleRequisitos: {
         type: Object,
         default: null
      },
      beneficiarioId: {
         type: Number,
         default: null
      },
      bienestarUniversitarioProp: {
         type: Boolean
      },
      preinscripcion: {
         type: Boolean,
         default: false
      }
   },
   data: (vm) => ({
      materiasAprobadas: 0,
      totalMaterias: 0,
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      fechaSolicitud: vm.toISOLocal(new Date()).substr(0, 10),
      dialogMensaje: false,
      loadingGenerar: false,
      dialogEnviando: false,
      dialogCambiarEmail: false,
      nuevoEmail: '',
      validCambiarEmail: true,
      loadingCambiandoCorreo: false,
      dialogNotas: false,
      dialogPensum: false,
      dialogHistorialBecas: false,
      estadoNombre: '',
      selectPrioridad: 0,
      ignoreNextEstado: false,
      accessCode: '',
      cargaInicial: false,

      // Variables de formulario
      cmbPrioridad: [
         {
            id: 0,
            nombre: 'Sin prioridad'
         },
         {
            id: 1,
            nombre: 'Segunda opción'
         },
         {
            id: 2,
            nombre: 'Candidato'
         }
      ],
      cmbEstadoCaso: [],
      cmbEstadoCasoFiltered: [],
      cmbDepartamentos: [],
      cmbMunicipios: [],
      filteredMunicipios: [],
      selectedDepartamento: null,
      selectedMunicipio: null,
      cmbAsesores: [],
      cmbInsAuspiciadora: [],
      selectAuspiciador: '',
      selectAsesor: '',
      tieneFocus: false,
      selectEstadoCaso: !vm.preinscripcion ? null : 2,
      dui: '',
      carnet: '',
      alumnoInfo: [],
      nombres: '',
      apellidos: '',
      validDatosGenerales: true,
      fechaNacimiento: null,
      fechaGraduacion: null,
      cmbEstadoCivil: [],
      categoriaDeBeca: null,
      selectEstadoCivil: 1,
      cmbNivelAcademico: [],
      selectNivelAcademico: 1,
      cantHijos: 0,
      cmbCarreraUniversitaria: [],
      selectCarreraUniversitaria: '',
      carreraUniversitariaId: null,
      cmbBeneficiarios: [],
      selectBeneficiario: '',
      direccion: '',
      email: '',
      telefonoPrincipal: '',
      telefonoSecundario: '',
      radioCosteaEstudios: 'yo',
      radioColaborar: true,
      justificacionColaborador: '',
      numPalabras: 0,
      costeaEstudiosOtro: '',
      radioHaTenidoBeca: 'no',
      radioHermanosBeca: 'no',
      hermanosBeca: '',
      origenBecaAnterior: '',
      acepto: false,
      cicloActual: '',
      cicloDeAplicacion: '',
      cmbParentesco: [],
      selectCosteaEstudioParentesco: null,
      solicitudBecaId: null,
      solicitudBecaRef: '',
      solicitudInfo: null,
      comentarioAsesor: '',
      comentarioAdminBecas: '',
      empleadoNombre: '',
      empleadoUnidadDeTrabajo: '',
      completada: true,
      selectTipoBeca: null,
      cmbTipoBeca: [],
      congregacion: null,
      profilePicturePath: '/assets/default-male.png',
      dialogFotografia: false,
      validFotografia: true,
      archivosAdjuntos: [],
      reaplicacion: false,
      bienestarUniversitario: false,
      notificado: false,
      bloqueado: false,
      noCumple: false,
      irregular: false,
      procesoDeGraduacion: false,

      // Reglas de validacion
      rulesCarnet: [
         (v) => !!v || 'Ingresa tu carnet',
         (v) => (v && v.length == 11) || 'Termina de escribir tu carnet'
      ],

      rulesNombre: [(v) => !!v || 'Ingrese sus nombres'],
      rulesApellido: [(v) => !!v || 'Ingrese sus apellidos'],
      rulesDireccion: [(v) => !!v || 'Ingrese una dirección'],
      rulesCongregacion: [(v) => !!v || 'Ingrese un nombre'],
      rulesEstadoCivil: [(v) => !!v || 'Selecciona un estado civil'],
      rulesNivelAcademico: [(v) => !!v || 'Selecciona un estado académico'],
      rulesEmpleadoNombre: [(v) => !!v || 'Ingrese un nombre completo'],
      rulesEmpleadoUnidad: [
         (v) => !!v || 'Ingrese la unidad de trabajo del empleado'
      ],
      rulesFechaNacimiento: [(v) => !!v || 'Ingrese su fecha de nacimiento'],
      rulesTelefonoPrincipal: [
         (v) => !!v || 'Ingresa un número de teléfono principal',
         (v) =>
            (v && v.length == 9) || 'Termina de escribir tu número de teléfono'
      ],
      rulesEmail: [
         (v) => !!v || 'Ingrese un correo institucional',
         (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
               v
            ) ||
            'Formato de correo incorrecto',
         (v) =>
            v === '' ||
            /@catolica.edu.sv$/.test(v) ||
            'El correo debe terminar en @catolica.edu.sv'
      ],
      rulesCarreraUniversitaria: [(v) => !!v || 'Seleccione una carrera'],
      rulesDepartamento: [(v) => !!v || 'Seleccione un Departamento'],
      rulesMunicipio: [(v) => !!v || 'Seleccione un Municipio'],
      rulesCosteaEstudioOtro: [(v) => !!v || 'Ingrese un nombre'],
      rulesCosteaEstudioParentesco: [(v) => !!v || 'Seleccione un parentesco'],
      rulesHaTenidoBeca: [(v) => !!v || 'Ingrese un nombre'],
      rulesHermanosBeca: [(v) => !!v || 'Ingrese la información solicitada'],
      rulesAcepto: [
         (v) =>
            !!v ||
            'Debes aceptar los términos y condiciones de la solicitud de beca'
      ],
      rulesFotografia: [
         (files) => files.length > 0 || 'Seleccione un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'image/png') ||
            !files.some((file) => file.type != 'image/jpeg') ||
            !files.some((file) => file.type != 'image/bmp') ||
            'Solo se permiten archivos de imágenes',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'La imagen no puede pesar más de 15MB!'
      ]
   }),
   computed: {
      ...authComputed,
      tipoBecaDescripcion() {
         if (
            this.cmbTipoBeca.length > 0 &&
            this.selectTipoBeca != null &&
            this.selectTipoBeca != 1
         ) {
            return (
               ', ' +
               this.cmbTipoBeca.find((obj) => {
                  return obj.tipoBecaId == this.selectTipoBeca
               }).descripcion
            )
         } else {
            return ''
         }
      },
      carreraCodigoById: {
         get() {
            if (this.preinscripcion) {
               return this.selectCarreraUniversitaria
            } else {
               if (
                  this.cmbCarreraUniversitaria.length > 0 &&
                  this.carreraUniversitariaId != null
               ) {
                  var codigo = this.cmbCarreraUniversitaria.find((obj) => {
                     return (
                        obj.carreraUniversitariaId ==
                        this.carreraUniversitariaId
                     )
                  }).codigo
                  this.selectCarreraUniversitaria = codigo
                  return codigo
               } else {
                  return ''
               }
            }
         },
         set: (val) => {
            this.selectCarreraUniversitaria = val
         }
      },
      checkInsAuspiciadora() {
         if (this.oidcUser !== null) {
            return this.oidcUser.role === 'admin' && this.categoriaDeBeca === 3
               ? true
               : false
         } else {
            return false
         }
      },
      tipoBeneficioNombre() {
         var bene = this.cmbBeneficiarios.find(
            (obj) => obj.beneficiarioId == this.selectBeneficiario
         )
         if (bene != undefined) {
            if (bene.tipoBeneficioId == 1) {
               return 'Administrativa'
            } else if (bene.tipoBeneficioId == 2) {
               return 'Servicio Estudiantil'
            } else {
               return 'Auspiciado'
            }
         } else {
            return ''
         }
      },
      filteredCarreraUniversitaria() {
         // Deprecated
         return this.cmbCarreraUniversitaria.filter((i) => {
            if (this.selectBeneficiario == 4 && this.preinscripcion) {
               return i.nombre.toLowerCase().includes('semipresencial')
            } else {
               return true
            }
         })
      }
   },
   watch: {
      selectedDepartamento(val) {
         this.filteredMunicipios = this.cmbMunicipios.filter(
            (municipio) => municipio.departamentoId == val
         )
      },
      selectBeneficiario(val) {
         if (this.solicitudBecaId != null && !this.cargaInicial) {
            axios
               .put(
                  'api/SolicitudDeBeca/TipoBeneficiario/' +
                     this.solicitudBecaId,
                  {
                     beneficiarioId: val
                  }
               )
               .then(() => {
                  this.snackText =
                     'Tipo de beneficiario actualizado. Refrescando pestaña.'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  setTimeout(() => {
                     location.reload()
                  }, 1500)
               })
               .catch((error) => {
                  if (error.response.status != 304) {
                     this.snackText =
                        'No se pudo actualizar el tipo beneficiario.'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                  }
               })
         }
      },
      selectAsesor(val) {
         if (val !== '') {
            axios
               .put(
                  '/api/SolicitudDeBeca/ReasignarAsesor/' +
                     this.solicitudBecaId,
                  {
                     id: val
                  }
               )
               .then(() => {
                  this.snackText = 'Asesor actualizado!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
               })
               .catch((error) => {
                  if (error.response.status !== 304) {
                     this.snackText = 'No se pudo actualizar el asesor'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                  }
               })
         }
      },
      selectAuspiciador(val) {
         if (val != null) {
            axios
               .put(
                  '/api/SolicitudDeBeca/InstitucionPatrocinadora/' +
                     this.solicitudInfoProp.solicitudBecaId,
                  { institucionPatrocinadoraId: val }
               )
               .then(() => {
                  this.snackText = 'Institución Auspiciada actualizada!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
               })
               .catch((error) => {
                  if (error.response.status !== 304) {
                     this.snackText =
                        'No se pudo actualizar la Institución Auspiciada'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                     console.log(error)
                  }
               })
         }
      },
      selectPrioridad(val) {
         if (this.solicitudBecaId != null && !this.cargaInicial) {
            axios
               .put('api/SolicitudDeBeca/Prioridad/' + this.solicitudBecaId, {
                  prioridad: val
               })
               .then(() => {
                  this.snackText = 'Prioridad actualizada.'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
               })
               .catch((error) => {
                  if (error.response.status != 304) {
                     this.snackText = 'No se pudo actualizar la prioridad.'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                  }
               })
         }
      },
      bloqueado(val) {
         this.completada = val
      },
      selectTipoBeca(val, oldValue) {
         if (val != oldValue && oldValue) {
            axios
               .put('api/SolicitudDeBeca/tipobeca/' + this.solicitudBecaId, {
                  tipoBecaId: val
               })
               .then(() => {
                  this.snackText = 'Tipo de beca actualizado.'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
               })
               .catch((error) => {
                  if (error.response.status != 304) {
                     this.snackText = 'No se pudo actualizar el tipo de beca.'
                     this.snackColor = 'snackError'
                     this.snackbar = true

                     console.log(error)
                  }
               })
         }
      },
      selectEstadoCaso(val, oldVal) {
         if (this.ignoreNextEstado) {
            this.ignoreNextEstado = false
         } else {
            if (oldVal != null) {
               if (
                  this.checkIsAsesor() &&
                  (oldVal == 3 ||
                     oldVal == 4 ||
                     oldVal == 5 ||
                     oldVal == 9 ||
                     oldVal == 10)
               ) {
                  // Si es asesor e intenta cambiar un estado que se encuentre: Aprobado, Denegado, Anulado, Revisado, Aprobado por Consejo
                  this.snackText =
                     'No esta autorizado para realizar esta acción.'
                  this.snackColor = 'snackWarning'
                  this.snackbar = true
                  this.ignoreNextEstado = true
                  setTimeout(() => {
                     this.selectEstadoCaso = oldVal
                  }, 200)
               } else if (
                  this.checkRangoCero() &&
                  (oldVal == 3 || oldVal == 4 || (oldVal == 10 && val != 3))
               ) {
                  // Si es nivel 0 e intenta cambiar un estado que se encuentre: Aprobado, Denegado, Aprobado por Consejo
                  this.snackText =
                     'No esta autorizado para realizar esta acción.'
                  this.snackColor = 'snackWarning'
                  this.snackbar = true
                  this.ignoreNextEstado = true
                  setTimeout(() => {
                     this.selectEstadoCaso = oldVal
                  }, 200)
               } else if (this.checkRangoCero() && val == 3 && oldVal != 10) {
                  // Si es nivel 0 e intenta aprobar una solicitud que no este en aprobado por consejo
                  this.snackText =
                     'Solo puede aprobar solicitudes aprobadas por consejo.'
                  this.snackColor = 'snackWarning'
                  this.snackbar = true
                  this.ignoreNextEstado = true
                  setTimeout(() => {
                     this.selectEstadoCaso = oldVal
                  }, 200)
               } else if ((val == 3 || val == 10) && this.selectTipoBeca == 1) {
                  // Si se intenta aprobar sin haber seleccionado un tipo de beca
                  this.snackText =
                     'Seleccione un tipo de beca para poder aprobar.'
                  this.snackColor = 'snackWarning'
                  this.snackbar = true
                  this.ignoreNextEstado = true
                  setTimeout(() => {
                     this.selectEstadoCaso = oldVal
                  }, 200)
               } else {
                  // Actualizar estado
                  axios
                     .put(
                        'api/SolicitudDeBeca/estado/' + this.solicitudBecaId,
                        {
                           estadoId: val
                        }
                     )
                     .then(() => {
                        this.snackText = 'Estado actualizado.'
                        this.snackColor = 'snackSuccess'
                        this.snackbar = true
                     })
                     .catch((error) => {
                        if (error.response.status != 400) {
                           this.snackText = 'No se pudo actualizar el estado.'
                           this.snackColor = 'snackError'
                           this.snackbar = true
                           this.ignoreNextEstado = true
                           setTimeout(() => {
                              this.selectEstadoCaso = oldVal
                           }, 200)
                        }
                     })
               }
            }
         }
      },
      radioHaTenidoBeca(val) {
         if (val == 'no') {
            this.origenBecaAnterior = ''
         }
      },
      radioHermanosBeca(val) {
         if (val == 'no') {
            this.hermanosBeca = ''
         }
      },
      radioCosteaEstudios(val) {
         if (val == 'yo') {
            this.costeaEstudiosOtro = ''
            this.selectCosteaEstudioParentesco = null
         }
      },
      carnetProp(val) {
         this.carnet = val
      },
      solicitudInfoProp(val) {
         if (val != null) {
            this.cargaInicial = true
            this.solicitudInfo = val
            this.selectAsesor = val.asesorId
            this.solicitudBecaId = val.solicitudBecaId
            this.selectBeneficiario = val.beneficiarioId
            this.cicloDeAplicacion = val.cicloDeAplicacion
            this.selectEstadoCaso = val.estadoCasoId
            this.estadoNombre = val.estadoCaso
            this.radioColaborar = val.esColaborador
            this.justificacionColaborador = val.justificacionColaborador
            this.carnet = val.carnet
            this.nombres = val.nombres
            this.apellidos = val.apellidos
            this.telefonoPrincipal = val.telefonoPrincipal
            this.telefonoSecundario = val.telefonoSecundario
            this.email = val.email
            this.fechaNacimiento = this.parseDate(val.fechaDeNacimiento)
            this.fechaGraduacion = this.parseDate(val.fechaDeGraduacion)
            this.selectEstadoCivil = val.estadoCivilId
            this.selectNivelAcademico = val.nivelAcademicoId
            this.direccion = val.direccion
            this.cantHijos = val.hijos
            this.categoriaDeBeca = val.tipoBeneficioId
            this.selectAuspiciador = val.institucionPatrocinadoraId
            this.carreraUniversitariaId = val.carreraUniversitariaId
            this.radioCosteaEstudios = val.patrocinador == null ? 'yo' : 'otro'
            this.costeaEstudiosOtro = val.patrocinador
            this.selectCosteaEstudioParentesco = val.patrocinadorParentescoId
            this.radioHaTenidoBeca = val.hasTenidoBeca ? 'si' : 'no'
            this.origenBecaAnterior = val.origenDeBecaAnterior
            this.radioHermanosBeca = val.hermanosConBeca ? 'si' : 'no'
            this.hermanosBeca = val.hermanosConBeca
            this.empleadoNombre = val.empleado
            this.empleadoUnidadDeTrabajo = val.empleadoUnidadDeTrabajo
            this.comentarioAsesor = val.comentarioAsesor
            this.comentarioAdminBecas = val.comentarioAdministrador
            this.congregacion = val.congregacion
            this.profilePicturePath =
               val.profilePicturePath == null || val.profilePicturePath == ''
                  ? '/assets/default-male.png'
                  : val.profilePicturePath
            this.selectTipoBeca = val.tipoBecaId == null ? 1 : val.tipoBecaId
            this.completada = val.completada
            this.reaplicacion = val.reaplicacion
            this.bienestarUniversitario = val.bienestarUniversitario
            this.notificado = val.notificado
            this.bloqueado = val.bloqueada
            this.noCumple = val.noCumple
            this.selectPrioridad = val.prioridad
            this.irregular = val.irregularmenteAcademico
            this.procesoDeGraduacion = val.procesoDeGraduacion
            if (val.completada) {
               this.acepto = true
            }
            this.$refs.formDatosGenerales.resetValidation()
            this.consultarPensum(val.carnet)
            this.generarEnlaces()

            axios
               .get('api/Region/municipio')
               .then((res) => (this.cmbMunicipios = res.data))
               .catch((err) => console.log(err))
               .finally(() => {
                  this.selectedDepartamento = this.cmbMunicipios.filter(
                     (m) => m.municipioId == val.municipioId
                  )[0].departamentoId
                  this.selectedMunicipio = val.municipioId
               })

            setTimeout(() => {
               this.cargaInicial = false
            }, 100)
         }
      },
      beneficiarioId(val) {
         this.selectBeneficiario = val
      },
      carnet(val) {
         if (this.solicitudInfo == null) {
            if (val.length == 11) {
               axios
                  .get('api/FormularioPlanPago/AlumnoInfo?carnet=' + val)
                  .then((response) => {
                     if (response.data.length > 0) {
                        this.alumnoInfo = response.data[0]
                        this.nombres =
                           `${this.alumnoInfo.primerNombre.trim()} ${this.alumnoInfo.segundoNombre.trim()}`.trim()
                        this.apellidos =
                           `${this.alumnoInfo.primerApellido.trim()} ${this.alumnoInfo.segundoApellido.trim()} ${this.alumnoInfo.tercerApellido.trim()}`.trim()
                        if (
                           this.alumnoInfo.carreraCodigo.toString().trim()
                              .length == 4
                        ) {
                           this.selectCarreraUniversitaria =
                              this.alumnoInfo.carreraCodigo
                                 .toString()
                                 .trim()
                                 .substr(1, 3)
                        } else {
                           this.selectCarreraUniversitaria =
                              this.alumnoInfo.carreraCodigo.toString().trim()
                        }
                     }
                  })
                  .catch(() => {
                     //console.log(error)
                  })
            } else {
               this.nombres = ''
               this.apellidos = ''
               this.selectCarreraUniversitaria = ''
               this.$refs.formDatosGenerales.resetValidation()
            }
         }
      }
   },
   created() {
      this.listarComboBoxes()
   },
   methods: {
      countWords() {
         var str = this.justificacionColaborador
         str = str.replace(/(^\s*)|(\s*$)/gi, '')
         str = str.replace(/[ ]{2,}/gi, ' ')
         str = str.replace(/\n /, '\n')
         if (str == '') {
            this.numPalabras = 0
         } else {
            this.numPalabras = str.split(' ').length
         }
      },
      consultarPensum(val) {
         axios
            .get('api/SolicitudDeBeca/Pensum?carnet=' + val)
            .then((response) => {
               this.materiasAprobadas = response.data.materiasAprobadas
               this.totalMaterias = response.data.totalMaterias
            })
            .catch((error) => {
               console.log(error)
            })
      },
      irColecturiaDigital() {
         window.open(
            process.env.VUE_APP_CLIENTE +
               'colecturiaDigital?carnet=' +
               this.carnet +
               '&accesscode=' +
               this.accessCode,
            '_blank'
         )
      },
      generarEnlaces() {
         this.accessCode = ''
         if (this.carnet.trim() != '') {
            axios
               .get('api/Alumno/Credenciales?carnet=' + this.carnet)
               .then((response) => {
                  if (response.data[0] !== undefined) {
                     var res = response.data[0]
                     this.accessCode = res.accessCode
                  }
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },
      filterEstadosBlur() {
         this.tieneFocus = false
         setTimeout(() => {
            if (!this.tieneFocus) {
               this.cmbEstadoCasoFiltered = this.cmbEstadoCaso
            }
         }, 500)
      },
      filterEstadosFocus() {
         this.tieneFocus = true
         if (this.oidcUser.nivelasesor == 1) {
            this.cmbEstadoCasoFiltered = this.cmbEstadoCaso.filter((obj) => {
               return (
                  obj.estadoCasoId == this.selectEstadoCaso ||
                  obj.estadoCasoId == 1 ||
                  obj.estadoCasoId == 2 ||
                  obj.estadoCasoId == 8
               )
            })
         } else if (
            this.oidcUser.nivelasesor == 0 &&
            this.oidcUser.role != 'admin'
         ) {
            this.cmbEstadoCasoFiltered = this.cmbEstadoCaso.filter((obj) => {
               return (
                  obj.estadoCasoId == this.selectEstadoCaso ||
                  obj.estadoCasoId == 1 ||
                  obj.estadoCasoId == 2 ||
                  obj.estadoCasoId == 3 ||
                  obj.estadoCasoId == 5 ||
                  obj.estadoCasoId == 8 ||
                  obj.estadoCasoId == 9
               )
            })
         } else if (
            this.oidcUser.nivelasesor == 0 &&
            this.oidcUser.role == 'admin'
         ) {
            this.cmbEstadoCasoFiltered = this.cmbEstadoCaso
         }
      },
      actualizarEmail() {
         if (this.$refs.formCambiarEmail.validate()) {
            // Actualizar
            this.loadingCambiandoCorreo = true
            axios
               .put(
                  'api/SolicitudDeBeca/RegenarNumeroRef/' +
                     this.solicitudBecaId,
                  { email: this.nuevoEmail }
               )
               .then(() => {
                  this.snackText = 'Correo actualizado'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  setTimeout(() => {
                     location.reload()
                  }, 2000)
               })
               .catch(() => {
                  this.snackText = 'Algo salió mal'
                  this.snackColor = 'snackError'
                  this.snackbar = true
                  this.loadingCambiandoCorreo = false
               })
         }
      },
      cancelarCambiarEmail() {
         this.dialogCambiarEmail = false
         this.nuevoEmail = ''
         this.$refs.formCambiarEmail.resetValidation()
      },
      showCambiarEmail() {
         this.dialogCambiarEmail = true
         this.nuevoEmail = ''

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      generarMensajeRequisitos() {
         var mensaje = ''
         if (this.noCumple) {
            mensaje = mensaje.concat('No cumplió con los requisitos de la beca')
         }
         if (this.irregular) {
            if (mensaje.length > 0) {
               mensaje = mensaje.concat(
                  ', se le detectaron irregularidades en el historial académico'
               )
            } else {
               mensaje = mensaje.concat(
                  'Se le detectaron irregularidades en el historial académico'
               )
            }
         }
         if (this.procesoDeGraduacion) {
            if (mensaje.length > 0) {
               mensaje = mensaje.concat(
                  ', el alumno se encontraba en calidad de egresado.'
               )
            } else {
               mensaje = mensaje.concat(
                  'El alumno se encontraba en calidad de egresado'
               )
            }
         }
         mensaje = mensaje.concat('.')
         return mensaje.trim()
      },
      alternarBloqueado() {
         axios
            .put('api/SolicitudDeBeca/Bloqueo/' + this.solicitudBecaId, {
               bloqueo: !this.bloqueado
            })
            .then(() => {
               this.snackText = 'Estado de bloqueo actualizado'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.bloqueado = !this.bloqueado
            })
            .catch(() => {
               // Do nothing
            })
      },
      alternarReaplicacion() {
         axios
            .put('api/SolicitudDeBeca/Reapliacion/' + this.solicitudBecaId, {
               reaplicacion: !this.reaplicacion
            })
            .then(() => {
               this.snackText = 'Estado de reaplicación actualizado'
               this.snackColor = 'snackSuccess '
               this.snackbar = true
               this.reaplicacion = !this.reaplicacion
            })
            .catch(() => {
               // Do nothing
            })
      },
      alternarBienestar() {
         axios
            .put(
               'api/SolicitudDeBeca/BienestarUniversitario/' +
                  this.solicitudBecaId,
               {
                  bienestarUniversitario: !this.bienestarUniversitario
               }
            )
            .then(() => {
               this.snackText = 'Estado de bienestar universitario actualizado'
               this.snackColor = 'snackSuccess '
               this.snackbar = true
               this.bienestarUniversitario = !this.bienestarUniversitario
            })
            .catch(() => {
               // Do nothing
            })
      },
      verFotografia(ruta) {
         if (!ruta.includes('default-male')) {
            window.open(ruta, null)
         }
      },
      subirFotografia() {
         if (this.$refs.formFotografia.validate()) {
            this.dialogEnviando = true
            let formData = new FormData()
            for (let file of this.archivosAdjuntos) {
               formData.append('file', file, file.name)
            }
            axios
               .put(
                  'api/SolicitudDeBeca/ProfilePicture/' + this.solicitudBecaId,
                  formData
               )
               .then((response) => {
                  this.profilePicturePath = response.data.profilePicturePath
                  this.closeFotografia()
                  this.snackText = 'Fotografia actualizada correctamente!'
                  this.snackColor = 'snackSuccess'
                  this.dialogEnviando = false
               })
               .catch((error) => {
                  if (error.response.status != 304) {
                     this.snackText = 'No se pudo subir la imagen'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                  }
                  this.closeFotografia()
                  this.dialogEnviando = false
               })
         }
      },
      closeFotografia() {
         this.$refs.formFotografia.resetValidation()
         this.dialogFotografia = false
         this.archivosAdjuntos = []
      },
      uppercase() {
         this.costeaEstudiosOtro = this.costeaEstudiosOtro.toUpperCase()
         this.origenBecaAnterior = this.origenBecaAnterior.toUpperCase()
         this.nombres = this.nombres.toUpperCase()
         this.apellidos = this.apellidos.toUpperCase()
         this.empleadoNombre = this.empleadoNombre.toUpperCase()
      },
      entendidoNextStep() {
         this.dialogMensaje = false
         location.reload()
      },
      nextStepDatosGenerales() {
         if (this.$refs.formDatosGenerales.validate()) {
            if (
               (this.profilePicturePath.includes('default-male') ||
                  this.profilePicturePath == null ||
                  this.profilePicturePath == '') &&
               !this.preinscripcion
            ) {
               this.snackText = 'Debe adjuntar una fotografía'
               this.snackColor = 'snackWarning'
               this.snackbar = true
               this.$vuetify.goTo(0)
            } else {
               this.loadingGenerar = true
               var carreraId = this.cmbCarreraUniversitaria.find((obj) => {
                  return obj.codigo === this.selectCarreraUniversitaria
               }).carreraUniversitariaId
               if (this.solicitudBecaId == null) {
                  this.selectEstadoCaso = 2
                  axios
                     .post('api/SolicitudDeBeca', {
                        documentoDeIdentidad: this.dui,
                        carnet: this.carnet,
                        esColaborador: this.radioColaborar,
                        justificacionColaborador: this.radioColaborar
                           ? ''
                           : this.justificacionColaborador,
                        nombres: this.nombres,
                        apellidos: this.apellidos,
                        telefonoPrincipal: this.telefonoPrincipal,
                        telefonoSecundario: this.telefonoSecundario,
                        email: this.email,
                        empleado: this.empleadoNombre,
                        empleadoUnidadDeTrabajo: this.empleadoUnidadDeTrabajo,
                        fechaDeNacimiento: this.fechaNacimiento,
                        fechaDeGraduacion: this.fechaGraduacion,
                        direccion: this.direccion,
                        municipioId: this.selectedMunicipio,
                        estadoCasoId: this.selectEstadoCaso,
                        estadoCivilId: this.selectEstadoCivil,
                        nivelAcademicoId: this.selectNivelAcademico,
                        carreraUniversitariaId: carreraId,
                        cicloDeAplicacion: this.cicloActual,
                        hasTenidoBeca:
                           this.radioHaTenidoBeca == 'si' ? true : false,
                        hijos: this.cantHijos,
                        beneficiarioId: this.selectBeneficiario,
                        comentarioAsesor: this.comentarioAsesor,
                        comentarioAdministrador: this.comentarioAdminBecas,
                        congregacion: this.congregacion,
                        reaplicacion: this.reaplicacion,
                        bienestarUniversitario: this.bienestarUniversitarioProp,
                        irregularmenteAcademico:
                           this.detalleRequisitos.irregular > 0 ? true : false,
                        noCumple:
                           this.detalleRequisitos.reprobadas > 0 ? true : false,
                        procesoDeGraduacion:
                           this.detalleRequisitos.egresado > 0 ? true : false,
                        hermanosConBeca:
                           this.radioHermanosBeca == 'no'
                              ? null
                              : this.hermanosBeca,
                        origenDeBecaAnterior:
                           this.radioHaTenidoBeca == 'no'
                              ? null
                              : this.origenBecaAnterior,
                        patrocinador:
                           this.radioCosteaEstudios == 'yo'
                              ? null
                              : this.costeaEstudiosOtro,
                        patrocinadorParentescoId:
                           this.radioCosteaEstudios == 'yo'
                              ? null
                              : this.selectCosteaEstudioParentesco
                     })
                     .then((response) => {
                        this.solicitudInfo = response.data
                        this.solicitudBecaId = response.data.solicitudBecaId
                        this.solicitudBecaRef = response.data.numeroReferencia
                        this.loadingGenerar = false
                        this.dialogMensaje = true
                        //me.$emit('nextStep', me.solicitudInfo)
                     })
                     .catch((error) => {
                        this.loadingGenerar = false
                        this.snackText = error.response.data
                        this.snackColor = 'snackError'
                        this.snackbar = true
                     })
               } else {
                  axios
                     .put('api/SolicitudDeBeca/' + this.solicitudBecaId, {
                        documentoDeIdentidad: this.dui,
                        carnet: this.carnet,
                        esColaborador: this.radioColaborar,
                        justificacionColaborador: this.radioColaborar
                           ? ''
                           : this.justificacionColaborador,
                        nombres: this.nombres,
                        apellidos: this.apellidos,
                        telefonoPrincipal: this.telefonoPrincipal,
                        telefonoSecundario: this.telefonoSecundario,
                        email: this.email,
                        empleado: this.empleadoNombre,
                        empleadoUnidadDeTrabajo: this.empleadoUnidadDeTrabajo,
                        fechaDeNacimiento: this.fechaNacimiento,
                        fechaDeGraduacion: this.fechaGraduacion,
                        direccion: this.direccion,
                        municipioId: this.selectedMunicipio,
                        estadoCasoId: this.selectEstadoCaso,
                        estadoCivilId: this.selectEstadoCivil,
                        nivelAcademicoId: this.selectNivelAcademico,
                        carreraUniversitariaId: carreraId,
                        cicloDeAplicacion: this.cicloDeAplicacion,
                        hasTenidoBeca:
                           this.radioHaTenidoBeca == 'si' ? true : false,
                        hijos: this.cantHijos,
                        beneficiarioId: this.selectBeneficiario,
                        comentarioAsesor: this.comentarioAsesor,
                        comentarioAdministrador: this.comentarioAdminBecas,
                        congregacion: this.congregacion,
                        reaplicacion: this.reaplicacion,
                        bienestarUniversitario: this.bienestarUniversitario,
                        noCumple: this.noCumple,
                        irregularmenteAcademico: this.irregular,
                        procesoDeGraduacion: this.procesoDeGraduacion,

                        hermanosConBeca:
                           this.radioHermanosBeca == 'no'
                              ? null
                              : this.hermanosBeca,
                        origenDeBecaAnterior:
                           this.radioHaTenidoBeca == 'no'
                              ? null
                              : this.origenBecaAnterior,
                        patrocinador:
                           this.radioCosteaEstudios == 'yo'
                              ? null
                              : this.costeaEstudiosOtro,
                        patrocinadorParentescoId:
                           this.radioCosteaEstudios == 'yo'
                              ? null
                              : this.selectCosteaEstudioParentesco
                     })
                     .then((response) => {
                        this.solicitudInfo = response.data
                     })
                     .catch((error) => {
                        if (error.response.status != 304) {
                           console.log(error)
                        }
                     })
                     .finally(() => {
                        this.loadingGenerar = false
                        this.$emit('nextStep', this.solicitudInfo)
                     })
               }
            }
         } else {
            //Focus a Primer Elemento no Validado
            this.$nextTick(() => {
               //Seleccionando Primer Elemento de MensajeError
               const el = this.$el.querySelector(
                  '.v-messages.error--text:first-of-type'
               )
               if (el) {
                  //Focus a Elemento que Genero el Error
                  el.parentNode.previousSibling.firstChild.childNodes[1].focus()
                  this.$vuetify.goTo(el, { offset: 60 })
               }
               return
            })
         }
      },
      cancelarDatosGenerales() {
         if (this.selectBeneficiario == 1 || this.selectBeneficiario == 2) {
            this.$emit('cancelGoStep', 2.1)
         } else {
            this.$emit('cancelGoStep', 2)
            this.cantHijos = 0
            this.carnet = ''
            this.selectNivelAcademico = 0
            this.selectNivelAcademico = 1
            this.direccion = ''
            this.fechaNacimiento = null
            this.fechaGraduacion = null
            this.email = ''
            this.telefonoPrincipal = ''
            this.telefonoSecundario = ''
            this.$refs.formDatosGenerales.resetValidation()
            this.radioCosteaEstudios = 'yo'
            this.radioHaTenidoBeca = 'no'
            this.acepto = false
         }
      },
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) == 0 ||
               Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      checkIsAsesor() {
         if (this.oidcUser) {
            return this.oidcUser.nivelasesor == 1 ? true : false
         } else {
            return false
         }
      },
      checkIsAdmin() {
         if (this.oidcUser) {
            return this.oidcUser.role == 'admin' ? true : false
         } else {
            return false
         }
      },
      checkModificarAsesor() {
         if (this.oidcUser !== null) {
            return this.oidcUser.role === 'admin' ||
               this.oidcUser.nivelasesor === '0'
               ? false
               : true
         } else {
            return true
         }
      },
      checkRangoCero() {
         if (this.oidcUser) {
            return this.oidcUser.nivelasesor == 0 &&
               this.oidcUser.role != 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      listarComboBoxes() {
         axios
            .get('api/Region/departamento')
            .then((res) => (this.cmbDepartamentos = res.data))
            .catch((err) => console.error(err))

         axios
            .get('api/Region/municipio')
            .then((res) => (this.cmbMunicipios = res.data))
            .catch((err) => console.error(err))

         axios
            .get('api/Configuracion/cicloActual')
            .then((response) => {
               this.cicloActual = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/Parentescos')
            .then((response) => {
               this.cmbParentesco = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/TipoBeca')
            .then((response) => {
               this.cmbTipoBeca = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/EstadoCaso?parabecas=true')
            .then((response) => {
               this.cmbEstadoCaso = response.data
               this.cmbEstadoCasoFiltered = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/solicitudesdebecas/Beneficiario')
            .then((response) => {
               this.cmbBeneficiarios = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/EstadosCiviles')
            .then((response) => {
               this.cmbEstadoCivil = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/NivelAcademicos')
            .then((response) => {
               this.cmbNivelAcademico = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/CarreraUniversitaria')
            .then((response) => {
               this.cmbCarreraUniversitaria = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/InstitucionPatrocinadora')
            .then((response) => {
               this.cmbInsAuspiciadora = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios({
            method: 'get',
            url: 'api/UserManagement/clienteweb?soloAsesores=true',
            baseURL: process.env.VUE_APP_IDENTITY
         })
            .then((response) => {
               this.cmbAsesores = response.data.filter(
                  (asesor) =>
                     asesor.isEnabled == true && asesor.asesorBecas == true
               )
               this.cmbAsesores.push({
                  id: null,
                  userClaims: [{ claimValue: 'Administrador' }]
               })
            })
            .catch((error) => console.log(error))
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      }
   }
}
</script>

<style scoped>
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
.primaryColor {
   color: var(--v-primary-base) !important;
}
</style>
