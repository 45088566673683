<template>
   <v-container>
      <v-layout text-center wrap>
         <v-flex mb-4>
            <h1 class="display-3 font-weight-bold mb-3">
               Error en autenticación
            </h1>
            <p class="body-1 font-weight-regular">
               Para regresar a la página principal solo debes hacer clic en el
               siguiente botón.
            </p>
         </v-flex>
         <v-flex xs12>
            <v-btn color="primary" @click="$router.push({ name: 'inicio' })"
               >Regresar</v-btn
            >
         </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
export default {
   name: 'OidcCallbackError'
}
</script>
