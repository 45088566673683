<template>
   <v-container>
      <v-layout text-center wrap>
         <v-flex xs12 mb-6>
            <v-img src="/assets/logo-u700.png" contain height="260"></v-img>
         </v-flex>

         <v-flex mb-4>
            <h1 class="text-h2 font-weight-bold mb-3">
               UNICAES <br />
               Cliente Web
               <div v-if="sede == 'IL'">ILOBASCO</div>
            </h1>
            <p>
               Modo: {{ isDevelopment ? 'Desarrollo' : 'Produccion' }}<br />
               Versión: {{ version }}
            </p>
         </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
import { authComputed } from '../helpers'
export default {
   data: () => ({
      sede: process.env.VUE_APP_SEDE,
      version: process.env.VUE_APP_VERSION
   }),
   computed: {
      ...authComputed
   }
}
</script>
