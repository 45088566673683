<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}

            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR  -->
         <v-dialog v-model="dialogIngreso" persistent max-width="600px">
            <v-form ref="formIngreso" v-model="validIngreso" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm12>
                              <v-autocomplete
                                 v-model="selectIngreso"
                                 :readonly="editedIndex === 1"
                                 :items="cmbIngresos"
                                 :rules="ingresoRules"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="ingresoBecaId"
                                 label="Tipo de ingreso"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              ></v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="descripcion"
                                 :rules="descripcionRules"
                                 color="primary"
                                 label="Descripción de ingreso"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="monto"
                                 :rules="montoRules"
                                 color="primary"
                                 label="Monto"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 class="font-weight-medium">
                              <span v-if="editedIndex === 1">
                                 Usted ya adjunto un comprobante, si desea
                                 sustituirlo puede seleccionar un nuevo archivo.
                                 De lo contrario, puede dejar este campo vacio
                              </span>
                              <span v-else>Adjunte un comprobante</span>
                           </v-flex>
                           <v-flex xs12>
                              <v-file-input
                                 v-model="archivosAdjuntos"
                                 color="primary"
                                 :rules="editedIndex === 1 ? [] : adjuntosRules"
                                 label="Archivos adjuntos"
                                 placeholder="Selecciona tu archivo"
                                 prepend-icon="fa-paperclip"
                                 accept=".pdf, image/png, image/jpeg, image/bmp"
                                 outlined
                                 multiple
                                 counter
                                 :show-size="1000"
                              >
                                 <template #selection="{ text }">
                                    <v-chip color="primary" dark label small>
                                       {{ text }}
                                    </v-chip>
                                 </template>
                              </v-file-input>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validIngreso"
                        @click.native="saveIngreso"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <v-dialog v-model="dialogEnviandoComp" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Enviando comprobante
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>
         <v-dialog v-model="dialogAnular" persistent max-width="500px">
            <v-form ref="formEliminarAbono">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Anular comprobante</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea anular el
                                 comprobante?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12 sm12>
                                    <div style="font-size: 18px">
                                       comprobante:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ comprobante }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                              >
                                 fa-ban
                              </v-icon>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="anularComprobante"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="closedialogAnular"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR  -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover ingreso</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover el
                                 ingreso?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       mb-10
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ descripcion }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >${{ numFormatIntl(monto) }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerIngreso"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Ingresos</v-toolbar-title
               >
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>

               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn
                        text
                        icon
                        color="white darken-3"
                        :disabled="completada"
                        v-on="on"
                        @click="completada ? false : nuevoIngreso()"
                     >
                        <v-icon>fas fa-plus</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px">Agregar</span>
               </v-tooltip>
            </v-toolbar>

            <!-- DATATABLE ingresos -->
            <v-data-table
               :headers="headerIngresosInfo"
               :items="ingresos"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-data-text="Sin ingresos"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.revision="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :color="item.validado ? 'primary' : botonCheck"
                           v-on="on"
                           @click="checkDocumento(item)"
                        >
                           <v-icon>fa-check</v-icon>
                        </v-btn>
                     </template>
                     <span>Verificado</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :color="
                              item.notificaciones > 0
                                 ? 'primary'
                                 : 'grey lighten-1'
                           "
                           :disabled="item.validado"
                           v-on="on"
                           @click="abrirRevision(item)"
                        >
                           <v-icon>fas fa-times</v-icon>
                        </v-btn>
                     </template>
                     <span
                        >Numero de notificaciones:
                        {{ item.notificaciones }}</span
                     >
                  </v-tooltip>
               </template>
               <template #item.observacion="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           :color="
                              item.notificaciones > 0
                                 ? 'primary'
                                 : 'grey lighten-1'
                           "
                           v-on="on"
                           @click="comentarioAsesor(item)"
                        >
                           <v-icon>fas fa-file-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Observacion</span>
                  </v-tooltip>
               </template>
               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           :disabled="completada"
                           v-on="on"
                           @click="completada ? false : editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar </span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           :disabled="completada"
                           v-on="on"
                           @click="completada ? false : deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Eliminar </span>
                  </v-tooltip>
               </template>
               <template #body.append="">
                  <!-- Linea de totales -->
                  <tr v-if="$vuetify.breakpoint.smAndUp">
                     <td></td>
                     <td class="text-body-1 font-weight-medium">Total</td>
                     <td class="text-body-1 font-weight-medium">
                        ${{ numFormatIntl(total) }}
                     </td>
                  </tr>
               </template>
               <template #item.monto="{ item }">
                  $ {{ numFormatIntl(item.monto) }}
               </template>
               <template #item.comprobantePath="{ item }">
                  <v-tooltip right color="primary">
                     <template #activator="{ on }">
                        <v-icon
                           color="primary"
                           v-on="on"
                           @click="visualizar(item)"
                           >fas fa-download</v-icon
                        >
                     </template>
                     <span style="font-size: 15px">Clic para ver</span>
                  </v-tooltip>
               </template>
            </v-data-table>
         </v-card>
         <v-container>
            <div class="text-center">
               <v-dialog v-model="dialogRevision" width="500">
                  <v-card>
                     <v-card-title class="text-h5 primary white--text">
                        Ingrese el comentario
                     </v-card-title>

                     <v-card-text>
                        <v-flex
                           xs12
                           mt-4
                           :style="
                              $vuetify.breakpoint.mdAndUp
                                 ? 'margin-right: 12%; margin-left: 12%;'
                                 : ''
                           "
                        >
                           <v-form>
                              <v-textarea
                                 v-model="observacion"
                                 outlined
                                 rows="5"
                                 label="Comentario"
                                 :rules="comentarioAsesorRules"
                                 color="primary"
                                 no-resize
                              ></v-textarea>
                           </v-form>
                        </v-flex>
                     </v-card-text>

                     <v-divider></v-divider>

                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           :disabled="observacion.length === 0"
                           text
                           @click="revisarDocumento"
                        >
                           Enviar
                        </v-btn>

                        <v-btn
                           color="primary"
                           text
                           @click="cerrarDialogoAsesor"
                        >
                           Salir
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-dialog>
            </div>
         </v-container>

         <v-container>
            <div class="text-center">
               <v-dialog v-model="dialogObservacion" width="600">
                  <v-card>
                     <ComentarioAsesor
                        :width="600"
                        :comentarios="observacionAsesor"
                     />
                     <v-divider></v-divider>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           text
                           @click="dialogObservacion = false"
                        >
                           Salir
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-dialog>
            </div>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import ComentarioAsesor from '@/components/ComentarioAsesor.vue'

export default {
   name: 'FormBecaIngresos',
   components: {
      ComentarioAsesor
   },
   mixins: [formatters],
   props: {
      solicitudBecaId: {
         type: Number,
         default: 1031
      },
      completada: {
         type: Boolean,
         default: false
      }
   },
   data: () => ({
      // Variables
      dialogIngreso: false,
      dialogEliminar: false,
      editedIndex: -1,
      archivosAdjuntos: [],
      headers: [
         { text: 'Tipo', value: 'ingreso', class: 'titulo' },
         { text: 'Descripción', value: 'descripcion', class: 'titulo' },
         { text: 'Monto', value: 'monto', class: 'titulo', align: 'rigth' },
         {
            text: 'Comprobante',
            value: 'comprobantePath',
            class: 'titulo',
            sortable: false,
            align: 'center'
         },
         {
            text: 'Revision',
            value: 'revision',
            align: 'right',
            width: 120,
            sortable: false,
            class: 'titulo'
         },
         {
            text: 'Observacion',
            value: 'observacion',
            align: 'center',
            width: 120,
            sortable: false,
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            width: 120,
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      comprobante: '',
      search: '',
      pagination: {},
      result: [],
      ingresos: [],
      swGeneraVenta: false,

      validIngreso: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades bodega
      ingresoId: '',
      descripcion: '',
      monto: '',
      selectIngreso: '',
      cmbIngresos: [],
      total: 0.0,
      dialogEnviandoComp: false,
      dialogAnular: false,
      dialogRevision: false,
      dialogObservacion: false,
      comentario: true,
      observacion: '',
      observacionAsesor: [],
      observacionForm: '',
      botonCheck: 'grey lighten-1',

      // Reglas de validacion de formularios
      descripcionRules: [(v) => !!v || 'Ingrese una descripción'],
      comentarioAsesorRules: [(v) => !!v || 'Ingrese un comentario'],
      ingresoRules: [(v) => !!v || 'Seleccione un ingreso'],
      montoRules: [
         (v) => !!v || 'Ingrese un monto',
         (v) =>
            v === '' || /^(\d*\.)?\d+$/.test(v) || 'Formato de número inválido'
      ],
      adjuntosRules: [
         (files) => files.length > 0 || 'Seleccione un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'application/pdf') ||
            !files.some((file) => file.type != 'image/png') ||
            !files.some((file) => file.type != 'image/jpeg') ||
            !files.some((file) => file.type != 'image/bmp') ||
            'Solo se permiten archivos PDF e imágenes',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ]
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1 ? 'Nuevo ingreso' : 'Editar ingreso'
      },
      headerIngresosInfo() {
         if (this.oidcUser) {
            if (
               Number(this.oidcUser.nivelasesor) >= 1 ||
               Number(this.oidcUser.nivelasesor) == 0 ||
               this.oidcUser.role == 'admin'
            ) {
               return this.headers.filter(
                  (headerE) => headerE.text !== 'Observacion'
               )
            } else {
               return this.headers.filter(
                  (headerE) => headerE.text !== 'Revision'
               )
            }
         }
         return this.headers.filter((headerE) => headerE.text !== 'Revision')
      }
   },

   watch: {
      solicitudBecaId(val) {
         if (val != null) {
            this.listarIngresos()
         }
      },
      selectIngreso(val) {
         if (val != 3 && this.editedIndex != 1) {
            this.descripcion = this.getIngreso(this.selectIngreso)
         } else {
            this.descripcion =
               this.descripcion.length > 0 ? this.descripcion : ''
         }
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarIngresos() {
         // Obteniendo ingresos
         axios
            .get(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/SolicitudIngreso'
            )
            .then((response) => {
               this.ingresos = response.data
               this.getTotal()
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboboxes() {
         // Obteniendo ingresos
         axios
            .get('api/general/Ingresos')
            .then((response) => {
               this.cmbIngresos = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las ingresos en el datatable se puede resetear
      reset() {
         this.listarIngresos()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         this.ingresoId = item.id
         this.descripcion = item.descripcion
         this.selectIngreso = item.ingresoId
         this.monto = item.monto
         this.editedIndex = 1
         this.openDialog()

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         this.dialogEliminar = true
         this.descripcion = item.descripcion
         this.monto = item.monto
         this.selectIngreso = item.ingresoId
         this.ingresoId = item.id

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogIngreso = false
         this.dialogEliminar = false
         this.getTotal()
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      saveIngreso() {
         if (this.$refs.formIngreso.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               this.dialogEnviandoComp = true
               let formData = new FormData()
               for (let file of this.archivosAdjuntos) {
                  formData.append('files', file, file.name)
               }
               formData.append(
                  'model',
                  JSON.stringify({
                     ingresoId: this.selectIngreso,
                     descripcion: this.descripcion,
                     monto: this.monto,
                     path: null
                  })
               )
               axios
                  .put(
                     'api/solicitudesdebecas/' +
                        this.solicitudBecaId +
                        '/SolicitudIngreso/' +
                        this.ingresoId,
                     formData
                  )
                  .then(() => {
                     this.close()
                     this.snackText = 'Ingreso editado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                     this.dialogEnviandoComp = false
                     this.listarIngresos()
                  })
                  .catch((error) => {
                     if (error.response.status != 304) {
                        this.snackText = 'No se pudo subir el comprobante'
                        this.snackColor = 'snackError'
                        this.snackbar = true
                     }
                     this.close()
                     this.dialogEnviandoComp = false
                  })
            } else {
               this.dialogEnviandoComp = true
               let formData = new FormData()
               for (let file of this.archivosAdjuntos) {
                  formData.append('files', file, file.name)
               }
               formData.append(
                  'model',
                  JSON.stringify({
                     ingresoId: this.selectIngreso,
                     descripcion: this.descripcion,
                     monto: this.monto
                  })
               )
               axios
                  .post(
                     'api/solicitudesdebecas/' +
                        this.solicitudBecaId +
                        '/SolicitudIngreso',
                     formData
                  )
                  .then(() => {
                     this.close()
                     this.snackText = 'Ingreso agregado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                     this.dialogEnviandoComp = false
                     this.listarIngresos()
                  })
                  .catch((error) => {
                     console.log(error)
                     this.snackText = 'No se pudo subir el comprobante'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                     this.close()
                     this.dialogEnviandoComp = false
                  })
            }
         }
      },
      revisarDocumento() {
         this.dialogRevision = false
         axios
            .put(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/SolicitudIngreso/' +
                  this.notificarId +
                  '/notificar',
               {
                  text: this.observacion
               }
            )
            .then(() => {
               this.snackText = 'Notificacion enviada con exito'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               axios
                  .get('api/FileUploadNotificationLog/ingresos')
                  .then(() => {})
               this.listarIngresos()
            })
            .catch((error) => {
               console.log(error)
            })

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()

         this.observacion = ''
      },

      cerrarDialogoAsesor() {
         this.dialogRevision = false
         this.observacion = ''
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },

      abrirRevision(item) {
         this.notificarId = item.id
         this.dialogRevision = true
      },
      comentarioAsesor(item) {
         this.dialogObservacion = true
         axios
            .get(
               'api/FileUploadNotificationLog/ingresos?solicitudId=' +
                  this.solicitudBecaId +
                  '&ingresoId=' +
                  item.id
            )
            .then((response) => {
               this.observacionAsesor = response.data
            })
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      checkDocumento(item) {
         axios
            .put(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/SolicitudIngreso/' +
                  item.id +
                  '/validar',
               {
                  value: true
               }
            )
            .then(() => {
               this.listarIngresos()
               this.snackText = 'Validado correctamente'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formIngreso.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombre = ''
         this.descripcion = ''
         this.selectIngreso = ''
         this.monto = ''
         this.archivosAdjuntos = []
      },
      nuevoIngreso() {
         this.openDialog()
      },
      removerIngreso() {
         // Enviando metodo delete
         axios
            .delete(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/SolicitudIngreso/' +
                  this.ingresoId
            )
            .then(() => {
               this.close()
               this.snackText = 'Ingreso eliminado exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.listarIngresos()
            })
            .catch((error) => {
               console.log(error)
            })
      },
      getIngreso(ingresoId) {
         let nombre = ''
         this.cmbIngresos.forEach((element) => {
            //nombre = element.ingresoId == ingresoId ? element.nombre : ''
            if (element.ingresoBecaId == ingresoId) {
               nombre = element.nombre
            }
         })
         return nombre
      },
      getTotal() {
         this.total = 0
         this.ingresos.forEach((element) => {
            this.total += parseFloat(element.monto)
         })
      },
      closedialogAnular() {
         this.clear()
         this.dialogAnular = false
      },
      anularComprobante() {
         this.archivosAdjuntos = []
         let formData = new FormData()
         for (let file of this.archivosAdjuntos) {
            formData.append('files', file, file.name)
         }
         axios
            .put(
               'api/solicitudesdebecas/' +
                  this.solicitudBecaId +
                  '/PathComprobante/' +
                  this.pathComprobanteId,
               formData
            )
            .then(() => {
               this.listarComprobantes()
               this.snackText = 'Comprobante anulado.'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.closedialogAnular()
            })
            .catch((error) => {
               console.log(error)
               this.listarComprobantes()
               this.snackText = 'Comprobante anulado.'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.closedialogAnular()
            })
      },
      visualizar(item) {
         window.open(item.comprobantePath, null)
      },
      openDialog() {
         this.dialogIngreso = true
      }
   }
}
</script>
