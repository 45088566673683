<template>
   <v-container>
      <v-layout text-center wrap>
         <v-flex xs12 mb-9>
            <v-img
               src="/assets/404NotFound-500-red.png"
               class="my-3"
               contain
               height="400"
            ></v-img>
         </v-flex>

         <v-flex mb-4>
            <h1 class="text-h2 font-weight-bold mb-3">
               Oh!, parece que te has perdido
            </h1>
            <p class="text-body-1 font-weight-regular">
               Para regresar a la página principal solo debes hacer clic en el
               siguiente botón.
            </p>
         </v-flex>
         <v-flex xs12>
            <v-btn color="primary" @click="$router.push({ name: 'inicio' })"
               >Regresar</v-btn
            >
         </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
export default {
   data: () => ({}),
   methods: {}
}
</script>
